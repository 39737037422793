import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Checkbox as MUICheckbox,
  Tabs,
  Tab,
  Box,
} from "@mui/material";

import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Autocomplete, FileListField, Select, DateField }
  from "../../components";
import api from "../../services/api";
import { tiposCargas } from "../../static";

import AddTomadaPreco from "../../components/TomadaPreco/AddTomadaPreco";
import ListTomadaPreco from "../../components/TomadaPreco/ListTomadaPreco";
import ListTomadaPrecoAprovado from "../../components/TomadaPreco/ListTomadaPrecoAprovado";

import PropTypes from 'prop-types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;




function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const DialogoSolicitacaoOrcamentosAddNovos = ({ open, onClose, disabled }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let currProcesso = useStoreState((state) => state.processo.current);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const close = () => {
    // reset(defaultValues);
    onClose(true);
  };


  return (
    <Dialog open={open} onClose={() => close()} fullScreen>
      <DialogTitle>Dados para tomada de preços</DialogTitle>
      <DialogContent dividers>
        <AddTomadaPreco currProcesso={currProcesso} Close={(() => close())} />
      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button color="inherit" onClick={() => close()}>
          FECHAR
        </Button>

      </DialogActions>
    </Dialog>
  );
};

export default DialogoSolicitacaoOrcamentosAddNovos;
