import * as React from 'react';
import {
    Box,
    Button,
    FormControl,
    CircularProgress,
    Paper,
    Grid,
    Checkbox as MUICheckbox,
    Divider,
    Modal,
    Link
} from '@mui/material';
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
    TextField,
    Select,
    Checkbox,
    CNPJField,
    Autocomplete,
    TelField,
    CEPField,
    SelectUF,
    SelectCidade,
    FileField,
    DialogoConfirmacaoSimples,
} from '../../components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { estadosBrasil, listaEnergeticos, opcoesBooleanas, constituicaoEmpresaFornecedor, estagioFornecedor } from '../../static';
import { DialogoVisualizador } from '../../components';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
// import { linkEditais } from './constants';
import DownloadIcon from '@mui/icons-material/Download';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FornecedorCadastro = () => {
    let loading = useStoreState((state) => state.nav.loading);
    let startLoading = useStoreActions((actions) => actions.nav.startLoading);
    let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
    let user = useStoreState((state) => state.auth.user);
    const [tecnologias, setTecnologias] = React.useState([]);
    const [regioes, setRegioes] = React.useState([]);
    const [estados, setEstados] = React.useState([]);
    const [categorias, setCategorias] = React.useState([]);
    const [fornecedor, setFornecedor] = React.useState([]);

    const [cities, setCities] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const [fileVisualizador, setFileVisualizador] = React.useState();
    const [nomeDocumento, setNomeDocumento] = React.useState();
    const [nomeCampoDocumento, setNomeCampoDocumento] = React.useState();
    const [idFornecedor, setIdFornecedor] = React.useState();
    const [openDlgVis, setOpenDlgVis] = React.useState(false);
    const [openDlgAdd, setOpenDlgAdd] = React.useState(false);
    const [submeterAprovacao, setSubmeterAprovacao] = React.useState(false);

    const [openConfirmaExcluir, setOpenConfirmaExcluir] = React.useState(false);
    const [excluirId, setExcluirId] = React.useState(null);
    const [excluirNomeCampo, setExcluirNomeCampo] = React.useState(null);
    const [excluirTitulo, setExcluirTitulo] = React.useState("");
    const [editais, setEditais] = React.useState([]);

    const defaultValues = {
        nome: '',
        responsavel: '',
        email: '',
        cnpj: '',
        habilitado: true,
        regioes: [],
        tecnologias: []
    };

    const schema = yup.object({
        nome: yup.string().label('Nome da empresa').required(),
        responsavel: yup.string().label('Nome do responsável').required(),
        email: yup.string().email().label('Email do responsável').required(),
        cnpj: yup.string().cnpj().label('CNPJ').required(),
        tecnologias: yup.array(yup.number().positive().integer())
            .min(1).nullable().label('Tecnologia').required(),
        regioes: yup.array(yup.number().positive().integer())
            .min(1).nullable().label('Região').required(),
        atendeEstados: yup.array(yup.number().positive().integer())
            .min(1).nullable().label('Em quais estados a empresa deseja atuar?').required(),
        emailAux: yup.string().email().label("E-mail secundário").required(),
        telefone: yup.string().label("Telefone").required(),
        cep: yup.string().optional().label("CEP"),
        rua: yup.string().optional().label("Rua"),
        numero: yup.string().optional().label("Número"),
        bairro: yup.string().optional().label("Bairro"),
        cidade: yup.string().optional().label("Cidade"),
        uf: yup.string().optional().label("Estado"),
        atuaEsco: yup.boolean().nullable().label('A empresa atua como uma ESCO?'),
        atuaBot: yup.boolean().nullable().label('A empresa atua na modalidade de BOT?'),
        constituicaoEmpresa: yup.number().nullable().label('Qual o porte da empresa?'),
        idDocumento: yup.number().positive().nullable().label('Selecionar').optional(),
        // actId: yup.number().positive().nullable().label('1. Selecionar').optional(),
        // actAprovado: yup.number().positive().nullable().label('1. Aprovado').optional(),
        // actJustificativa: yup.string().nullable().label('1. Justificativa').optional(),
        // catId: yup.number().positive().nullable().label('2. Selecionar').optional(),
        // catAprovado: yup.number().positive().nullable().label('2. Aprovado').optional(),
        // catJustificativa: yup.string().optional().label('2. Justificativa').optional(),
        // declaracaoFabricanteId: yup.number().positive().nullable().label('3. Selecionar').optional(),
        // declaracaoFabricanteAprovado: yup.number().positive().nullable().label('3. Aprovado').optional(),
        // declaracaoFabricanteJustificativa: yup.string().optional().label('3. Justificativa').optional(),
        // certidaoCreaId: yup.number().positive().nullable().label('4. Selecionar').optional(),
        // certidaoCreaAprovado: yup.number().positive().nullable().label('4. Aprovado').optional(),
        // certidaoCreaJustificativa: yup.string().optional().label('4. Justificativa').optional(),
        // habilitado: yup.boolean().label('Habilitado').required()
    }).required();

    const useFormRef = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });

    const { handleSubmit, watch, reset, setValue, formState: { isDirty } } = useFormRef;

    const watchUF = watch("uf");
    const constituicaoEmpresa = watch("constituicaoEmpresa");
    const idDocumento = watch("idDocumento");
    // const actAprovado = watch("actAprovado");
    // const actJustificativa = watch("actJustificativa");
    // const catAprovado = watch("catAprovado");
    // const catJustificativa = watch("catJustificativa")
    // const declaracaoFabricanteAprovado = watch("declaracaoFabricanteAprovado");
    // const declaracaoFabricanteJustificativa = watch("declaracaoFabricanteJustificativa");
    // const certidaoCreaAprovado = watch("certidaoCreaAprovado");
    // const certidaoCreaJustificativa = watch("certidaoCreaJustificativa");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const disabled = (() => {
        if (fornecedor.estagioFornecedor === "analise" && fornecedor.analisadorId)
            return true
        else return false;
    });

    React.useEffect(() => {
        ///recuperar dados da api ibge e preencher o dropdow estados
        async function fetchStates() {
            try {
                const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
                const data = await response.json();

                const sortedStates = data.sort((a, b) => {
                    return a.nome.localeCompare(b.nome); // Comparando os nomes dos estados
                });

                setStates(sortedStates);

            } catch (error) {
                console.error('Error fetching states:', error);
            }
        }

        fetchStates();
    }, []);

    // Load categorias, tecnologia e regiões
    React.useEffect(() => {
        let mount = { isMounted: true };
        loadCategorias(mount);
        loadTecnologias(mount);
        loadRegioes(mount);
        loadEstados(mount);
        loadfornecedor(mount);

        return () => { mount.isMounted = false; };
    }, []);



    React.useEffect(() => {
        const isSubmeterAprovacao = () => {
            if (fornecedor.actId
                && fornecedor.catId
                // && fornecedor.nome
                // && fornecedor.responsavel
                // && fornecedor.email
                // && fornecedor.cnpj
                // && (fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia")
                // && fornecedor.cep
                // && fornecedor.rua
                // && fornecedor.numero
                // && fornecedor.bairro
                // && fornecedor.cidade
                // && fornecedor.uf
                // && fornecedor.telefone
                // && fornecedor.estado
                && fornecedor.declaracaoFabricanteId
                && fornecedor.certidaoCreaId
                && fornecedor.pedidoPublicoAnexo1Id
                && fornecedor.outrasInformacoesAnexo9Id
                && (fornecedor.documentosMeiId
                    || fornecedor.atoSociedadeSimplesId
                    || fornecedor.atoSociedadeAcoesId)
                && fornecedor.negativaFalenciaId
                && fornecedor.certidaoVirgenteId
                && fornecedor.balancoPatrimonialId
                && fornecedor.provaInscCnpjId
                && fornecedor.cadastroContribuinteMunicipalId
                && fornecedor.cadastroContribuinteEstadualId
                && fornecedor.regularidadeFazendaNacionalId
                && fornecedor.regularidadeFazendaEstadualId
                && fornecedor.regularidadeFazendaNacionalId
                && fornecedor.regularidadeFgtsId
                && fornecedor.regularidadeJusticaTrabalhoId
            ) {
                setSubmeterAprovacao(true);
                // console.log("submenter aprovação");
            }
            // else { console.log("não submeter"); }
        }
        ////   testar se todos os dados foram preenchidos e liberar para submeterAprovacao
        isSubmeterAprovacao();
    }, [fornecedor])

    const loadCategorias = async (mount) => {
        startLoading();
        try {
            let data = (await api.http.get('/categorias')).data;
            if (mount.isMounted) {
                setCategorias(data);
            }
            stopLoading();
        } catch (error) {
            stopLoading({ message: error.toString(), severity: 'error' });
        }
    };

    const loadTecnologias = async (mount) => {
        startLoading();
        try {
            let data = (await api.http.get('/tecnologias')).data;
            if (mount.isMounted) {
                setTecnologias(data);
            }
            stopLoading();
        } catch (error) {
            stopLoading({ message: error.toString(), severity: 'error' });
        }
    };

    const loadRegioes = async (mount) => {
        startLoading();
        try {
            let data = (await api.http.get('/regioes')).data;
            if (mount.isMounted) {
                setRegioes(data);
            }
            stopLoading();
        } catch (error) {
            stopLoading({ message: error.toString(), severity: 'error' });
        }
    };
    const loadEstados = async (mount) => {
        startLoading();
        try {
            let data = (await api.http.get('/estados')).data;
            if (mount.isMounted) {
                setEstados(data);
            }
            stopLoading();
        } catch (error) {
            stopLoading({ message: error.toString(), severity: 'error' });
        }
    };

    const loadfornecedor = async (mount) => {
        startLoading();
        try {
            let data = (await api.http.get(`/fornecedores/interesses?id=${user.id}`)).data;
            if (mount.isMounted) {
                setFornecedor(data[0]);
            }
            stopLoading();
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: 'error'
            });
        }
    }

    React.useEffect(() => {
        async function fetchCities() {
            if (watchUF) {
                try {
                    const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${watchUF}/municipios`);
                    const data = await response.json();
                    setCities(data);

                } catch (error) {
                    console.error('Error fetching cities:', error);
                }
            } else {
                setCities([]);
            }
        }

        fetchCities();
    }, [watchUF]);

    const onSave = async (data) => {
        startLoading();
        try {
            if (data.id) {
                await api.http.put('/fornecedores/' + data.id, data);
            } else {
                await api.http.post('/fornecedores', data);
            }
            stopLoading({ severity: 'success' });
        } catch (error) {
            stopLoading({ message: error.toString(), severity: 'error' });
            throw error;
        }
    };

    React.useEffect(() => {
        if (fornecedor
            && fornecedor.regiao
            && fornecedor.tecnologia
            //&& fornecedor.estado
        ) {
            reset({
                ...fornecedor,
                regioes: fornecedor.regiao.map(regiao => regiao.id),
                tecnologias: fornecedor.tecnologia.map(tecnologia => tecnologia.id),
                atendeEstados: fornecedor.estado.map(estado => estado.id)
            });
        }
        else {
            reset({
                fornecedor
            });
        }
    }, [fornecedor]);


    const onCloseDlgVis = () => {
        setOpenDlgVis(false);
    };

    const onCloseAdd = () => {
        setNomeCampoDocumento(null);
        setIdFornecedor(null);
        setNomeDocumento(null);
        setOpenDlgAdd(false);
    };

    const onConfirmarAdd = async () => {
        startLoading();
        try {

            let data = (await api.http.put('/fornecedores/add/file/' + idFornecedor, {
                id: idFornecedor,
                nomeCampo: nomeCampoDocumento,
                idDocumento: idDocumento
            })).data;

            setFornecedor(data[0]);

            stopLoading({ severity: 'success' });
            setOpenDlgAdd(false);

        } catch (error) {
            stopLoading({ message: error.toString(), severity: 'error' });
            setOpenDlgAdd(false);
            throw error;
        }

    }

    const onOpenDlgVis = (param1, param2, param3) => {
        setFileVisualizador(param1);
        setOpenDlgVis(true);
    };

    const onOpenDlgAdd = (param1, param2, param3) => {
        setValue('idDocumento', null);
        setNomeCampoDocumento(param1);
        setIdFornecedor(param2);
        setNomeDocumento(param3);
        setOpenDlgAdd(true);

    };

    const onDelete = async (param1, param2, param3) => {

        setExcluirId(param2);
        setExcluirNomeCampo(param1);
        setExcluirTitulo(param3);
        setOpenConfirmaExcluir(true);

    };

    const onConfirmaExcluir = async () => {
        startLoading();
        try {

            let data = (await api.http.put('/fornecedores/delete/file/' + excluirId, {
                id: excluirId,
                nomeCampo: excluirNomeCampo,
                idDocumento: 0
            })).data;

            setFornecedor(data[0]);

            stopLoading({ severity: 'success' });
            setOpenConfirmaExcluir(false);

        } catch (error) {
            stopLoading({ message: error.toString(), severity: 'error' });
            setOpenConfirmaExcluir(false);
            throw error;
        }
    };

    const onNaoConfirmaExcluir = async (data) => {
        setOpenConfirmaExcluir(false);
    };


    //const getEditalLink = async ({ estado }) => {
    React.useEffect(async () => {
        //// Recuperar editais da API
        let datalink = (await api.http.get(`/fornecedores/editais?page=1&pageSize=5&uf=${fornecedor.uf}`)).data;
        setEditais(datalink);
        //        console.log("edital link: ", datalink);
    }, [fornecedor.uf]);

    const handleSolicitaAnalise = async () => {
        startLoading();
        try {

            let data = (await api.http.put('/fornecedores/orcamentos/alterarestagio/' + fornecedor.id, {
                estagioFornecedor: "analise"
            })).data;

            let datafor = (await api.http.get(`/fornecedores/interesses?id=${user.id}`)).data;

            setFornecedor(datafor[0]);

            window.location.reload();

            stopLoading({ severity: 'success' });

        } catch (error) {
            stopLoading({ message: error.toString(), severity: 'error' });
            throw error;
        }
    }

    const getDescricaoEstagio = (cod) => {
        const estagio = estagioFornecedor.find((e) => e.cod === cod);
        return estagio ? estagio.descricao : "Desconhecido";
    };

    const loadCEP = async (cep) => {
        const cleanCEP = cep.replace(/\D/g, '');
        if (cleanCEP.length === 8) { // Valida se o CEP tem 8 dígitos
            try {
                const response = await fetch(`https://viacep.com.br/ws/${cleanCEP}/json/`);
                const data = await response.json();

                if (!data.erro) {
                    // Atualiza os valores no formulário
                    setValue('rua', data.logradouro);
                    setValue('bairro', data.bairro);
                    setValue('uf', data.uf);
                    setValue('cidade', data.localidade);
                } else {
                    alert('CEP não encontrado');
                }
            } catch (error) {
                console.error('Erro ao buscar o CEP:', error);
                alert('Erro ao buscar o CEP');
            }
        }
    };

    // console.log("fornecedor ", fornecedor);
    // console.log("fornecedor.estagioFornecedor: ", fornecedor.estagioFornecedor);

    return (
        <Box sx={{ p: 3 }}>
            <DialogoConfirmacaoSimples
                title="Atenção"
                open={openConfirmaExcluir}
                onSim={onConfirmaExcluir}
                onNao={onNaoConfirmaExcluir}
            >
                <h4>{excluirTitulo}</h4>
                Tem certeza que deseja excluir o arquivo?
            </DialogoConfirmacaoSimples>
            <Modal
                open={openDlgAdd}
                onClose={onCloseAdd}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <h2 id="parent-modal-title">Adicionar arquivo</h2>
                    <h3>{nomeDocumento}</h3>
                    <p id="parent-modal-description">
                        Selecione o documento que deseja incluir:
                    </p>

                    <FormControl sx={{ mb: 2 }} fullWidth>
                        <FileField
                            field="idDocumento"
                            fileData={fornecedor?.idDocumento}
                            useFormRef={useFormRef}
                            schema={schema}
                        />
                    </FormControl>

                    {/* <ChildModal /> */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Button
                            color="warning"
                            onClick={() => onCloseAdd()}
                        >
                            Fechar
                        </Button>
                        <Button
                            color="success"
                            disabled={!idDocumento}
                            onClick={() => onConfirmarAdd()}
                        >
                            Confirma
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <DialogoVisualizador file={fileVisualizador} open={openDlgVis} onClose={onCloseDlgVis} />
            <Paper elevation={3} sx={{ p: 3 }}>
                <h2>Dados do cadastrais</h2>
                {editais.data && editais.data.length > 0 && (
                    editais.data.map((item) => (
                        <Button
                            key={item.id}
                            href={item.linkUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="outlined"
                            color="info"
                            startIcon={<DownloadIcon />}
                            style={{ margin: '8px 5px', textTransform: 'none' }}
                        >
                            {item.titulo}
                        </Button>

                    ))
                )}


                <FormControl sx={{ my: 2 }} fullWidth>
                    <CNPJField
                        margin="dense"
                        fullWidth
                        field="cnpj"
                        useFormRef={useFormRef}
                        schema={schema}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                    />
                </FormControl>
                <FormControl sx={{ my: 2 }} fullWidth>
                    <TextField
                        margin="dense"
                        fullWidth
                        field="nome"
                        useFormRef={useFormRef}
                        // disabled={disabled}
                        schema={schema}
                    />
                </FormControl>
                <FormControl sx={{ my: 2 }} fullWidth>
                    <TextField
                        margin="dense"
                        fullWidth
                        field="responsavel"
                        useFormRef={useFormRef}
                        schema={schema}
                    // disabled={disabled}
                    />
                </FormControl>
                <FormControl sx={{ my: 2 }} fullWidth>
                    <TextField
                        margin="dense"
                        fullWidth
                        field="email"
                        type="email"
                        useFormRef={useFormRef}
                        schema={schema}
                    // disabled={disabled}
                    />
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl sx={{ my: 1 }} fullWidth>
                            <TelField
                                margin="dense"
                                field="telefone"
                                // disabled={disabled}
                                useFormRef={useFormRef}
                                schema={schema}
                                InputLabelProps={{ shrink: true }}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ my: 1 }} fullWidth>
                            <TextField
                                margin="dense"
                                fullWidth
                                field="emailAux"
                                type="email"
                                useFormRef={useFormRef}
                                // disabled={disabled}
                                schema={schema} />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <FormControl sx={{ my: 2 }} fullWidth>
                            <CEPField
                                field="cep"
                                // disabled={disabled}
                                useFormRef={useFormRef}
                                schema={schema}
                                onBlur={(e) => loadCEP(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl sx={{ my: 2 }} fullWidth>
                            <TextField
                                field="rua"
                                // disabled={disabled}
                                useFormRef={useFormRef}
                                schema={schema}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ my: 2 }} fullWidth>
                            <TextField
                                field="numero"
                                // disabled={disabled}
                                useFormRef={useFormRef}
                                schema={schema}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ my: 2 }} fullWidth>
                            <TextField
                                field="bairro"
                                // disabled={disabled}
                                useFormRef={useFormRef}
                                schema={schema}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ my: 2 }} fullWidth>
                            <SelectUF
                                field="uf"
                                useFormRef={useFormRef}
                                schema={schema}
                                //disabled={disabled}
                                options={states}
                                getOptionLabel={(option) => option.nome}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ my: 2 }} fullWidth>
                            <SelectCidade
                                field="cidade"
                                useFormRef={useFormRef}
                                schema={schema}
                                //disabled={disabled}
                                options={cities}
                                getOptionLabel={(option) => option.nome}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <Checkbox field="atuaEsco"
                                useFormRef={useFormRef}
                                //disabled={disabled} 
                                schema={schema} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <Checkbox field="atuaBot"
                                useFormRef={useFormRef}
                                //disabled={disabled} 
                                schema={schema} />
                        </FormControl>
                    </Grid>
                </Grid>

                <Divider>
                    <h3>Atuação</h3>
                </Divider>
                <FormControl sx={{ my: 2 }} fullWidth>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        field="tecnologias"
                        useFormRef={useFormRef}
                        schema={schema}
                        //disabled={disabled}
                        options={tecnologias}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <MUICheckbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {props.key}
                            </li>
                        )}
                    />
                </FormControl>
                <FormControl sx={{ my: 2 }} fullWidth>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        field="regioes"
                        useFormRef={useFormRef}
                        schema={schema}
                        //disabled={disabled}
                        options={regioes}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <MUICheckbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {props.key}
                            </li>
                        )}
                    />
                </FormControl>
                <FormControl sx={{ my: 2 }} fullWidth>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        field="atendeEstados"
                        useFormRef={useFormRef}
                        schema={schema}
                        // disabled={disabled}
                        options={estados}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <MUICheckbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {props.key}
                            </li>
                        )}
                    />
                </FormControl>

                <FormControl x={{ mb: 2 }} fullWidth>
                    <Select
                        field="constituicaoEmpresa"
                        useFormRef={useFormRef} schema={schema}
                        options={constituicaoEmpresaFornecedor}
                    //disabled={disabled}
                    />
                </FormControl>


                <Divider>
                    <h3>Documentação Técnica</h3>
                    <h3>Qualificação Técnica</h3>
                </Divider>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <h4>1. ACT</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            {fornecedor.arquivoAct ?
                                <>
                                    <Button variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                        color="secondary"
                                        onClick={() => onOpenDlgVis(fornecedor.arquivoAct, fornecedor.id, 'Valor3')}
                                    >
                                        Visualizar
                                    </Button>
                                    {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                        <Button
                                            color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                            onClick={() => onDelete("actId", fornecedor.id, 'Act')}
                                        //disabled={disabled}
                                        >
                                            Remover
                                        </Button>
                                    )}

                                </>
                                : <Button
                                    color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                    onClick={() => onOpenDlgAdd("actId", fornecedor.id, 'Act')}
                                >
                                    Incluir
                                </Button>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {fornecedor.actAprovado && (
                                <h4>Aprovado:  {fornecedor.actAprovado === 1 ? 'Sim' : 'Não'} </h4>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <h4>{fornecedor.actJustificativa}</h4>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <h4>2. CAT</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            {fornecedor.arquivoCat ?
                                <><Button
                                    color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                    onClick={() => onOpenDlgVis(fornecedor.arquivoCat, fornecedor.id, 'Valor3')}
                                >
                                    Visualizar
                                </Button>
                                    {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                        <Button
                                            color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                            onClick={() => onDelete("catId", fornecedor.id, 'CAT')}
                                        //disabled={disabled}
                                        >
                                            Remover
                                        </Button>
                                    )}
                                </>
                                : <Button
                                    color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                    onClick={() => onOpenDlgAdd("catId", fornecedor.id, 'CAT')} //disabled={disabled}
                                >
                                    Incluir
                                </Button>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {fornecedor.catAprovado && (
                                <h4>Aprovado:  {fornecedor.catAprovado === 1 ? 'Sim' : 'Não'} </h4>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <h4>{fornecedor.catJustificativa}</h4>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <h4>3. Declaração   do fabricante</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            {fornecedor.arquivodeclaracaoFabricanteId ?
                                <><Button
                                    color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                    onClick={() => onOpenDlgVis(fornecedor.arquivodeclaracaoFabricanteId, fornecedor.id, 'Valor3')}
                                >
                                    Visualizar
                                </Button>
                                    {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                        <Button
                                            color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                            //disabled={disabled}
                                            onClick={() => onDelete("declaracaoFabricanteId", fornecedor.id, 'Declaração do fabricante')}
                                        >
                                            Remover
                                        </Button>)}
                                </>
                                : <Button
                                    color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                    onClick={() => onOpenDlgAdd("declaracaoFabricanteId", fornecedor.id, 'Declaração do fabricante')}
                                >
                                    Incluir
                                </Button>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {fornecedor.declaracaoFabricanteAprovado && (
                                <h4>Aprovado:  {fornecedor.declaracaoFabricanteAprovado === 1 ? 'Sim' : 'Não'} </h4>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <h4>{fornecedor.declaracaoFabricanteJustificativa}</h4>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <h4>4. Certidão de Registro - CREA Pessoa Juridica</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            {fornecedor.arquivocertidaoCreaId ?
                                <><Button
                                    color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                    onClick={() => onOpenDlgVis(fornecedor.arquivocertidaoCreaId, fornecedor.id, 'Valor3')}
                                >
                                    Visualizar
                                </Button>
                                    {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                        <Button
                                            color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                            // disabled={disabled}
                                            onClick={() => onDelete("certidaoCreaId", fornecedor.id, 'Certidão de Registro - CREA Pessoa Juridica')}
                                        >
                                            Remover
                                        </Button>)}
                                </>
                                : <Button
                                    color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                    onClick={() => onOpenDlgAdd("certidaoCreaId", fornecedor.id, 'Certidão de Registro - CREA Pessoa Juridica')}
                                >
                                    Incluir
                                </Button>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {fornecedor.certidaoCreaAprovado && (
                                <h4>Aprovado:  {fornecedor.certidaoCreaAprovado === 1 ? 'Sim' : 'Não'} </h4>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <h4>{fornecedor.certidaoCreaJustificativa}</h4>
                        </FormControl>
                    </Grid>
                </Grid>

                <Divider>
                    <h3>Documentação habilitação</h3>
                    <h3>Qualificação Técnica</h3>
                </Divider>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <h4>5. Pedido Público (Anexo I)</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            {fornecedor.arquivopedidoPublicoAnexo1Id ?
                                <><Button
                                    color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                    onClick={() => onOpenDlgVis(fornecedor.arquivopedidoPublicoAnexo1Id, fornecedor.id, 'Valor3')}
                                >
                                    Visualizar
                                </Button>
                                    {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                        <Button
                                            color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                            onClick={() => onDelete("pedidoPublicoAnexo1Id", fornecedor.id, 'Pedido Público (Anexo I)')}
                                        >
                                            Remover
                                        </Button>)}
                                </>
                                : <Button
                                    color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                    onClick={() => onOpenDlgAdd("pedidoPublicoAnexo1Id", fornecedor.id, 'Pedido Público (Anexo I)')}
                                >
                                    Incluir
                                </Button>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {fornecedor.pedidoPublicoAnexo1Aprovado && (
                                <h4>Aprovado:  {fornecedor.pedidoPublicoAnexo1Aprovado === 1 ? 'Sim' : 'Não'} </h4>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <h4>{fornecedor.pedidoPublicoAnexo1Justificativa}</h4>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <h4>6. Declaração de outras informações (Anexo IX)</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            {fornecedor.arquivooutrasInformacoesAnexo9Id ?
                                <><Button
                                    color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                    onClick={() => onOpenDlgVis(fornecedor.arquivooutrasInformacoesAnexo9Id, fornecedor.id, '')}
                                >
                                    Visualizar
                                </Button>
                                    {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                        <Button
                                            color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                            onClick={() => onDelete("outrasInformacoesAnexo9Id", fornecedor.id, 'Declaração de outras informações (Anexo IX)')}
                                        >
                                            Remover
                                        </Button>)}
                                </>
                                : <Button
                                    color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                    onClick={() => onOpenDlgAdd("outrasInformacoesAnexo9Id", fornecedor.id, 'Declaração de outras informações (Anexo IX)')}
                                >
                                    Incluir
                                </Button>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {fornecedor.outrasInformacoesAnexo9Aprovado && (
                                <h4>Aprovado:  {fornecedor.outrasInformacoesAnexo9Aprovado === 1 ? 'Sim' : 'Não'} </h4>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <h4>{fornecedor.outrasInformacoesAnexo9Justificativa}</h4>
                        </FormControl>
                    </Grid>



                    {constituicaoEmpresa === 1 && (
                        <>
                            <Grid item xs={6}>
                                <h4>7. Documentos - MEI</h4>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl sx={{ mb: 2 }} fullWidth>
                                    {fornecedor.arquivodocumentosMeiId ?
                                        <><Button
                                            color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                            onClick={() => onOpenDlgVis(fornecedor.arquivodocumentosMeiId, fornecedor.id, '')}
                                        >
                                            Visualizar
                                        </Button>
                                            {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                                <Button
                                                    color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                    onClick={() => onDelete("documentosMeiId", fornecedor.id, 'Documentos - Mei')}
                                                >
                                                    Remover
                                                </Button>)}
                                        </>
                                        : <Button
                                            color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                            onClick={() => onOpenDlgAdd("documentosMeiId", fornecedor.id, 'Documentos - Mei')}
                                        >
                                            Incluir
                                        </Button>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl>
                                    {fornecedor.documentosMeiAprovado && (
                                        <h4>Aprovado:  {fornecedor.documentosMeiAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl>
                                    <h4>{fornecedor.documentosMeiJustificativa}</h4>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    {constituicaoEmpresa === 2 && (
                        <>
                            <Grid item xs={6}>
                                <h4>7. Ato Constitutivo - Sociedades Simples</h4>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl sx={{ mb: 2 }} fullWidth>
                                    {fornecedor.arquivoatoSociedadeSimplesId ?
                                        <><Button
                                            color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                            onClick={() => onOpenDlgVis(fornecedor.arquivoatoSociedadeSimplesId, fornecedor.id, '')}
                                        >
                                            Visualizar
                                        </Button>
                                            {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                                <Button
                                                    color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                    onClick={() => onDelete("atoSociedadeSimplesId", fornecedor.id, 'Ato Constitutivo - Sociedades Simples')}
                                                >
                                                    Remover
                                                </Button>)}
                                        </>
                                        : <Button
                                            color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                            onClick={() => onOpenDlgAdd("atoSociedadeSimplesId", fornecedor.id, 'Ato Constitutivo - Sociedades Simples')}
                                        >
                                            Incluir
                                        </Button>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl>
                                    {fornecedor.atoSociedadeSimplesAprovado && (
                                        <h4>Aprovado:  {fornecedor.atoSociedadeSimplesAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl>
                                    <h4>{fornecedor.atoSociedadeSimplesJustificativa}</h4>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    {constituicaoEmpresa === 3 && (
                        <>
                            <Grid item xs={6}>
                                <h4>7. Ato Constitutivo - Sociedades por Ações</h4>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl sx={{ mb: 2 }} fullWidth>
                                    {fornecedor.arquivoatoSociedadeAcoesId ?
                                        <><Button
                                            color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                            onClick={() => onOpenDlgVis(fornecedor.arquivoatoSociedadeAcoesId, fornecedor.id, '')}
                                        >
                                            Visualizar
                                        </Button>
                                            {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                                <Button
                                                    color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                    onClick={() => onDelete("atoSociedadeAcoesId", fornecedor.id, 'Ato Constitutivo - Sociedades por Ações')}
                                                >
                                                    Remover
                                                </Button>)}
                                        </>
                                        : <Button
                                            color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                            onClick={() => onOpenDlgAdd("atoSociedadeAcoesId", fornecedor.id, 'Ato Constitutivo - Sociedades por Ações')}
                                        >
                                            Incluir
                                        </Button>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl>
                                    {fornecedor.atoSociedadeAcoesAprovado && (
                                        <h4>Aprovado:  {fornecedor.atoSociedadeAcoesAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl>
                                    <h4>{fornecedor.atoSociedadeAcoesJustificativa}</h4>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                </Grid >
                <Divider>
                    <h3>Qualificação Econômico-Financeira</h3>
                </Divider>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <h4>8. Certidão Negativa Falência ou Certidão Judicial - não superior a 90 dias</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            {fornecedor.arquivonegativaFalenciaId ?
                                <><Button
                                    color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                    onClick={() => onOpenDlgVis(fornecedor.arquivonegativaFalenciaId, fornecedor.id, '')}
                                >
                                    Visualizar
                                </Button>
                                    {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                        <Button
                                            color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                            onClick={() => onDelete("negativaFalenciaId", fornecedor.id, 'Certidão Negativa Falência ou Certidão Judicial - não superior a 90 dias')}
                                        >
                                            Remover
                                        </Button>)}
                                </>
                                : <Button
                                    color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                    onClick={() => onOpenDlgAdd("negativaFalenciaId", fornecedor.id, 'Certidão Negativa Falência ou Certidão Judicial - não superior a 90 dias')}
                                >
                                    Incluir
                                </Button>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {fornecedor.negativaFalenciaAprovado && (
                                <h4>Aprovado:  {fornecedor.negativaFalenciaAprovado === 1 ? 'Sim' : 'Não'} </h4>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <h4>{fornecedor.negativaFalenciaJustificativa}</h4>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <h4>9. Certidão vigente emitida pela instância Judicial</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            {fornecedor.arquivocertidaoVirgenteId ?
                                <><Button
                                    color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                    onClick={() => onOpenDlgVis(fornecedor.arquivocertidaoVirgenteId, fornecedor.id, '')}
                                >
                                    Visualizar
                                </Button>
                                    {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                        <Button
                                            color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                            onClick={() => onDelete(fornecedor.arquivocertidaoVirgenteId, fornecedor.id, '')}
                                        >
                                            Remover
                                        </Button>)}
                                </>
                                : <Button
                                    color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                    onClick={() => onOpenDlgAdd("certidaoVirgenteId", fornecedor.id, 'Certidão vigente emitida pela instância Judicial')}
                                >
                                    Incluir
                                </Button>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {fornecedor.certidaoVirgenteAprovado && (
                                <h4>Aprovado:  {fornecedor.certidaoVirgenteAprovado === 1 ? 'Sim' : 'Não'} </h4>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <h4>{fornecedor.certidaoVirgenteJustificativa}</h4>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <h4>10. Balanço Patrimonial e Demonst. contábeis</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ mb: 2 }} fullWidth>
                            {fornecedor.arquivobalancoPatrimonialId ?
                                <><Button
                                    color="secondary" variant="outlined" startIcon={<ArticleOutlinedIcon />}
                                    onClick={() => onOpenDlgVis(fornecedor.arquivobalancoPatrimonialId, fornecedor.id, '')}
                                >
                                    Visualizar
                                </Button>
                                    {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                        <Button
                                            color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                            onClick={() => onDelete("balancoPatrimonialId", fornecedor.id, 'Balanço Patrimonial e Demonst. contábeis')}
                                        >
                                            Remover
                                        </Button>)}
                                </>
                                : <Button
                                    color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                    onClick={() => onOpenDlgAdd("balancoPatrimonialId", fornecedor.id, 'Balanço Patrimonial e Demonst. contábeis')}
                                >
                                    Incluir
                                </Button>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {fornecedor.balancoPatrimonialAprovado && (
                                <h4>Aprovado:  {fornecedor.balancoPatrimonialAprovado === 1 ? 'Sim' : 'Não'} </h4>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <h4>{fornecedor.balancoPatrimonialJustificativa}</h4>
                        </FormControl>
                    </Grid>

                    <Divider>
                        <h3>Regularidade Fiscal</h3>
                    </Divider>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <h4>11. Prova de Inscrição - CNPJ</h4>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ mb: 2 }} fullWidth>
                                {fornecedor.arquivoprovaInscCnpjId ?
                                    <><Button variant="outlined"
                                        color="secondary"
                                        startIcon={<ArticleOutlinedIcon />}
                                        onClick={() => onOpenDlgVis(fornecedor.arquivoprovaInscCnpjId, fornecedor.id, '')}
                                    >
                                        Visualizar
                                    </Button>
                                        {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                            <Button
                                                color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                onClick={() => onDelete("provaInscCnpjId", fornecedor.id, 'Prova de Inscrição - CNPJ')}
                                            >
                                                Remover
                                            </Button>)}
                                    </>
                                    : <Button
                                        color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                        onClick={() => onOpenDlgAdd("provaInscCnpjId", fornecedor.id, 'Prova de Inscrição - CNPJ')}
                                    >
                                        Incluir
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                {fornecedor.provaInscCnpjAprovado && (
                                    <h4>Aprovado:  {fornecedor.provaInscCnpjAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                <h4>{fornecedor.provaInscCnpjJustificativa}</h4>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <h4>12. Cadastro de Contribuinte Municipal</h4>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ mb: 2 }} fullWidth>
                                {fornecedor.arquivocadastroContribuinteMunicipalId ?
                                    <><Button variant="outlined"
                                        color="secondary"
                                        startIcon={<ArticleOutlinedIcon />}
                                        onClick={() => onOpenDlgVis(fornecedor.arquivocadastroContribuinteMunicipalId, fornecedor.id, '')}
                                    >
                                        Visualizar
                                    </Button>
                                        {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                            <Button
                                                color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                onClick={() => onDelete("cadastroContribuinteMunicipalId", fornecedor.id, 'Cadastro de Contribuinte Municipal')}
                                            >
                                                Remover
                                            </Button>)}
                                    </>
                                    : <Button
                                        color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                        onClick={() => onOpenDlgAdd("cadastroContribuinteMunicipalId", fornecedor.id, 'Cadastro de Contribuinte Municipal')}
                                    >
                                        Incluir
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                {fornecedor.cadastroContribuinteMunicipalAprovado && (
                                    <h4>Aprovado:  {fornecedor.cadastroContribuinteMunicipalAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                <h4>{fornecedor.cadastroContribuinteMunicipalJustificativa}</h4>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <h4>13. Cadastro de Contribuinte Estadual</h4>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ mb: 2 }} fullWidth>
                                {fornecedor.arquivocadastroContribuinteEstadualId ?
                                    <><Button variant="outlined"
                                        color="secondary"
                                        startIcon={<ArticleOutlinedIcon />}
                                        onClick={() => onOpenDlgVis(fornecedor.arquivocadastroContribuinteEstadualId, fornecedor.id, '')}
                                    >
                                        Visualizar
                                    </Button>
                                        {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                            <Button
                                                color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                onClick={() => onDelete("cadastroContribuinteEstadualId", fornecedor.id, 'Cadastro de Contribuinte Estadual')}
                                            >
                                                Remover
                                            </Button>)}
                                    </>
                                    : <Button
                                        color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                        onClick={() => onOpenDlgAdd("cadastroContribuinteEstadualId", fornecedor.id, 'Cadastro de Contribuinte Estadual')}
                                    >
                                        Incluir
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                {fornecedor.cadastroContribuinteEstadualAprovado && (
                                    <h4>Aprovado:  {fornecedor.cadastroContribuinteEstadualAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                <h4>{fornecedor.cadastroContribuinteEstadualJustificativa}</h4>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <h4>14. Prova de Regularidade - Fazenda Nacional</h4>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ mb: 2 }} fullWidth>
                                {fornecedor.arquivoregularidadeFazendaNacionalId ?
                                    <><Button variant="outlined"
                                        color="secondary"
                                        startIcon={<ArticleOutlinedIcon />}
                                        onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFazendaNacionalId, fornecedor.id, '')}
                                    >
                                        Visualizar
                                    </Button>
                                        {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                            <Button
                                                color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                onClick={() => onDelete("regularidadeFazendaNacionalId", fornecedor.id, 'Prova de Regularidade - Fazenda Nacional')}
                                            >
                                                Remover
                                            </Button>)}
                                    </>
                                    : <Button
                                        color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                        onClick={() => onOpenDlgAdd("regularidadeFazendaNacionalId", fornecedor.id, 'Prova de Regularidade - Fazenda Nacional')}
                                    >
                                        Incluir
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                {fornecedor.regularidadeFazendaNacionalAprovado && (
                                    <h4>Aprovado:  {fornecedor.regularidadeFazendaNacionalAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                <h4>{fornecedor.regularidadeFazendaNacionalJustificativa}</h4>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <h4>15. Prova de Regularidade - Fazenda Estadual</h4>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ mb: 2 }} fullWidth>
                                {fornecedor.arquivoregularidadeFazendaEstadualId ?
                                    <><Button variant="outlined"
                                        color="secondary"
                                        startIcon={<ArticleOutlinedIcon />}
                                        onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFazendaEstadualId, fornecedor.id, '')}
                                    >
                                        Visualizar
                                    </Button>
                                        {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                            <Button
                                                color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                //disabled={disabled}
                                                onClick={() => onDelete("regularidadeFazendaEstadualId", fornecedor.id, 'Prova de Regularidade - Fazenda Estadual')}
                                            >
                                                Remover
                                            </Button>)}
                                    </>
                                    : <Button
                                        color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                        onClick={() => onOpenDlgAdd("regularidadeFazendaEstadualId", fornecedor.id, 'Prova de Regularidade - Fazenda Estadual')}
                                    >
                                        Incluir
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                {fornecedor.regularidadeFazendaEstadualAprovado && (
                                    <h4>Aprovado:  {fornecedor.regularidadeFazendaEstadualAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                <h4>{fornecedor.regularidadeFazendaEstadualJustificativa}</h4>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <h4>16. Prova de Regularidade - Fazenda Municipal</h4>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ mb: 2 }} fullWidth>
                                {fornecedor.arquivoregularidadeFazendaMunicipalId ?
                                    <><Button variant="outlined"
                                        color="secondary"
                                        startIcon={<ArticleOutlinedIcon />}
                                        onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFazendaMunicipalId, fornecedor.id, '')}
                                    >
                                        Visualizar
                                    </Button>
                                        {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                            <Button
                                                color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                //disabled={disabled}
                                                onClick={() => onDelete("regularidadeFazendaMunicipalId", fornecedor.id, 'Prova de Regularidade - Fazenda Municipal')}
                                            >
                                                Remover
                                            </Button>)}
                                    </>
                                    : <Button
                                        color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                        onClick={() => onOpenDlgAdd("regularidadeFazendaMunicipalId", fornecedor.id, 'Prova de Regularidade - Fazenda Municipal')}
                                    >
                                        Incluir
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                {fornecedor.regularidadeFazendaMunicipalAprovado && (
                                    <h4>Aprovado:  {fornecedor.regularidadeFazendaMunicipalAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                <h4>{fornecedor.regularidadeFazendaMunicipalJustificativa}</h4>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <h4>17. Prova de Regularidade - FGTS</h4>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ mb: 2 }} fullWidth>
                                {fornecedor.arquivoregularidadeFgtsId ?
                                    <><Button variant="outlined"
                                        color="secondary"
                                        startIcon={<ArticleOutlinedIcon />}
                                        onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFgtsId, fornecedor.id, '')}
                                    >
                                        Visualizar
                                    </Button>
                                        {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                            <Button
                                                color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                //disabled={disabled}
                                                onClick={() => onDelete("regularidadeFgtsId", fornecedor.id, 'Prova de Regularidade - FGTS')}
                                            >
                                                Remover
                                            </Button>)}
                                    </>
                                    : <Button
                                        color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                        onClick={() => onOpenDlgAdd("regularidadeFgtsId", fornecedor.id, 'Prova de Regularidade - FGTS')}
                                    >
                                        Incluir
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                {fornecedor.regularidadeFgtsAprovado && (
                                    <h4>Aprovado:  {fornecedor.regularidadeFgtsAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                <h4>{fornecedor.regularidadeFgtsJustificativa}</h4>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <h4>18. Prova de Regularidade – Justiça do Trabalho</h4>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl sx={{ mb: 2 }} fullWidth>
                                {fornecedor.arquivoregularidadeJusticaTrabalhoId ?
                                    <><Button variant="outlined"
                                        color="secondary"
                                        startIcon={<ArticleOutlinedIcon />}
                                        onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeJusticaTrabalhoId, fornecedor.id, '')}
                                    >
                                        Visualizar
                                    </Button>
                                        {(fornecedor.estagioFornecedor === "inativo" || fornecedor.estagioFornecedor === "pendencia") && (
                                            <Button
                                                color="warning" variant="outlined" startIcon={<DeleteForeverOutlinedIcon />}
                                                //disabled={disabled}
                                                onClick={() => onDelete("regularidadeJusticaTrabalhoId", fornecedor.id, 'Prova de Regularidade – Justiça do Trabalho')}
                                            >
                                                Remover
                                            </Button>)}
                                    </>
                                    : <Button
                                        color="success" variant="outlined" startIcon={<BackupOutlinedIcon />}
                                        onClick={() => onOpenDlgAdd("regularidadeJusticaTrabalhoId", fornecedor.id, 'Prova de Regularidade – Justiça do Trabalho')}
                                    >
                                        Incluir
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                {fornecedor.regularidadeJusticaTrabalhoAprovado && (
                                    <h4>Aprovado:  {fornecedor.regularidadeJusticaTrabalhoAprovado === 1 ? 'Sim' : 'Não'} </h4>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                                <h4>{fornecedor.regularidadeJusticaTrabalhoJustificativa}</h4>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                            <Checkbox
                                field="habilitado"
                                useFormRef={useFormRef}
                                schema={schema}
                            />
                        </FormControl> */}
                {loading > 0 && (
                    <CircularProgress
                        size={30}
                        sx={{
                            position: 'absolute',
                            left: 25,
                            bottom: 12
                        }}
                    />
                )}
                <Grid container justifyContent="flex-end">
                    {((fornecedor.estagioFornecedor === "inativo"
                        || fornecedor.estagioFornecedor === "pendencia")

                    ) && (
                            <>
                                <Button color="inherit" onClick={() => reset(defaultValues)}>
                                    CANCELAR
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={handleSubmit(onSave)}
                                    disabled={!isDirty}
                                >
                                    SALVAR
                                </Button>
                                {submeterAprovacao
                                    && (
                                        <Button
                                            color="primary"
                                            onClick={handleSolicitaAnalise}
                                        // disabled={!isDirty}
                                        >
                                            SUBMETER PARA APROVAÇÃO
                                        </Button>
                                    )}
                            </>
                        )}



                </Grid>
            </Paper>
        </Box>
    );
};

export default FornecedorCadastro;
