import React from 'react';
import MaterialTable from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import {
  Box, Fab, Paper
} from '@mui/material';
import api from "../../services/api";
import { useStoreActions } from 'easy-peasy';
import DialogoUsuario from './DialogoUsuario';
import { materialTableLocalization } from '../../config';

const Admin = () => {

  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const [rows, setRows] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const loadUsers = async () => {
    startLoading();
    try {
      setRows((await api.http.get('/users')).data);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };
  };

  React.useEffect(async () => {
    startLoading();
    try {
      let getRoles = (await api.http.get('/roles')).data;
      setColumns([
        { field: 'empresa', title: 'Empresa' },
        { field: 'nome', title: 'Nome' },
        { field: 'email', title: 'E-mail' },
        {
          field: 'roleId', title: 'Função',
          lookup: getRoles.reduce((map, role) => {
            map[role.id] = role.name;
            return map;
          }, {})
        },
        { field: 'uf', title: 'Estado' },
        { field: 'habilitado', title: 'Habilitado', type: "boolean" },
      ]);
      setRoles(getRoles);
      await loadUsers();
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  }, []);

  const [openDlgUsuario, setOpenDlgUsuario] = React.useState(false);
  const [usuario, setUsuario] = React.useState(null);
  const onCloseDlgUsuario = (reload = false) => {
    setUsuario(null);
    setOpenDlgUsuario(false);
    if (reload) {
      loadUsers();
    }
  };

  const onRowClick = (event, rowData) => {
    setUsuario(rowData);
    setOpenDlgUsuario(true);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <DialogoUsuario
        open={openDlgUsuario}
        onClose={onCloseDlgUsuario}
        roles={roles}
        usuario={usuario}
      />
      <Paper variant="outlined" className='center-box' sx={{ width: '100%', my: 3 }}>
        <MaterialTable
          columns={columns}
          data={rows}
          title="Usuários"
          onRowClick={onRowClick}
          localization={materialTableLocalization}
        />
      </Paper>
      <Fab color="primary" aria-label="novo processo" onClick={() => {
        setOpenDlgUsuario(true);
      }}>
        <AddIcon />
      </Fab>
    </Box>
  );
};

export default Admin;
