import * as React from "react";
import MaterialTable from "@material-table/core";
import {
    Fab, Paper, Fade, Tooltip,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import api from "../../services/api";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { materialTableLocalization } from "../../config";
import { Rule } from "@mui/icons-material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//import FornecedorDialogoTomada from "./FornecedorDialogoTomada";

import DialogoAcompanhaOrcamentosFaseTwo from "../../components/TomadaPreco/DialogoAcompanhaOrcamentosFaseTwo";

const FornecedorPedidoOrcamento = (fornecedorId) => {
    let user = useStoreState((state) => state.auth.user);
    let startLoading = useStoreActions((actions) => actions.nav.startLoading);
    let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

    const [openDlgViewFaseTwo, setOpenDlgViewFaseTwo] = React.useState(false);
    const [rowDataFaseTwo, setRowDataFaseTwo] = React.useState([]);
    const [dados, setDados] = React.useState();

    const tableRef = React.createRef();
    const [columns, setColumns] = React.useState([]);
    // const [openDlgCadOrcamento, setOpenDlgCadOrcamento] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [fields, setFields] = React.useState([{
        // discrFabr: '', tipoFiname: 'Com codigo FINAME', codFiname: '',
        // hasCodPotencializee: 'Sem Código Potencializee', codpotencializee: '', 
        tipoLampada: 'led', formatoLampada: 'tubular',
        potencia: '20', liminosidade: '2500',
        // fatorPotencia: '0,92', eficienciaLuminosa: '90', temperaturaCor: '4000',
        // tempoGarantia: '3', possuiProcel: 'Sim', classificacaoImetro: 'A',
        // vidaUtil: '25000', quantidade: '20', valorunitario: '10', valortotal: '200', valorprevistointalacao: '100',
        // valorprevistodescarte: '100', total: '400'
    }]);


    const loadOrcamentos = async (query) => {
        startLoading();
        console.log("loadOrcamentos - fornecedorId: ", fornecedorId?.fornecedorId);
        try {
            let getOrcamentos = (
                await api.http.get("/fornecedores/orcamentosprocessos/",
                    {
                        params: {
                            page: query.page + 1,
                            pageSize: query.pageSize,
                            carga: 'orcamento',
                            orderBy: query.orderBy ? query.orderBy.field : undefined,
                            orderDirection: query.orderDirection
                                ? query.orderDirection
                                : undefined,
                            fornecedorId: fornecedorId?.fornecedorId,
                        },
                    }
                )
            ).data[0];

            console.log("orcamentos", getOrcamentos);
            // console.log("orcamentos  current page", getOrcamentos.meta.current_page - 1);
            // console.log("orcamentos total ", getOrcamentos.meta.total);

            stopLoading();
            return {
                data: getOrcamentos,
                page: 0, //getOrcamentos.meta.current_page - 1,
                totalCount: 18, //getOrcamentos.meta.total,
            };
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    };

    React.useEffect(() => {
        setColumns([
            {
                field: "id",
                align: "left",
                title: "Nr. Pedido",
            },
            {
                field: "tipoCarga",
                align: "left",
                title: "Carga",
            },
            {
                field: "estagio",
                align: "left",
                title: "Estágio",
            },
            {
                field: "solicitante",
                align: "left",
                title: "Solicitante",
            },
            {
                field: "localizacao",
                align: "center",
                title: "Cidade/UF",
            },
        ]);
    }, []);

    const onRowClick = async (event, rowData) => {
        startLoading();

        try {
            let getOrcamentos = await api.http.get("/fornecedores/orcamentos/processo/tomada/itens/" + rowData.id);
            setDados(getOrcamentos);

            setRowDataFaseTwo(rowData);
            setOpenDlgViewFaseTwo(true);
            stopLoading();
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });

        };
        setOpen(true);
    };

    const onCloseDlgFaseTwo = () => {
        setOpenDlgViewFaseTwo(false);
    };


    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleAddField = () => {
    //     setFields([...fields, {
    //         // discrFabr: '', tipoFiname: '', codFiname: '',
    //         // hasCodPotencializee: '', codpotencializee: '', 
    //         tipoLampada: '',
    //         formatoLampada: '', potencia: '', liminosidade: '',
    //         // fatorPotencia: '', eficienciaLuminosa: '', temperaturaCor: '',
    //         // tempoGarantia: '', possuiProcel: '', classificacaoImetro: '',
    //         // vidaUtil: '', quantidade: '', valorunitario: '', valortotal: '', valorprevistointalacao: '',
    //         // valorprevistodescarte: '', total: ''
    //     }]);
    // };

    // const handleChange = (index, event) => {
    //     const values = [...fields];
    //     values[index][event.target.name] = event.target.value;
    //     setFields(values);
    // };

    // const handleRemoveField = (index) => {
    //     const values = [...fields];
    //     values.splice(index, 1);
    //     setFields(values);
    // };

    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Paper
            variant="outlined"
            className="center-box"
            sx={{
                textAlign: "center",
                px: 4,
                pt: 2,
                pb: 3,
                m: 5,
                maxWidth: "md",
            }}
        >
            <DialogoAcompanhaOrcamentosFaseTwo open={openDlgViewFaseTwo} onClose={onCloseDlgFaseTwo} orcamento={rowDataFaseTwo} isFornecedor itens={dados} />
            <MaterialTable
                columns={columns}
                data={loadOrcamentos}
                title="2ª Fase Tomada de Preços"
                onRowClick={onRowClick}
                localization={materialTableLocalization}
                tableRef={tableRef}

            />

        </Paper>
    );
};

export default FornecedorPedidoOrcamento;