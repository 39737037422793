import React from 'react';
import MaterialTable from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import {
  Box, Fab, Paper
} from '@mui/material';
import api from "../../services/api";
import { useStoreActions } from 'easy-peasy';
import DialogoEdital from './DialogoEdital';
import { materialTableLocalization } from '../../config';

const Edital = () => {

  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const [rows, setRows] = React.useState([]);
  // const [roles, setRoles] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const loadUsers = async () => {
    startLoading();
    try {
      setRows((await api.http.get('/fornecedores/editais')).data);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };
  };

  React.useEffect(async () => {
    startLoading();
    try {
      // let getRoles =(await api.http.get('/roles')).data;
      setColumns([
        { field: 'titulo', title: 'Título' },
        { field: 'uf', title: 'UF' },
        //{field: 'email', title: 'E-mail'},
        // {field: 'roleId', title: 'Função',
        //  lookup: getRoles.reduce((map,role)=>{
        //    map[role.id]=role.name;
        //    return map;
        //  },{})},
        { field: 'habilitado', title: 'Habilitado', type: "boolean" },
      ]);
      // setRoles(getRoles);
      await loadUsers();
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  }, []);

  const [openDlgEdital, setOpenDlgEdital] = React.useState(false);
  const [edital, setEdital] = React.useState(null);
  const onCloseDlgEdital = (reload = false) => {
    setEdital(null);
    setOpenDlgEdital(false);
    if (reload) {
      loadUsers();
    }
  };

  const onRowClick = (event, rowData) => {
    setEdital(rowData);
    setOpenDlgEdital(true);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <DialogoEdital
        open={openDlgEdital}
        onClose={onCloseDlgEdital}
        edital={edital}
      />
      <Paper variant="outlined" className='center-box' sx={{ width: '100%', my: 3 }}>
        <MaterialTable
          columns={columns}
          data={rows}
          title="Editais para fornecedores"
          onRowClick={onRowClick}
          localization={materialTableLocalization}
        />
      </Paper>
      <Fab color="primary" aria-label="novo edital" onClick={() => {
        setOpenDlgEdital(true);
      }}>
        <AddIcon />
      </Fab>
    </Box>
  );
};

export default Edital;
