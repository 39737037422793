import React, { useState } from 'react';
import { Checkbox, Box, TextField, FormControl, InputLabel, Select, MenuItem, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { tiposCargas } from "../../static";
import { materialTableLocalization } from "../../config";
import MaterialTable from "@material-table/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import api from "../../services/api";
import DialogoAcompanhaOrcamentosFaseTwo from './DialogoAcompanhaOrcamentosFaseTwo';

export default function ListTomadaPreco({ currProcesso, tipoCarga }) {
    let startLoading = useStoreActions((actions) => actions.nav.startLoading);
    let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
    const [listaOrcamentos, setListaOrcamentos] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const tableRef = React.createRef();
    const [reloadTable, setReloadTable] = React.useState(0);
    const [openDlgViewFaseOne, setOpenDlgViewFaseOne] = React.useState(false);
    const [rowDataFaseOne, setRowDataFaseOne] = React.useState([]);
    const [dados, setDados] = React.useState();
    const [calculoFinanceiroDisabled, setCalculoFinanceiroDisabled] = useState(false);
    //let currProcesso = useStoreState((state) => state.processo.current);

    // React.useEffect(() => {
    //     if (reloadTable > 0) tableRef.current && tableRef.current.onQueryChange();
    // }, [reloadTable]);

    React.useEffect(() => {
        const checkCalculoFinanceiroStatus = async () => {
            try {
                startLoading();
                const response = await api.http.get(`orcamentos/processo/orcamento/calculo/financeiro/${currProcesso.id}/${tipoCarga}`);
                const calculoFinanceiro = response.data;
                setCalculoFinanceiroDisabled(calculoFinanceiro.some(item => item.calculoFinanceiro === 1));
                stopLoading();
            } catch (error) {
                stopLoading({
                    message: error.toString(),
                    severity: "error",
                });
            }
        };

        checkCalculoFinanceiroStatus();
    }, [currProcesso.id, tipoCarga]);

    const formatDateToPtBr = (dateString) => {
        if (!dateString) return '';

        // Cria um objeto de data a partir do string
        const date = new Date(dateString);

        // Verifica se a data é válida
        if (isNaN(date.getTime())) return '';

        // Formata a data no padrão pt-BR (dd/mm/yyyy)
        return date.toLocaleDateString('pt-BR').slice(0, 10);
    };

    const loadOrcamentos = async (query) => {
        startLoading();
        try {
            let getOrcamentos = (
                await api.http.get("/orcamentos/processo/tomada/tecnologia", {
                    params: {
                        page: query.page + 1,
                        pageSize: query.pageSize,
                        processoId: currProcesso.id,
                        tecnologia: tipoCarga,
                        estagio: "orcamento",
                        search: query.search,
                        orderBy: query.orderBy ? query.orderBy.field : undefined,
                        orderDirection: query.orderDirection
                            ? query.orderDirection
                            : undefined,
                    },
                })
            ).data;

            console.log("lista tomada de preços getOrcamentos: ", getOrcamentos);

            stopLoading();
            return {
                data: getOrcamentos.data,
                page: getOrcamentos.meta.current_page - 1,
                totalCount: getOrcamentos.meta.total,
            };
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    };

    // React.useEffect(async () => {
    //     startLoading();
    //     try {
    //         setListaOrcamentos(
    //             (
    //                 await api.http.get("/orcamentos/processo/tomadas/", {
    //                     params: {
    //                         roleId: "consultorEE",
    //                         habilitado: true,
    //                         ordenado_alfa: true,
    //                     },
    //                 })
    //             ).data);

    //         stopLoading();
    //     } catch (error) {
    //         stopLoading({
    //             message: error.toString(),
    //             severity: 'error'
    //         });
    //     }
    // }, [])

    const estagios = [
        { cod: "orcamento", descricao: 'Solicitação de orçamento' },
        { cod: "orcamentoresposta", descricao: 'Orçamento Respondido' },
        // { cod: "3", descricao: 'PENDÊNCIA' },
        // { cod: "4", descricao: 'ATIVO' }
    ];

    const getDescricaoEstagio = (datafase2) => {
        // if (datafase2 !== "") {
        //     cod = "orcamentoresposta"
        // }

        const cod = datafase2 === null || datafase2 === undefined ? "orcamento" : "orcamentoresposta";

        const estagio = estagios.find((e) => e.cod === cod);
        return estagio ? estagio.descricao : "Desconhecido";
    };

    const getTipoCarga = (cod) => {
        const nomecarga = tiposCargas.find((e) => e.id === cod);
        return nomecarga ? nomecarga.label : "Desconhecido";
    };

    React.useEffect(() => {
        setColumns([
            {
                field: "id",
                align: "left",
                title: "Nr. Orçamento",
            },
            {

                field: "tipoCarga",
                align: "left",
                title: "Tipo da Carga",
                render: (rowData) => getTipoCarga(rowData.tipoCarga),
            },
            {
                field: "fornecedor.nome",
                align: "left",
                title: "Fornecedor",
            },
            {
                field: "enviofase2At",
                align: "left",
                title: "Data solicitação orçamento",
                render: (rowData) => formatDateToPtBr(rowData.enviofase1At),
            },
            {
                field: "respostafase2At",
                align: "left",
                title: "Data recebimento orçamento",
                render: (rowData) => formatDateToPtBr(rowData.respostafase2At),
            },
            {
                field: "estagio",
                align: "center",
                title: "Status",
                render: (rowData) => getDescricaoEstagio(rowData.respostafase2At),
            },
            {
                field: "selecionado",
                align: "center",
                title: "Selecionado",
                render: (rowData) => (
                    <Checkbox
                        checked={rowData.selecionado === 1}
                    //disabled={true}
                    />
                ),
            },
            {
                field: "calculoFinanceiro",
                align: "center",
                title: "Calculo Financeiro",
                render: (rowData) => (
                    <Checkbox
                        checked={rowData.calculoFinanceiro === 1}
                        disabled={calculoFinanceiroDisabled && rowData.calculoFinanceiro !== 1}
                        onChange={(event) => handleCalculoFinanceiroClick(event, rowData)}
                    />
                ),
            },

        ]);
    }, []);

    const onRowClick = async (event, rowData) => {
        startLoading();
        try {
            let getOrcamentos = await api.http.get("/orcamentos/processo/tomada/itens/" + rowData.id);
            setDados(getOrcamentos);

            setRowDataFaseOne(rowData);
            setOpenDlgViewFaseOne(true);
            // console.log("lista tomada fase 2 - getorcamentos: ", getOrcamentos);
            // console.log("lista tomada fase 2 - rowData: ", rowData);
            stopLoading();
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });

        };
    };

    const onCloseDlgFaseOne = () => {
        setOpenDlgViewFaseOne(false);
    };

    const handleCalculoFinanceiroClick = async (event, rowData) => {
        const newStatus = event.target.checked ? 1 : 0;
        try {
            startLoading();
            await api.http.put(`/orcamentos/processo/orcamento/calculo/financeiro/${rowData.id}`, {
                calculoFinanceiro: newStatus,
            });
            setListaOrcamentos((prev) =>
                prev.map((item) =>
                    item.id === rowData.id
                        ? { ...item, calculoFinanceiro: newStatus }
                        : item
                )
            );
            stopLoading();
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });
        }
    };

    return (
        <>
            <DialogoAcompanhaOrcamentosFaseTwo open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            {/* <Box sx={{ width: '100%', padding: 2 }}> */}
            <Typography variant='subtitle1' align='center'>
                Orçamento 2ª Fase
            </Typography>
            <MaterialTable
                columns={columns}
                data={loadOrcamentos}
                title={getTipoCarga(tipoCarga)}
                onRowClick={onRowClick}
                localization={materialTableLocalization}
                tableRef={tableRef} />
            {/* </Box> */}
        </>
    );
}
