import React, { useState } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { tiposCargas } from "../../static";
import { materialTableLocalization } from "../../config";
import MaterialTable from "@material-table/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import api from "../../services/api";
//import DialogoAcompanhaOrcamentosFaseOne from './DialogoAcompanhaOrcamentosFaseOne';
import AcompanhaAquecedoresFaseOne from './BodyFaseOne/AcompanhaAquecedoresFaseOne';
import AcompanhaBombasFaseOne from './BodyFaseOne/AcompanhaBombasFaseOne';
import AcompanhaCaldeirasFaseOne from "./BodyFaseOne/AcompanhaCaldeirasFaseOne";
import AcompanhaCogeracaoFaseOne from "./BodyFaseOne/AcompanhaCogeracaoFaseOne";
import AcompanhaColetoresSolaresFaseOne from "./BodyFaseOne/AcompanhaColetoresSolaresFaseOne";
import AcompanhaCompressoresFaseOne from "./BodyFaseOne/AcompanhaCompressoresFaseOne";
import AcompanhaCondicionadoresArFaseOne from "./BodyFaseOne/AcompanhaCondicionadoresArFaseOne";
import AcompanhaFornosFaseOne from "./BodyFaseOne/AcompanhaFornosFaseOne";
import AcompanhaIluminacaoFaseOne from "./BodyFaseOne/AcompanhaIluminacaoFaseOne";
import AcompanhaInversoresFrequenciaFaseOne from "./BodyFaseOne/AcompanhaInversoresFrequenciaFaseOne";
import AcompanhaIsolamentoTermicoFaseOne from "./BodyFaseOne/AcompanhaIsolamentoTermicoFaseOne";
import AcompanhaMotoresEletricosFaseOne from "./BodyFaseOne/AcompanhaMotoresEletricosFaseOne";
import AcompanhaQueimadoresFaseOne from "./BodyFaseOne/AcompanhaQueimadoresFaseOne";
import AcompanhaRefrigeracaoFaseOne from "./BodyFaseOne/AcompanhaRefrigeracaoFaseOne";
import AcompanhaReservatoriosTermicosFaseOne from "./BodyFaseOne/AcompanhaReservatoriosTermicosFaseOne";
import AcompanhaSgeFaseOne from "./BodyFaseOne/AcompanhaSgeFaseOne";
import AcompanhaTrocadoresCalorFaseOne from "./BodyFaseOne/AcompanhaTrocadoresCalorFaseOne";
import AcompanhaVentiladoresFaseOne from "./BodyFaseOne/AcompanhaVentiladoresFaseOne";
import AcompanhaBombasCalorFaseOne from './BodyFaseOne/AcompanhaBombasCalorFaseOne';

export default function ListTomadaPreco({ currProcesso, tipoCarga }) {
    let startLoading = useStoreActions((actions) => actions.nav.startLoading);
    let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
    const [listaOrcamentos, setListaOrcamentos] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const tableRef = React.createRef();
    const [reloadTable, setReloadTable] = React.useState(0);
    const [openDlgViewFaseOne, setOpenDlgViewFaseOne] = React.useState(false);
    const [rowDataFaseOne, setRowDataFaseOne] = React.useState([]);
    const [dados, setDados] = React.useState();


    const formatDateToPtBr = (dateString) => {
        if (!dateString) return '';

        // Cria um objeto de data a partir do string
        const date = new Date(dateString);

        // Verifica se a data é válida
        if (isNaN(date.getTime())) return '';

        // Formata a data no padrão pt-BR (dd/mm/yyyy)
        return date.toLocaleDateString('pt-BR').slice(0, 10);
    };

    const loadOrcamentos = async (query) => {
        startLoading();
        try {
            let getOrcamentos = (
                await api.http.get("/orcamentos/processo/tomada/tecnologia", {
                    params: {
                        page: query.page + 1,
                        pageSize: query.pageSize,
                        processoId: currProcesso.id,
                        tecnologia: tipoCarga,
                        estagio: "tomada",
                        search: query.search,
                        orderBy: query.orderBy ? query.orderBy.field : undefined,
                        orderDirection: query.orderDirection
                            ? query.orderDirection
                            : undefined,
                    },
                })
            ).data;

            console.log("lista tomada de preços getOrcamentos: ", getOrcamentos);

            stopLoading();
            return {
                data: getOrcamentos.data,
                page: getOrcamentos.meta.current_page - 1,
                totalCount: getOrcamentos.meta.total,
            };
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    };


    const estagios = [
        { cod: "tomada", descricao: 'Tomada de Preço Solicitado' },
        { cod: "tomadaresposta", descricao: 'Tomada de Preço Respondido' },

    ];

    const getDescricaoEstagio = (datafase1) => {

        const cod = datafase1 === null || datafase1 === undefined ? "tomada" : "tomadaresposta";

        const estagio = estagios.find((e) => e.cod === cod);
        return estagio ? estagio.descricao : "Desconhecido";
    };

    const getTipoCarga = (cod) => {
        const nomecarga = tiposCargas.find((e) => e.id === cod);
        return nomecarga ? nomecarga.label : "Desconhecido";
    };

    React.useEffect(() => {
        setColumns([
            {
                field: "id",
                align: "left",
                title: "Nr. Solicitação"
            },
            {
                field: "tipoCarga",
                align: "left",
                title: "Tipo da Carga",
                render: (rowData) => getTipoCarga(rowData.tipoCarga),
            },
            {
                field: "fornecedor.nome",
                align: "left",
                title: "Fornecedor",
            },
            {
                field: "enviofase1At",
                align: "left",
                title: "Data solicitação fase 1",
                render: (rowData) => formatDateToPtBr(rowData.enviofase1At),
            },
            {
                field: "finishedAt",
                align: "left",
                title: "Data encerramento",
                render: (rowData) => formatDateToPtBr(rowData.finishedAt),
            },
            {
                field: "estagio",
                align: "center",
                title: "Status",
                render: (rowData) => getDescricaoEstagio(rowData.respostafase1At),
            },

        ]);
    }, []);

    const onRowClick = async (event, rowData) => {
        startLoading();
        try {
            let getOrcamentos = await api.http.get("/orcamentos/processo/tomada/itens/" + rowData.id);
            setDados(getOrcamentos);

            setRowDataFaseOne(rowData);
            setOpenDlgViewFaseOne(true);
            stopLoading();
            console.log("getorcamentos: ", getOrcamentos);
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });

        };
    };

    const onCloseDlgFaseOne = () => {
        setOpenDlgViewFaseOne(false);
    };

    return (
        <>
            {/* <DialogoAcompanhaOrcamentosFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} /> */}
            {tipoCarga === 'iluminacao' && (
                <AcompanhaIluminacaoFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'condicionadores_de_ar' && (
                <AcompanhaCondicionadoresArFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'motores_eletricos' && (
                <AcompanhaMotoresEletricosFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'bombas' && (
                <AcompanhaBombasFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'compressores' && (
                <AcompanhaCompressoresFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'ventiladores' && (
                <AcompanhaVentiladoresFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'inversores_de_frequencia' && (
                <AcompanhaInversoresFrequenciaFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'refrigeracao' && (
                <AcompanhaRefrigeracaoFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'sistema_de_gerenciamento_de_energia' && (
                <AcompanhaSgeFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'cogeracao' && (
                <AcompanhaCogeracaoFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'isolamento_termico' && (
                <AcompanhaIsolamentoTermicoFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'caldeiras' && (
                <AcompanhaCaldeirasFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'queimadores' && (
                <AcompanhaQueimadoresFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'fornos' && (
                <AcompanhaFornosFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'aquecedores' && (
                <AcompanhaAquecedoresFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'coletores_solares' && (
                <AcompanhaColetoresSolaresFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'reservatorios_termicos' && (
                <AcompanhaReservatoriosTermicosFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'trocadores_de_calor' && (
                <AcompanhaTrocadoresCalorFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {tipoCarga === 'bombas_de_calor' && (
                <AcompanhaBombasCalorFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} itens={dados} />
            )}
            {/* <Box sx={{ width: '100%', padding: 2 }}> */}
            <Typography variant='subtitle1' align='center'>
                Tomada de preço 1ª Fase
            </Typography>
            <MaterialTable
                columns={columns}
                data={loadOrcamentos}
                title={getTipoCarga(tipoCarga)}
                onRowClick={onRowClick}
                localization={materialTableLocalization}
                tableRef={tableRef} />
            {/* </Box> */}
        </>
    );
}
