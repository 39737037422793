import MaterialTable from "@material-table/core";
import { Box, Paper, Fab } from "@mui/material";
import api from "../../services/api";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { materialTableLocalization } from "../../config";
import { Rule } from "@mui/icons-material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FornecedorCadastro from "./fornecedorcadastro";
import FornecedorPedidoOrcamento from "./FornecedorPedidoOrcamento";
import FornecedorPedidoTomadaPreco from "./FornecedorPedidoTomadaPreco";
import * as React from "react";
import ListTomadaPreco from "../../components/TomadaPreco/ListTomadaPreco";
import { estagiosFornecedor } from "../../static";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Fornecedor = () => {
    let user = useStoreState((state) => state.auth.user);
    let startLoading = useStoreActions((actions) => actions.nav.startLoading);
    let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
    let currProcesso = useStoreState((state) => state.processo.current);

    const [columns, setColumns] = React.useState([]);
    const [value, setValue] = React.useState(0);
    const [fornecedor, setFornecedor] = React.useState([]);

    React.useEffect(async () => {
        startLoading();
        try {
            let data = (await api.http.get(`/fornecedores/interesses?id=${user.id}`))
                .data;
            setFornecedor(data[0]);
            stopLoading();
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getDescricaoEstagio = (cod) => {
        const estagio = estagiosFornecedor.find((e) => e.cod === cod);
        return estagio ? estagio.descricao : "Desconhecido";
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <h4>
                    {fornecedor.nome} - <i>{getDescricaoEstagio(fornecedor.estagioFornecedor)}</i>
                </h4>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="Fornecedor">
                    <Tab label="Cadastro" {...a11yProps(0)} />
                    {fornecedor.estagioFornecedor === "ativo" && (
                        <Tab label="Tomada de preço" {...a11yProps(1)} />
                    )}
                    {fornecedor.estagioFornecedor === "ativo" && (
                        <Tab label="Orçamento" {...a11yProps(2)} />
                    )}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <FornecedorCadastro />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                {/* <ListTomadaPreco currProcesso={currProcesso} /> */}
                <FornecedorPedidoTomadaPreco fornecedorId={fornecedor.id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <FornecedorPedidoOrcamento fornecedorId={fornecedor.id} />
            </CustomTabPanel>
        </>
    );
};

export default Fornecedor;
