import * as React from "react";
import MaterialTable from "@material-table/core";
import {
    Fab, Paper, Fade, Tooltip,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    FormControl,
    Grid,
    TextField
} from "@mui/material";
import api from "../../services/api";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { materialTableLocalization } from "../../config";
import { Rule } from "@mui/icons-material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//import FornecedorDialogoTomada from "./FornecedorDialogoTomada";
import FornecedorDialogoTomada from './FornecedorDialogoTomada';
import { reach } from "yup";
import DialogoAcompanhaOrcamentosFaseOne from "../../components/TomadaPreco/DialogoAcompanhaOrcamentosFaseOne";


const FornecedorPedidoTomadaPreco = (fornecedorId) => {
    let startLoading = useStoreActions((actions) => actions.nav.startLoading);
    let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
    let user = useStoreState((state) => state.auth.user);
    const tableRef = React.createRef();
    const [columns, setColumns] = React.useState([]);
    // const [openDlgCadOrcamento, setOpenDlgCadOrcamento] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [nrPedido, setNrPedido] = React.useState('');
    const [carga, setCarga] = React.useState('');
    const [children, setChildren] = React.useState([]);
    const [openDlgViewFaseOne, setOpenDlgViewFaseOne] = React.useState(false);
    const [rowDataFaseOne, setRowDataFaseOne] = React.useState([]);
    const [dados, setDados] = React.useState();

    const loadOrcamentos = async (query) => {
        startLoading();
        try {
            let getOrcamentos = (
                await api.http.get("/fornecedores/orcamentosprocessos/",
                    {
                        params: {
                            page: query.page + 1,
                            pageSize: query.pageSize,
                            search: query.search,
                            carga: 'tomada',
                            orderBy: query.orderBy ? query.orderBy.field : undefined,
                            orderDirection: query.orderDirection
                                ? query.orderDirection
                                : undefined,
                            fornecedorId: fornecedorId?.fornecedorId,
                        },
                    }
                )
            ).data[0];

            console.log("orcamentos", getOrcamentos);
            // console.log("orcamentos  current page", getOrcamentos.meta.current_page - 1);
            // console.log("orcamentos total ", getOrcamentos.meta.total);

            stopLoading();
            return {
                data: getOrcamentos,
                page: 0, // getOrcamentos.meta.current_page - 1,
                totalCount: 18, // getOrcamentos.meta.total,
            };
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    };

    React.useEffect(() => {
        setColumns([
            {
                field: "id",
                align: "left",
                title: "Nr. Pedido",
            },
            {
                field: "tipoCarga",
                align: "left",
                title: "Carga",
            },
            {
                field: "estagio",
                align: "left",
                title: "Estágio",
            },
            {
                field: "solicitante",
                align: "left",
                title: "Solicitante",
            },
            {
                field: "localizacao",
                align: "center",
                title: "Cidade/UF",
            },
        ]);
    }, []);

    const separarCidadeUf = (localizacao) => {
        const [cidade, uf] = localizacao.split('/');
        return { cidade, uf };
    };

    React.useEffect(() => {
        if (children) {
            setOpen(true);
        }
    }, [children])


    const onRowClick = async (event, rowData) => {
        setChildren(rowData);
        startLoading();

        try {
            let getOrcamentos = await api.http.get("/fornecedores/orcamentos/processo/tomada/itens/" + rowData.id);
            setDados(getOrcamentos);

            setRowDataFaseOne(rowData);
            setOpenDlgViewFaseOne(true);
            stopLoading();
        } catch (error) {
            stopLoading({
                message: error.toString(),
                severity: "error",
            });

        };

        // setTimeout(() => {
        //     setOpen(true);
        // }, 250);
    };

    const onCloseDlgFaseOne = () => {
        setOpenDlgViewFaseOne(false);
    };


    const handleClose = () => setOpen(false);

    return (
        <Paper
            variant="outlined"
            className="center-box"
            sx={{
                textAlign: "center",
                px: 4,
                pt: 2,
                pb: 3,
                m: 5,
                maxWidth: "md",
            }}
        >

            <DialogoAcompanhaOrcamentosFaseOne open={openDlgViewFaseOne} onClose={onCloseDlgFaseOne} orcamento={rowDataFaseOne} isFornecedor itens={dados} />
            {/* <FornecedorDialogoTomada
                open={open}
                handleClose={handleClose}
                title="Tomada de preços"
                children={children}></FornecedorDialogoTomada> */}
            {/* <FornecedorDialogoTomada open={open} handleClose={handleClose} title="Tomada de preços"></FornecedorDialogoTomada> */}
            <MaterialTable
                columns={columns}
                data={loadOrcamentos}
                title="Tomada de Preços"
                onRowClick={onRowClick}
                localization={materialTableLocalization}
                tableRef={tableRef}

            />

        </Paper>
    );
};

export default FornecedorPedidoTomadaPreco