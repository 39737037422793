import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Checkbox as MUICheckbox,
  Tabs,
  Tab,
  Box,
  Fab,
  Paper,
  Link,
} from "@mui/material";

import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Autocomplete, FileListField, Select, DateField } from "../../components";
import api from "../../services/api";
import { tiposCargas } from "../../static";

import { materialTableLocalization } from "../../config";
import MaterialTable from "@material-table/core";

import DialogoSolicitacaoOrcamentosAddNovos from "./DialogoSolicitacaoOrcamentosAddNovos";

import AddTomadaPreco from "../../components/TomadaPreco/AddTomadaPreco";
import ListTomadaPreco from "../../components/TomadaPreco/ListTomadaPreco";
import ListTomadaPrecoAprovado from "../../components/TomadaPreco/ListTomadaPrecoAprovado";

import PropTypes from 'prop-types';
import { format } from 'date-fns'; // Importação da função de formatação
import { ptBR } from 'date-fns/locale'; // Importação da localização para português do Brasil

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogoSolicitacaoOrcamentosNovos = ({ open, onClose, disabled }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let currProcesso = useStoreState((state) => state.processo.current);

  const [columns, setColumns] = React.useState([]);
  const tableRef = React.createRef();
  const [reloadTable, setReloadTable] = React.useState(0);
  const [openDlgAddOrcamento, setOpenDlgAddOrcamento] = React.useState(false);

  const close = () => {
    onClose();
  };

  const onCloseDlgAddOrcamento = (reload = false) => {
    setOpenDlgAddOrcamento(false);
    if (reload) setReloadTable((prev) => prev + 1);
  };

  React.useEffect(() => {
    if (openDlgAddOrcamento === false) {
      setReloadTable((prev) => prev + 1);
    }
  }, [openDlgAddOrcamento]);

  React.useEffect(() => {
    if (reloadTable > 0) tableRef.current && tableRef.current.onQueryChange();
  }, [reloadTable]);

  const loadOrcamentos = async (query) => {
    startLoading();
    try {
      let getOrcamentos = (
        await api.http.get("/orcamentos/processo/list/", {
          params: {
            page: query.page + 1,
            pageSize: query.pageSize,
            estagio: "editando",
            processoId: currProcesso.id,
            search: query.search,
            orderBy: query.orderBy ? query.orderBy.field : undefined,
            orderDirection: query.orderDirection
              ? query.orderDirection
              : undefined,
          },
        })
      ).data;

      stopLoading();
      return {
        data: getOrcamentos.data,
        page: getOrcamentos.meta.current_page - 1,
        totalCount: getOrcamentos.meta.total,
      };
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const estagios = [
    { cod: "editando", descricao: 'Aguardando envio ao fornecedor' },
  ];

  const getDescricaoEstagio = (cod) => {
    const estagio = estagios.find((e) => e.cod === cod);
    return estagio ? estagio.descricao : "Desconhecido";
  };

  const getTipoCarga = (cod) => {
    const nomecarga = tiposCargas.find((e) => e.id === cod);
    return nomecarga ? nomecarga.label : "Desconhecido";
  };

  const enviarParaFornecedores = async (rowData) => {
    startLoading();
    try {
      let postOrcamentos = (
        await api.http.post("/orcamentos/processo/solicitartomada", {
          estagio: "editando",
          processoId: currProcesso.id,
          orcamentoId: rowData.id,
          tipoCarga: rowData.tipoCarga
        })
      ).data;

      console.log("postOrcamentos :> ", postOrcamentos);

      if (postOrcamentos === "SUCESSO") {
        stopLoading({ severity: 'success' });
        setReloadTable((prev) => prev + 1);
      }
      else {
        stopLoading({
          message: postOrcamentos.toString(),
          severity: "error",
        });
      }


    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  const excluirSolicitacao = async (rowData) => {
    startLoading();
    try {
      let putOrcamentos = (
        await api.http.put(`/orcamentos/processo/tomada/excluir/${rowData.id}`, {
          enviofase2At: new Date()
        })
      ).data;

      console.log("putOrcamentos: ", putOrcamentos);

      stopLoading();
      setReloadTable((prev) => prev + 1);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  React.useEffect(() => {
    setColumns([
      {
        field: "tipoCarga",
        align: "left",
        title: "Tipo da Carga",
        render: (rowData) => getTipoCarga(rowData.tipoCarga),
      },
      {
        field: "createdAt",
        align: "left",
        title: "Data solicitação",
        render: (rowData) => format(new Date(rowData.createdAt), 'dd/MM/yyyy', { locale: ptBR }), // Formatando a data
      },
      {
        field: "finishedAt",
        align: "left",
        title: "Data encerramento",
        render: (rowData) => rowData.finishedAt ? format(new Date(rowData.finishedAt), 'dd/MM/yyyy', { locale: ptBR }) : "-", // Formatando a data ou exibindo "-"
      },
      {
        field: "estagio",
        align: "center",
        title: "Status",
        render: (rowData) => getDescricaoEstagio(rowData.estagio),
      },
      {
        field: "id",
        align: "left",
        title: "Solicitar",
        render: (rowData) => (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              enviarParaFornecedores(rowData);
            }}
          >
            Solicitar
          </Button>
        ),
      },
      {
        field: "id",
        align: "left",
        title: "Remover",
        render: (rowData) => (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              excluirSolicitacao(rowData);
            }}
          >
            Remover
          </Button>
        ),
      },
    ]);
  }, []);

  const onRowClick = (event, rowData) => {
    // Lógica para clique na linha
  };

  return (
    <Dialog open={open} onClose={() => close()} fullScreen>
      <DialogTitle>Tomada de preços</DialogTitle>
      <DialogContent dividers>
        <Paper variant="outlined" className="center-box" sx={{ width: "100%", my: 3 }}>
          <DialogoSolicitacaoOrcamentosAddNovos
            open={openDlgAddOrcamento}
            onClose={onCloseDlgAddOrcamento}
          />
          <MaterialTable
            columns={columns}
            data={loadOrcamentos}
            title="Fornecedores"
            onRowClick={onRowClick}
            localization={materialTableLocalization}
            tableRef={tableRef}
          />
        </Paper>
      </DialogContent>
      <DialogActions sx={{ marginTop: "20px" }}>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button color="inherit" onClick={() => close()} sx={{ mr: "90px" }}>
          FECHAR
        </Button>
        <Fab
          color="primary"
          aria-label="novo orcamento"
          onClick={() => {
            setOpenDlgAddOrcamento(true);
          }}
        >
          <AddIcon />
        </Fab>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoSolicitacaoOrcamentosNovos;