import React, { useState } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid } from '@mui/material';
import { tiposCargas, tituloTabelaAddTomadaPorCarga, opcoesTabelaAddTomadaPorCarga } from "../../static";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import api from "../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";

import AddIluminacao from "./AddTomada/AddIluminacaoFaseOne";
import AddCondicionadoresAr from "./AddTomada/AddCondicionadoresArFaseOne";
import AddMotoresEletricos from "./AddTomada/AddMotoresEletricosFaseOne";
import AddBombas from "./AddTomada/AddBombasFaseOne";
import AddCompressores from "./AddTomada/AddCompressoresFaseOne";
import AddVentiladores from "./AddTomada/AddVentiladoresFaseOne";
import AddInversoresFrequencia from "./AddTomada/AddInversoresFrequenciaFaseOne";
import AddRefrigeracao from "./AddTomada/AddRefrigeracaoFaseOne";
import AddSge from "./AddTomada/AddSgeFaseOne";
import AddCogeracao from "./AddTomada/AddCogeracaoFaseOne";
import AddIsolamentoTermico from "./AddTomada/AddIsolamentoTermicoFaseOne";
import AddCaldeiras from "./AddTomada/AddCaldeirasFaseOne";
import AddQueimadores from "./AddTomada/AddQueimadoresFaseOne";
import AddFornos from "./AddTomada/AddFornosFaseOne";
import AddAquecedores from './AddTomada/AddAquecedoresFaseOne';
import AddColetoresSolares from "./AddTomada/AddColetoresSolaresFaseOne";
import AddReservatoriosTermicos from "./AddTomada/AddReservatoriosTermicosFaseOne";
import AddTrocadoresCalor from "./AddTomada/AddTrocadoresCalorFaseOne";
import AddBombasCalor from "./AddTomada/AddBombasCalorFaseOne";
import { Add } from '@mui/icons-material';

export default function AddTomadaPreco({ currProcesso, Close }) {

    let loading = useStoreState((state) => state.nav.loading);
    let startLoading = useStoreActions((actions) => actions.nav.startLoading);
    let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

    const [tipoCarga, setTipoCarga] = useState('');
    const [finishedAt, setFinishedAt] = useState('');

    const handleChangeTipoCarga = (event) => {
        setTipoCarga(event.target.value);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="body1">Tomada de Preço - Processo {currProcesso?.razaoSocial}</Typography>

            <Grid container spacing={1}>
                <Grid item xs={4} >
                    {/* Campo finishedAt */}
                    <TextField
                        fullWidth
                        label="Data de encerramento da tomada de preços"
                        type="date"
                        value={finishedAt}
                        onChange={(e) => setFinishedAt(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ mt: 2 }}
                    />
                </Grid>
                <Grid item xs={8} >

                    {/* Select tipoCarga */}
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Tecnologia</InputLabel>
                        <Select value={tipoCarga} onChange={handleChangeTipoCarga} label="Tipo de Carga">
                            {tiposCargas.map((carga) => (
                                <MenuItem key={carga.id} value={carga.id}>
                                    {carga.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {tipoCarga === "iluminacao" && (
                <AddIluminacao currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "condicionadores_de_ar" && (
                <AddCondicionadoresAr currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "motores_eletricos" && (
                <AddMotoresEletricos currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "bombas" && (
                <AddBombas currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "compressores" && (
                <AddCompressores currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "ventiladores" && (
                <AddVentiladores currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "inversores_de_frequencia" && (
                <AddInversoresFrequencia currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "refrigeracao" && (
                <AddRefrigeracao currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "sistema_de_gerenciamento_de_energia" && (
                <AddSge currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "cogeracao" && (
                <AddCogeracao currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "isolamento_termico" && (
                <AddIsolamentoTermico currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "caldeiras" && (
                <AddCaldeiras currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "queimadores" && (
                <AddQueimadores currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "fornos" && (
                <AddFornos currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "aquecedores" && (
                <AddAquecedores currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "coletores_solares" && (
                <AddColetoresSolares currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "reservatorios_termicos" && (
                <AddReservatoriosTermicos currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "trocadores_de_calor" && (
                <AddTrocadoresCalor currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}
            {tipoCarga === "bombas_de_calor" && (
                <AddBombasCalor currProcesso={currProcesso} onClose={Close} datamaxima={finishedAt} tipoCarga={tipoCarga} />
            )}

        </Box>
    );
}