import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  CircularProgress,
  Paper,
  Grid
} from '@mui/material';
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  TextField,
  Select,
  Checkbox,
  SelectUF,
} from '../../components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import HelpDialog from './HelpDialog.js';

yup.setLocale(pt);


const InstFinSelect = ({ useFormRef, schema }) => {
  const [listaInstFinanceiras, setListaInstFinanceiras] = React.useState([]);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(async () => {
    startLoading();
    try {
      setListaInstFinanceiras((await api.http.get('/inst-financeiras')).data);
      stopLoading();
      setMounted(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  }, []);

  return (
    <FormControl sx={{ my: 2 }} fullWidth>
      {mounted &&
        <Select
          required
          field="instFinanceiraId"
          useFormRef={useFormRef}
          schema={schema}
          options={listaInstFinanceiras}
          getOptionLabel={option => option.nome}
        />
      }
    </FormControl>
  );
};

const DialogoUsuario = ({ open, onClose, roles, usuario }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [states, setStates] = React.useState([]);

  const defaultValues = {
    empresa: '',
    nome: '',
    email: '',
    roleId: '',
    habilitado: true
  };
  const schema = yup.object({
    empresa: yup.string().label('Empresa').required(),
    nome: yup.string().label('Nome').required(),
    email: yup.string().email().label('Email').required(),
    roleId: yup.string().label('Função').required(),
    instFinanceiraId: yup.number().positive().nullable()
      .label('Instituição Financeira')
      .when('roleId', {
        is: 'instFin',
        then: (schema) => schema.required()
      }),
    uf: yup.string().label("Estado").required(),
    habilitado: yup.boolean().label('Habilitado').required()
  }).required();
  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { handleSubmit, reset, watch,
    formState: { isDirty, isSubmitting } } = useFormRef;

  const roleId = watch('roleId');

  const onSave = async (data) => {
    startLoading();
    try {
      if (data.id) {
        await api.http.put('/users/' + data.id, data);
      } else {
        await api.http.post('/users', data);
      }
      stopLoading({ severity: 'success' });
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const close = (reload = false) => {
    reset(defaultValues);
    onClose(reload);
  };

  React.useEffect(() => {
    reset(usuario);
  }, [usuario]);

  React.useEffect(() => {
    ///recuperar dados da api ibge e preencher o dropdow estados
    async function fetchStates() {
      try {
        const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        const data = await response.json();

        const sortedStates = data.sort((a, b) => {
          return a.nome.localeCompare(b.nome); // Comparando os nomes dos estados
        });

        setStates(sortedStates);

      } catch (error) {
        console.error('Error fetching states:', error);
      }
    }

    fetchStates();
  }, [usuario]);

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Dados do usuário</DialogTitle>
      <DialogContent>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="empresa"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="nome"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="email"
            type="email"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <SelectUF
            field="uf"
            useFormRef={useFormRef}
            schema={schema}
            //disabled={disabled}
            options={states}
            getOptionLabel={(option) => option.nome}
          />
        </FormControl>
        <Grid container direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid item xs={10}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <Select
                field="roleId"
                useFormRef={useFormRef}
                schema={schema}
                options={roles}
                getOptionLabel={option => option.name}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: 'center' }}>
            <HelpDialog />

          </Grid>
        </Grid>
        {roleId == 'instFin' &&
          <InstFinSelect useFormRef={useFormRef} schema={schema} />
        }
        <FormControl sx={{ my: 2 }} fullWidth>
          <Checkbox field="habilitado"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        {loading > 0 && <CircularProgress
          size={30}
          sx={{
            'position': 'absolute',
            'left': 25,
            'bottom': 12
          }} />}
        <Button color="inherit"
          onClick={() => close()}
          disabled={isSubmitting}>
          CANCELAR
        </Button>
        <Button color="primary"
          onClick={handleSubmit(onSave)}
          disabled={!isDirty || isSubmitting}>
          SALVAR
        </Button>
      </DialogActions>
    </Dialog>

  );
};


export default DialogoUsuario;
