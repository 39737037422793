import MaterialTable from "@material-table/core";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";

import api from "../../services/api";

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AcompanhaOrcamentosFase1Novo from '../../components/TomadaPreco/AcompanhaOrcamentosFase1Novo';
import AcompanhaOrcamentosFase2Novo from '../../components/TomadaPreco/AcompanhaOrcamentosFase2Novo';
import Typography from '@mui/material/Typography';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DialogoAcompanhaOrcamentosNovo = ({ open, onClose }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let currProcesso = useStoreState((state) => state.processo.current);
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth={true}>
      <DialogContent>
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', height: "100vh" }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="1ª Fase" {...a11yProps(0)} />
              <Tab label="2ª Fase" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <AcompanhaOrcamentosFase1Novo />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AcompanhaOrcamentosFase2Novo />
          </CustomTabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoAcompanhaOrcamentosNovo;
