import MaterialTable from "@material-table/core";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import ListTomadaPreco from '../../components/TomadaPreco/ListTomadaPreco'
import api from "../../services/api";

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const DialogoAcompanhaOrcamentosNovo = () => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let currProcesso = useStoreState((state) => state.processo.current);
  const [value, setValue] = React.useState(0);
  const [tecnologiasSolicitadas, setTecnologiasSolicitas] = React.useState([]);

  React.useEffect(async () => {

    console.log("passou por aqui acompanha fase 1");
    startLoading();
    try {
      let getTecnologias = await api.http.get(`/orcamentos/processo/tomada/tecnologia/${currProcesso.id}/tomada/`);
      setTecnologiasSolicitas(getTecnologias.data);
      stopLoading();
      console.log("getTecnologias: ", getTecnologias.data);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });

    };

  }, []);

  const verificaTipoCarga = (tipo) => {
    return tecnologiasSolicitadas.some(item => item.tipo_carga === tipo);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "100vh" }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {/* {verificaTipoCarga("iluminacao") && ( */}
        <Tab label="ILUMINAÇÃO" {...a11yProps(0)} />
        {/* )}
        {verificaTipoCarga("condicionadores_de_ar") && ( */}
        <Tab label="CONDICIONADORES DE AR" {...a11yProps(1)} />
        {/* )}
        {verificaTipoCarga("motores_eletricos") && ( */}
        <Tab label="MOTORES ELÉTRICOS" {...a11yProps(2)} />
        {/* )}
        {verificaTipoCarga("bombas") && ( */}
        <Tab label="BOMBAS" {...a11yProps(3)} />
        {/* )}
        {verificaTipoCarga("compressores") && ( */}
        <Tab label="COMPRESSORES" {...a11yProps(4)} />
        {/* )}
        {verificaTipoCarga("ventiladores") && ( */}
        <Tab label="VENTILADORES" {...a11yProps(5)} />
        {/* )}
        {verificaTipoCarga("inversores_de_frequencia") && ( */}
        <Tab label="INVERSORES DE FREQUÊNCIA" {...a11yProps(6)} />
        {/* )}
        {verificaTipoCarga("refrigeracao") && ( */}
        <Tab label="REFRIGERAÇÃO" {...a11yProps(7)} />
        {/* )}
        {verificaTipoCarga("sistema_de_gerenciamento_de_energia") && ( */}
        <Tab label="SISTEMA DE GERENCIAMENTO DE ENERGIA (SGE)" {...a11yProps(8)} />
        {/* )}
        {verificaTipoCarga("cogeracao") && ( */}
        <Tab label="COGERAÇÃO" {...a11yProps(9)} />
        {/* )}
        {verificaTipoCarga("ilumiisolamento_termiconacao") && ( */}
        <Tab label="ISOLAMENTO TÉRMICO" {...a11yProps(10)} />
        {/* )}
        {verificaTipoCarga("caldeiras") && ( */}
        <Tab label="CALDEIRAS" {...a11yProps(11)} />
        {/* )}
        {verificaTipoCarga("queimadores") && ( */}
        <Tab label="QUEIMADORES" {...a11yProps(12)} />
        {/* )}
        {verificaTipoCarga("fornos") && ( */}
        <Tab label="FORNOS" {...a11yProps(13)} />
        {/* )}
        {verificaTipoCarga("aquecedores") && ( */}
        <Tab label="AQUECEDORES" {...a11yProps(14)} />
        {/* )}
        {verificaTipoCarga("coletores_solares") && ( */}
        <Tab label="COLETORES SOLARES" {...a11yProps(15)} />
        {/* )}
        {verificaTipoCarga("reservatorios_termicos") && ( */}
        <Tab label="RESERVATÓRIOS TÉRMICOS" {...a11yProps(16)} />
        {/* )}
        {verificaTipoCarga("trocadores_de_calor") && ( */}
        <Tab label="TROCADORES DE CALOR" {...a11yProps(17)} />
        {/* )}
        {verificaTipoCarga("bombas_de_calor") && ( */}
        <Tab label="BOMBAS DE CALOR" {...a11yProps(18)} />
        {/* )} */}
      </Tabs>
      <Box sx={{ width: '85%' }}>
        <TabPanel value={value} index={0}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'iluminacao'} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'condicionadores_de_ar'} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'motores_eletricos'} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'bombas'} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'compressores'} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'ventiladores'} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'inversores_de_frequencia'} />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'refrigeracao'} />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'sistema_de_gerenciamento_de_energia'} />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'cogeracao'} />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'isolamento_termico'} />
        </TabPanel>
        <TabPanel value={value} index={11}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'caldeiras'} />
        </TabPanel>
        <TabPanel value={value} index={12}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'queimadores'} />
        </TabPanel>
        <TabPanel value={value} index={13}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'fornos'} />
        </TabPanel>
        <TabPanel value={value} index={14}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'aquecedores'} />
        </TabPanel>
        <TabPanel value={value} index={15}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'coletores_solares'} />
        </TabPanel>
        <TabPanel value={value} index={16}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'reservatorios_termicos'} />
        </TabPanel>
        <TabPanel value={value} index={17}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'trocadores_de_calor'} />
        </TabPanel>
        <TabPanel value={value} index={18}>
          <ListTomadaPreco currProcesso={currProcesso} tipoCarga={'bombas_de_calor'} />
        </TabPanel>
      </Box>
    </Box>

  );
};

export default DialogoAcompanhaOrcamentosNovo;
