import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Paper,
  Button,
  FormHelperText,
  FormControl,
  Box,
  Typography,
  Container,
  Grid,
  autocompleteClasses
} from '@mui/material';
import { DialogoMensagem, Select, TextField } from '../../components';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  useNavigate,
} from "react-router-dom";
import { useRecaptcha } from "react-hook-recaptcha";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useStoreActions } from 'easy-peasy';
import api from "../../services/api";
import {
  CNPJField,
} from '../../components';

yup.setLocale(pt);

const sitekey = "6LeK1MceAAAAACM096lU71zJeznaIVqLuzuwvDE0";
const containerId = "recaptcha_id";

const roles = [{
  id: 'fornecedor', name: 'Fornecedor'
}];


const InscFornecedor = () => {
  const setCnpjValidado = useStoreActions((actions) => actions.interesse.setCnpjValidado);
  const navigate = useNavigate();
  //let recaptchaInstance;

  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [captchaErrorMsg, setCaptchaErrorMsg] = useState(null);
  const [cnpjErrorMsg, setCnpjErrorMsg] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [linkEdital, setLinkEdital] = useState(null);
  const [openMensagem, setOpenMensagem] = useState(false);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);


  const onSubmit = async (data) => {
    //const existecadastro = await existeCNPJ();
    // if (!existecadastro) {
    //   setCnpjErrorMsg("Já existe um cadastro com este CNPJ.");
    // }
    // else
    if (captchaResponse == null) {
      setCaptchaErrorMsg('Captcha inválido');
    } else {

      startLoading();
      try {
        await api.http.post('/fornecedores/interesses', data);
        window._paq.push(["FormAnalytics::trackFormConversion", "potencializee"]);
        stopLoading();
        setOpenMensagem(true);
      } catch (error) {
        stopLoading({
          message: error.toString(),
          severity: "error",
        });
        throw error;
      }
    }
  };

  const defaultValues = {
    empresa: '',
    cnpj: '',
    email: '',
    roleId: 'fornecedor',
    habilitado: true
  };

  const schema = yup.object({
    empresa: yup.string().label('Empresa').required(),
    cnpj: yup.string().cnpj().label('CNPJ').required(),
    email: yup.string().email().label('Email').required(),
    roleId: yup.string().label('Função').required(),
    // instFinanceiraId: yup.number().positive().nullable()
    //   .label('Instituição Financeira')
    //   .when('roleId', {
    //     is: 'instFin',
    //     then: (schema) => schema.required()
    //   }),
    // habilitado: yup.boolean().label('Habilitado').required()
  }).required();

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { handleSubmit, watch } = useFormRef;

  const cnpj = watch("cnpj");

  const successCallback = (response) => {
    setCaptchaResponse(response);
    setCaptchaErrorMsg(null);
  };

  const expiredCallback = () => {
    setCaptchaResponse(null);
  };

  useRecaptcha({
    containerId,
    successCallback,
    expiredCallback,
    sitekey,
    size: "normal",
  });


  const onOkMensagem = () => {
    navigate("/fornecedor", { replace: true });
  };

  //React.useEffect(async () => {
  // const existeCNPJ = async () => {
  //   console.log("digitou o cnpj ", cnpj);
  //   //// validar cnpj


  //   //// validar se já esta cadastrado na base
  //   const fornecedor = (
  //     await api.http.get("/fornecedores/exists/cnpj/" + cnpj.replace(/\D/g, '')));

  //   console.log("retorno cnpj ", fornecedor);

  //   if (!fornecedor) {
  //     return false;
  //   }
  //   return true;



  // };

  const handleBlur = async (e) => {
    const cnpj = e.target.value.replace(/\D/g, ''); // Remove qualquer caractere não numérico

    // Verificar se o CNPJ foi preenchido
    if (cnpj) {
      try {
        const fornecedor = await api.http.get("/fornecedores/exists/cnpj/" + cnpj);

        // Se a API retornar algum dado relevante, desabilitar o botão
        // console.log("fornecedor", fornecedor)
        // console.log("fornecedor.data", fornecedor.data[0]?.id)
        if (fornecedor && fornecedor.data[0]?.id) {
          setIsButtonDisabled(true);
          setCnpjErrorMsg("Já existe um cadastro com este CNPJ.");
        } else {
          // Se não houver dados, habilitar o botão
          setIsButtonDisabled(false);
          setCnpjErrorMsg(null);
        }
      } catch (error) {
        console.error('Erro ao buscar fornecedor:', error);
        // Em caso de erro, habilitar o botão
        setIsButtonDisabled(false);
      }
    } else {
      // Se o campo CNPJ estiver vazio, habilitar o botão
      setIsButtonDisabled(false);
    }
  };


  return (
    <Container sx={{ mt: 3 }}>
      <Typography color="inherit" variant="h5">
        Preencha o formulário abaixo e participe!
      </Typography>

      <Paper variant="outlined" className='center-box' sx={{
        px: 4,
        pt: 2,
        pb: 3,
        mb: 7
      }}>
        <DialogoMensagem title="Sucesso" open={openMensagem} onOk={onOkMensagem}>
          Sua inscição foi registrada com sucesso em nosso sistema. Em breve
          entraremos em contato com o responsável legal.
        </DialogoMensagem>
        <p>Inicie a participação de sua empresa como fornecedora de tecnologia</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              field="empresa"
              useFormRef={useFormRef} schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <CNPJField
              margin="dense"
              fullWidth
              field="cnpj"
              useFormRef={useFormRef}
              schema={schema}
              onBlur={handleBlur} />
          </FormControl>
          {cnpjErrorMsg && (
            <Box sx={{ my: 3 }}>
              <h3>{cnpjErrorMsg}</h3>
            </Box>
          )}
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              field="email"
              type="email"
              useFormRef={useFormRef} schema={schema} />
          </FormControl>

        </form>
        <Box sx={{ my: 3 }}>
          <div id={containerId} />
          {captchaErrorMsg && <FormHelperText error={true}>{captchaErrorMsg}</FormHelperText>}
        </Box>
        <Button variant="contained"
          size="large"
          endIcon={<ArrowForwardIcon />}
          onClick={handleSubmit(onSubmit)}
          disabled={isButtonDisabled}>INSCREVER</Button>
        <br />
        <br />
        <p>
          O PotencializEE – Programa Investimentos Transformadores de Eficiência Energética na Indústria – tem o intuito de promover Eficiência Energética em PMEs (Pequenas e Médias Empresas) industriais. A implementação tem foco na região de São Paulo, considerando a maior concentração de indústrias e os altos índices de consumo de energia, e contará com estratégias a fim de alavancar os potenciais de Eficiência Energética na Indústria em nível nacional.
        </p>
        <p>
          A iniciativa é um Programa de Cooperação Brasil-Alemanha para o Desenvolvimento Sustentável, liderado pelo MME (Ministério de Minas e Energia) e coordenado por meio da GIZ (Deutsche Gesellschaft für Internationale Zusammenarbeit), Agência Alemã de Cooperação Internacional.
        </p>
      </Paper>
    </Container>
  );
};

export default InscFornecedor;
