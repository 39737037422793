import React, { useState, useMemo } from "react";
import {
  Button, Checkbox as MUICheckbox,
  Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TableFooter,
  TextField,
} from "@mui/material";
import { tituloTabelaAddOrcamentoPorCarga } from "../../../static";
import { formatCurrency, parseValue } from "../../../services/utils";
import api from "../../../services/api";
import { useStoreActions } from "easy-peasy";

const MotoresEletricosFaseTwoTabelaItens = ({ rows = [] , orcamento, isFornecedor = false, save, send,  onDataSend }) => {

  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const totals = useMemo(() => {
    // // Garantir que rows seja um array v�lido antes de usar reduce
    const validRows = Array.isArray(rows) ? rows : [];
    return validRows.reduce(
      (acc, row) => ({
        coluna14: acc.coluna14 + parseValue(row.coluna14),
        coluna15: acc.coluna15 + parseValue(row.coluna15),
        coluna16: acc.coluna16 + parseValue(row.coluna16),
        colunaB13: acc.colunaB13 + parseValue(row.colunaB13),
        colunaB14: acc.colunaB14 + parseValue(row.colunaB14),
        colunaB15: acc.colunaB15 + parseValue(row.colunaB15),
      }),
      { coluna14: 0, coluna15: 0, coluna16: 0, colunaB13: 0, colunaB14: 0, colunaB15: 0 }
    );
  }, [rows]);

  const saveData = React.useCallback(async (send = false) => {
    startLoading();
    console.log("Itens salvando: ", rows);
    try {
      await api.http.put("/fornecedores/orcamentos/processo/tomada/alterarorcamento/" + orcamento?.id
        , {
          tomadas: rows,
          respostaFase2At: send,
      });
      onDataSend(true);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  }, [rows, orcamento?.id, onDataSend, startLoading, stopLoading]);

  React.useEffect(() => {
    if (save) {
      saveData();
    }
  }, [save, saveData]);

    React.useEffect(() => {
    if (send) {
      saveData(true);
    }
  }, [send, saveData]);

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaA1}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaA2}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaA3}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaA4}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaA5}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.coluna1}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.coluna2}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.coluna3}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.coluna4}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaB1}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaB2}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaB3}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaB4}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaB5}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.coluna14}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.coluna15}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.coluna16}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaB13}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaB14}</TableCell>
              <TableCell align="center">{tituloTabelaAddOrcamentoPorCarga.motores_eletricos.colunaB15}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {isFornecedor ? 
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={row.colunaA1}
                      onChange={(e) => {
                        rows[index].colunaA1 = e.target.value;
                      }}
                    />
                    : row.colunaA1}
                    </TableCell>
                <TableCell align="center">
                  {row.colunaA2}
                </TableCell>
                <TableCell align="center">
                  {isFornecedor ?
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={row.colunaA3}
                      onChange={(e) => {
                        rows[index].colunaA3 = e.target.value;
                      }}
                    />
                    : row.colunaA3}
                </TableCell>
                <TableCell align="center">
                  {row.colunaA4}
                </TableCell>
                <TableCell align="center">
                  {isFornecedor ?
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={row.colunaA5}
                      onChange={(e) => {
                        rows[index].colunaA5 = e.target.value;
                      }}
                    />
                    : row.colunaA5}
                </TableCell>
                <TableCell align="center">{row.coluna1}</TableCell>
                <TableCell align="center">{row.coluna2}</TableCell>
                <TableCell align="center">{row.coluna3}</TableCell>
                <TableCell align="center">{row.coluna4}</TableCell>
                <TableCell align="center">
                  {isFornecedor ?
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={row.colunaB1}
                      onChange={(e) =>{
                        rows[index].colunaB1 = e.target.value;
                      }}
                    />
                    : row.colunaB1}
                </TableCell>
                <TableCell align="center">
                  {isFornecedor ?
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={row.colunaB2}
                      onChange={(e) => {
                        rows[index].colunaB2 = e.target.value;
                      }}
                    />
                    : row.colunaB2}
                </TableCell>
                <TableCell align="center">
                  {isFornecedor ?
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={row.colunaB3}
                      onChange={(e) => {
                        rows[index].colunaB3 = e.target.value;
                      }}
                    />
                    : row.colunaB3}
                </TableCell>
                <TableCell align="center">
                  {isFornecedor ?
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={row.colunaB4}
                      onChange={(e) => {
                        rows[index].colunaB4 = e.target.value;
                      }}
                    />
                    : row.colunaB4}
                </TableCell>
                <TableCell align="center">
                  {isFornecedor ?
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      value={row.colunaB5}
                      onChange={(e) => {
                        rows[index].colunaB5 = e.target.value;
                      }}
                    />
                    : row.colunaB5}
                </TableCell>
                <TableCell align="center">{row.coluna14}</TableCell>
                <TableCell align="center">{formatCurrency(row.coluna15)}</TableCell>
                <TableCell align="center">{formatCurrency(row.coluna16)}</TableCell>
                <TableCell align="center">{formatCurrency(row.colunaB13)}</TableCell>
                <TableCell align="center">{formatCurrency(row.colunaB14)}</TableCell>
                <TableCell align="center">{formatCurrency(row.colunaB15)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow >
              <TableCell align="center" colSpan={17}>Totais</TableCell>
              <TableCell align="center">{formatCurrency(totals.coluna14)}</TableCell>
              <TableCell align="center">{formatCurrency(totals.coluna15)}</TableCell>
              <TableCell align="center">{formatCurrency(totals.coluna16)}</TableCell>
              <TableCell align="center">{formatCurrency(totals.colunaB13)}</TableCell>
              <TableCell align="center">{formatCurrency(totals.colunaB14)}</TableCell>
              <TableCell align="center">{formatCurrency(totals.colunaB15)}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default MotoresEletricosFaseTwoTabelaItens;
