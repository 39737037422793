export const estagioFornecedor = [
  {
    id: 1,
    estagio: "inativo",
    label: "Apenas criou o acesso ao sistema , com senha e login mas ainda não submeteu os documentos e informações para análise ou quando o processo dele for reprovado"
  },
  {
    id: 2,
    estagio: "analise",
    label: "Quando envia todos os documentos tanto no cadastro quanto quando ele reenviar documentos que estavam com pendência"
  },
  {
    id: 3,
    estagio: "pendencia",
    label: "Quando algum documento ou informação não estiver ok, e ele ainda não tiver corrigido/reenviado"
  },
  {
    id: 4,
    estagio: "ativo",
    label: "Quando o credenciamento for aprovado pelo Apoio SENAI e ele passar a compor o banco de fornecedores"
  },
];

export const deparaTecnologiasSistemasTomadaPreco =
{
  "aquecedores": [
    { "A": 7, "B": "A", "C": "Aquecimento / Processo de calor em geral" },
    { "A": 5, "B": "A", "C": "Recuperação de calor por refrigeração para geração de água quente" },
    { "A": 8, "B": "A", "C": "Solar térmico" }
  ],
  "compressores": [
    { "A": 21, "B": "C", "C": "Ar comprimido" },
    { "A": 16, "B": "B", "C": "Consolidação da oferta" }
  ],
  "bombas_de_calor": [
    { "A": 9, "B": "A", "C": "Bombas de calor (fonte de ar, fonte de solo e fonte de água)" }
  ],
  "caldeiras": [
    { "A": 10, "B": "A", "C": "Caldeira de biomassa" },
    { "A": 2, "B": "A", "C": "Instalações e sistemas de caldeiras a vapor" }
  ],
  "reservatorios_termicos": [
    { "A": 11, "B": "A", "C": "Calor e energia combinados (CHP) (convencional, célula de combustível ou biomassa, excluindo carvão)" },
    { "A": 4, "B": "A", "C": "Recuperação de calor de ar" }
  ],
  "trocadores_de_calor": [
    { "A": 6, "B": "A", "C": "Desperdício de calor para eletricidade" },
    { "A": 12, "B": "A", "C": "Elementos de construção e intervenções térmicas passivas" }
  ],
  "fornos": [
    { "A": 3, "B": "A", "C": "Economizadores de condensação e gases de combustão" }
  ],
  "iluminacao": [
    { "A": 17, "B": "C", "C": "Iluminação" }
  ],
  "isolamento_termico": [
    { "A": 1, "B": "A", "C": "Isolamento térmico" }
  ],
  "motores_eletricos": [
    { "A": 18, "B": "C", "C": "Motores e acionamentos" }
  ],
  "inversores_de_frequencia": [
    { "A": 23, "B": "C", "C": "Redução / otimização de tensão" },
    { "A": 22, "B": "C", "C": "Transformadores" }
  ],
  "condicionadores_de_ar - refrigeracao": [
    { "A": 19, "B": "C", "C": "Refrigeração e ar-condicionado" }
  ],
  "sistema_de_gerenciamento_de_energia": [
    { "A": 13, "B": "B", "C": "Sistema de Gestão Predial (SGP) e controles de processo" },
    { "A": 14, "B": "B", "C": "Sistemas de Gestão de Energia (SGE)" }
  ],
  "bombas": [
    { "A": 24, "B": "C", "C": "Sistemas de bombeamento" }
  ],
  "cogeracao": [
    { "A": 15, "B": "B", "C": "Submedição e monitoramento de energia" }
  ],
  "ventiladores": [
    { "A": 20, "B": "C", "C": "Ventilação" }
  ]
};

export const tiposCargas = [
  { id: "iluminacao", label: "ILUMINAÇÃO" },
  { id: "condicionadores_de_ar", label: "CONDICIONADORES DE AR" },
  { id: "motores_eletricos", label: "MOTORES ELÉTRICOS" },
  { id: "bombas", label: "BOMBAS" },
  { id: "compressores", label: "COMPRESSORES" },
  { id: "ventiladores", label: "VENTILADORES" },
  { id: "inversores_de_frequencia", label: "INVERSORES DE FREQUÊNCIA" },
  { id: "refrigeracao", label: "REFRIGERAÇÃO" },
  { id: "sistema_de_gerenciamento_de_energia", label: "SISTEMA DE GERENCIAMENTO DE ENERGIA (SGE)" },
  { id: "cogeracao", label: "COGERAÇÃO" },
  { id: "isolamento_termico", label: "ISOLAMENTO TÉRMICO" },
  { id: "caldeiras", label: "CALDEIRAS" },
  { id: "queimadores", label: "QUEIMADORES" },
  { id: "fornos", label: "FORNOS" },
  { id: "aquecedores", label: "AQUECEDORES" },
  { id: "coletores_solares", label: "COLETORES SOLARES" },
  { id: "reservatorios_termicos", label: "RESERVATÓRIOS TÉRMICOS" },
  { id: "trocadores_de_calor", label: "TROCADORES DE CALOR" },
  { id: "bombas_de_calor", label: "BOMBAS DE CALOR" }
];

export const constituicaoEmpresaFornecedor = [
  {
    id: 1,
    label: "Microempreendedor Individual (MEI)"
  },
  {
    id: 2,
    label: "Ato Constitutivo - Sociedades Simples"
  },
  {
    id: 3,
    label: "Ato Constitutivo - Sociedades por Ações"
  },
];

export const estadosBrasil = [
  {
    id: "1",
    sigla: "AC",
    name: "Acre",
  },
  {
    id: "2",
    sigla: "AL",
    name: "Alagoas",
  },
  {
    id: "3",
    sigla: "AP",
    name: "Amapá",
  },
  {
    id: "4",
    sigla: "AM",
    name: "Amazonas",
  },
  {
    id: "5",
    sigla: "BA",
    name: "Bahia",
  },
  {
    id: "6",
    sigla: "CE",
    name: "Ceará",
  },
  {
    id: "7",
    sigla: "DF",
    name: "Distrito Federal",
  },
  {
    id: "8",
    sigla: "ES",
    name: "Espírito Santo",
  },
  {
    id: "9",
    sigla: "GO",
    name: "Goiás",
  },
  {
    id: "10",
    sigla: "MA",
    name: "Maranhão",
  },
  {
    id: "11",
    sigla: "MT",
    name: "Mato Grosso",
  },
  {
    id: "12",
    sigla: "MS",
    name: "Mato Grosso do Sul",
  },
  {
    id: "13",
    sigla: "MG",
    name: "Minas Gerais",
  },
  {
    id: "14",
    sigla: "PA",
    name: "Pará",
  },
  {
    id: "15",
    sigla: "PB",
    name: "Paraíba",
  },
  {
    id: "16",
    sigla: "PR",
    name: "Paraná",
  },
  {
    id: "17",
    sigla: "PE",
    name: "Pernambuco",
  },
  {
    id: "18",
    sigla: "PI",
    name: "Piauí",
  },
  {
    id: "19",
    sigla: "RJ",
    name: "Rio de Janeiro",
  },
  {
    id: "20",
    sigla: "RN",
    name: "Rio Grande do Norte",
  },
  {
    id: "21",
    sigla: "RS",
    name: "Rio Grande do Sul",
  },
  {
    id: "22",
    sigla: "RO",
    name: "Rondônia",
  },
  {
    id: "23",
    sigla: "RR",
    name: "Roraima",
  },
  {
    id: "24",
    sigla: "SC",
    name: "Santa Catarina",
  },
  {
    id: "25",
    sigla: "SP",
    name: "São Paulo",
  },
  {
    id: "26",
    sigla: "SE",
    name: "Sergipe",
  },
  {
    id: "27",
    sigla: "TO",
    name: "Tocantins",
  },
];

export const categoriasTecnologia = {
  A: "Intervenções Térmicas",
  B: "Controles e intervenções de gerenciamento de energia",
  C: "Intervenções elétricas"
};

export const scrumNotApply = [{
  id: true,
  name: "Sim"
}, {
  id: false,
  name: "Não"
},
];

export const listaCategoriasTecnologia = [{
  id: 1,
  categoria: "A",
  label: "Isolamento térmico"
}, {
  id: 2,
  categoria: "A",
  label: "Instalações e sistemas de caldeiras a vapor"
}, {
  id: 3,
  categoria: "A",
  label: "Economizadores de condensação e gases de combustão"
}, {
  id: 4,
  categoria: "A",
  label: "Recuperação de calor de ar"
}, {
  id: 5,
  categoria: "A",
  label: "Recuperação de calor por refrigeração para geração de água quente"
}, {
  id: 6,
  categoria: "A",
  label: "Desperdício de calor para eletricidade"
}, {
  id: 7,
  categoria: "A",
  label: "Aquecimento / Processo de calor em geral"
}, {
  id: 8,
  categoria: "A",
  label: "Solar térmico"
}, {
  id: 9,
  categoria: "A",
  label: "Bombas de calor (fonte de ar, fonte de solo e fonte de água)"
}, {
  id: 10,
  categoria: "A",
  label: "Caldeira de biomassa"
}, {
  id: 11,
  categoria: "A",
  label: "Calor e energia combinados (CHP) (convencional, célula de combustível ou biomassa, excluindo carvão)"
}, {
  id: 12,
  categoria: "A",
  label: "Elementos de construção e intervenções térmicas passivas"
}, {
  id: 13,
  categoria: "B",
  label: "Sistema de Gestão Predial (SGP) e controles de processo"
}, {
  id: 14,
  categoria: "B",
  label: "Sistemas de Gestão de Energia (SGE)"
}, {
  id: 15,
  categoria: "B",
  label: "Submedição e monitoramento de energia"
}, {
  id: 16,
  categoria: "B",
  label: "Consolidação da oferta"
}, {
  id: 17,
  categoria: "C",
  label: "Iluminação"
}, {
  id: 18,
  categoria: "C",
  label: "Motores e acionamentos"
}, {
  id: 19,
  categoria: "C",
  label: "Refrigeração e ar-condicionado"
}, {
  id: 20,
  categoria: "C",
  label: "Ventilação"
}, {
  id: 21,
  categoria: "C",
  label: "Ar comprimido"
}, {
  id: 22,
  categoria: "C",
  label: "Transformadores"
}, {
  id: 23,
  categoria: "C",
  label: "Redução / otimização de tensão"
}, {
  id: 24,
  categoria: "C",
  label: "Sistemas de bombeamento"
}];

export const opcoesBooleanas = [{
  id: 1,
  label: "SIM"
}, {
  id: 2,
  label: "NÃO"
}];

export const regioes = [{
  id: 1,
  label: "Polo 1: São Paulo e Baixada Santista",
}, {
  id: 2,
  label: "Polo 2: Campinas",
}, {
  id: 3,
  label: "Polo 3: Sorocaba, Itapeva e Registro",
}, {
  id: 4,
  label: "Polo 4 São José dos Campos",
}, {
  id: 5,
  label: "Polo 5: Central",
}, {
  id: 6,
  label: "Polo 6: Ribeirão Preto, Barretos e Franca",
}, {
  id: 7,
  label: "Polo 7: São José do Rio Preto e Araçatuba",
}, {
  id: 8,
  label: "Polo 8: Marília",
}, {
  id: 9,
  label: "Polo 9: Bauru",
}, {
  id: 10,
  label: "Polo 10: Presidente Prudente",
}, {
  id: 11,
  label: "Outro"
}];

export const listaEnergeticos = [{
  id: 1,
  label: "Energia elétrica",
}, {
  id: 2,
  label: "Lenha",
}, {
  id: 3,
  label: "Óleo combustível",
}, {
  id: 4,
  label: "Óleo diesel",
}, {
  id: 5,
  label: "Gás natural",
}, {
  id: 6,
  label: "GLP",
}, {
  id: 7,
  label: "Carvão vegetal",
}, {
  id: 8,
  label: "Etanol",
}, {
  id: 9,
  label: "Bagaço de cana",
}, {
  id: 10,
  label: "Liquor negro",
}, {
  id: 11,
  label: "Coque",
}];

export const subsegmentos = {
  // Têxtil e vestuário
  1: [{
    id: 1,
    label: "Fiação"
  }, {
    id: 2,
    label: "Tecelagem"
  }, {
    id: 3,
    label: "Malharia"
  }, {
    id: 4,
    label: "Beneficiamento"
  }, {
    id: 5,
    label: "Confecção"
  }],
  // Cerâmica, vidro e produtos não metálicos
  2: [{
    id: 6,
    label: "Produção de cimento"
  }, {
    id: 7,
    label: "Produção de cerâmica branca"
  }, {
    id: 8,
    label: "Produção de cerâmica vermelha"
  }, {
    id: 9,
    label: "Produção de vidro"
  }, {
    id: 69,
    label: "Produção de artefatos de concreto, cimento, fibrocimento, gesso e materiais semelhantes"
  }, {
    id: 70,
    label: "Aparelhamento de pedras e fabricação de outros produtos minerais não-metálicos."
  }],
  // Metalurgia e produtos metálicos
  3: [{
    id: 10,
    label: "Produção de esquadrias de metal"
  }, {
    id: 11,
    label: "Fundição de ferro e aço"
  }, {
    id: 12,
    label: "Produção de embalagens metálicas"
  }, {
    id: 13,
    label: "Produção de artefatos estampados de metal"
  }, {
    id: 14,
    label: "Fundição de metais não ferrosos e suas ligas"
  }, {
    id: 15,
    label: "Produção de outros produtos de metal não especificados anteriormente"
  }, {
    id: 16,
    label: "Produção de obras de caldeiraria pesada"
  }, {
    id: 17,
    label: "Produção de estruturas metálicas"
  }, {
    id: 18,
    label: "Produção de artigos de metal para uso doméstico e pessoal"
  }, {
    id: 19,
    label: "Produção de ferramentas"
  }, {
    id: 20,
    label: "Serviço de usinagem, torneiria e solda"
  }, {
    id: 21,
    label: "Produção de produtos de trefilados de metal, exceto padronizados"
  }, {
    id: 22,
    label: "Produção de artigos de serralheria, exceto esquadrias"
  }, {
    id: 23,
    label: "Produção de tanques, reservatórios metálicos e caldeiras para aquecimento central"
  }, {
    id: 24,
    label: "Serviços de tratamento e revestimento em metais"
  }],
  // Transformados plásticos
  4: [{
    id: 25,
    label: "Produção de artefatos de material plástico para uso pessoal e doméstico"
  }, {
    id: 26,
    label: "Produção de artefatos de material plástico para usos industriais"
  }, {
    id: 27,
    label: "Produção de artefatos de material plástico para outros usos não especificados anteriormente"
  }, {
    id: 28,
    label: "Produção de embalagens de material plástico"
  }, {
    id: 29,
    label: "Produção de artefatos de material plástico para uso na construção, exceto tubos e acessórios"
  }, {
    id: 30,
    label: "Reforma de pneumáticos usados"
  }, {
    id: 31,
    label: "Produção de tubos e acessórios de material plástico para uso na construção"
  }, {
    id: 32,
    label: "Produção de laminados planos e tubulares de material plástico"
  }, {
    id: 33,
    label: "Produção de artefatos de borracha não especificados  anteriormente"
  }],
  // Alimentos e bebidas
  5: [{
    id: 34,
    label: "Bebidas"
  }, {
    id: 35,
    label: "Carnes (abate)"
  }, {
    id: 36,
    label: "Carne (industrialização)"
  }, {
    id: 37,
    label: "Leite (pasteurizado e UHT) e laticínios"
  }, {
    id: 38,
    label: "Rações e alimentos para animais"
  }, {
    id: 39,
    label: "Cacau, chocolate e doces"
  }, {
    id: 40,
    label: "Soja"
  }, {
    id: 41,
    label: "Óleo vegetal (refino)"
  }, {
    id: 42,
    label: "Trigo"
  }, {
    id: 43,
    label: "Arroz"
  }, {
    id: 44,
    label: "Milho"
  }, {
    id: 45,
    label: "Café"
  }, {
    id: 46,
    label: "Pescados"
  }, {
    id: 47,
    label: "Suco de laranja"
  }, {
    id: 66,
    label: "Produção de conservas de frutas, legumes e outros vegetais"
  }, {
    id: 67,
    label: "Produção e refino de açúcar"
  }],
  // Papel e celulose
  6: [{
    id: 48,
    label: "Produção de papel"
  }, {
    id: 49,
    label: "Produção de celulose"
  }, {
    id: 50,
    label: "Produção de papel reciclado"
  }, {
    id: 68,
    label: "Produção de embalagens de papel, cartolina, papel-cartão, e papelão ondulado"
  }],
  // Química e farmacêutica
  7: [{
    id: 51,
    label: "Sabões, detergentes, produtos de limpeza, cosméticos, de perfumaria e higiene pessoal"
  }, {
    id: 52,
    label: "Produtos químicos orgânicos"
  }, {
    id: 53,
    label: "Resinas e elastômetros"
  }, {
    id: 54,
    label: "Fibras artificiais e sintéticas"
  }, {
    id: 55,
    label: "Defensivos agrícolas e desinfetantes domissanitários"
  }, {
    id: 56,
    label: "Produtos químicos inorgânicos"
  }, {
    id: 57,
    label: "Tintas, vernizes, esmalte, lacas e produtos afins"
  }, {
    id: 58,
    label: "Produtos e preparados químicos diversos"
  }, {
    id: 59,
    label: "Produtos farmoquímicos"
  }, {
    id: 60,
    label: "Produtos farmacêuticos"
  }],
  // Automóveis e peças
  8: [{
    id: 61,
    label: "Produção de automóveis, caminhonetas e utilitários"
  }, {
    id: 62,
    label: "Produção de caminões e ônibus"
  }, {
    id: 63,
    label: "Produção de cabines, carrocerias e reboques para veículos automotores"
  }, {
    id: 64,
    label: "Produção de peças e acessórios para veículos automotores"
  }, {
    id: 65,
    label: "Recondicionamento e recuperaç~ao para veículos automotores"
  }],
  // Outros
  9: [{
    id: 66,
    label: "Outros"
  }],
};

export const listaProcessos = [{
  id: 1,
  label: "Linha de Produção"
}, {
  id: 2,
  label: "Processos Auxiliares"
}];

export const sistemas = {
  1: [{
    id: 1,
    label: "Sistemas Térmicos - Caldeiras"
  }, {
    id: 10,
    label: "Sistemas Térmicos - Trocadores de calor"
  }, {
    id: 11,
    label: "Sistemas Térmicos - Bombas de calor"
  }, {
    id: 12,
    label: "Sistemas Térmicos - Aquecedores"
  }, {
    id: 13,
    label: "Sistemas Térmicos - Fornos e Fornalhas"
  }, {
    id: 14,
    label: "Sistemas Térmicos - Radiação térmica"
  }, {
    id: 15,
    label: "Sistemas Térmicos - Sistemas motrizes estacionários a combustão"
  }, {
    id: 16,
    label: "Sistemas Térmicos - Motores de veículos de transporte"
  }, {
    id: 17,
    label: "Sistemas Térmicos - Tratores"
  }, {
    id: 2,
    label: "Sistemas Motrizes",
  }, {
    id: 3,
    label: "Sistemas de Distribuição"
  }, {
    id: 7,
    label: "Processos Eletroquímicos"
  }, {
    id: 8,
    label: "Sistemas de Refrigeração"
  }],
  2: [{
    id: 4,
    label: "Sistemas de Ar Comprimido",
  }, {
    id: 5,
    label: "Sistemas de Iluminação",
  }, {
    id: 6,
    label: "Sistemas de Condicionamento Ambiental"
  }, {
    id: 9,
    label: "Sistemas de Bombeamento"
  }]
};

export const listaComoSoubePrograma = [{
  id: 1,
  label: "Redes sociais"
}, {
  id: 2,
  label: "Associação Setorial"
}, {
  id: 3,
  label: "Contato direto de ESCO"
}, {
  id: 4,
  label: "Contato direto de Fornecedor de Tecnologia"
}, {
  id: 5,
  label: "Anúncios"
}, {
  id: 6,
  label: "Sindicato"
}, {
  id: 7,
  label: "Imprensa"
}, {
  id: 8,
  label: "Palestras"
}, {
  id: 9,
  label: "Contato direto do SENAI"
}, {
  id: 10,
  label: "E-mail marketing"
}, {
  id: 11,
  label: "Outros"
}];

export const operaFeriados = [
  {
    id: 1,
    label: "Sim"
  },
  {
    id: 2,
    label: "Não"
  }
]

export const selecionarPMEVaiContratarDiagnostico = [
  {
    id: 0,
    label: "Selecione ... "
  },
  {
    id: 1,
    label: "A PME contratou o Diagnóstico"
  },
  {
    id: 2,
    label: "A PME não contratou o Diagnóstico"
  },
]

export const motivosPMENaoContratarDiagnostico = [
  {
    id: 1,
    label: "Não tem potencial técnico para o programa em questão "
  },
  {
    id: 2,
    label: "A PME não demonstrou interesse em seguir (questões financeiras)"
  },
  {
    id: 3,
    label: "A PME não demonstrou interesse em seguir (falta de tempo)"
  },
  {
    id: 4,
    label: "A PME não demonstrou interesse em seguir (baixa atratividade das medidas propostas)"
  },
  {
    id: 5,
    label: "A PME não demonstrou interesse em seguir (falta de equipe para acompanhar o projeto)"
  },
]

export const textoExAnte = "Esta fase refere-se ao desenvolvimento do diagnóstico energético, onde as economias de energia são estimadas a partir de cálculos de engenharia para avaliação da energia consumida no sistema atual e para a energia que será consumida a partir do sistema proposto";

export const textoExPost = "Esta fase refere-se à execução do projeto, onde as economias de energia são estimadas a partir de medições em campo da energia consumida no sistema atual, antes da substituição, e das medições em capo da energia consumida pelo novo sistema mais eficiente, após substituição e execução da obra.";


export const tagsRel = {
  headers: [
    { key: "titulo", label: "Título" },
    { key: "ref_1_1", label: "Número de diagnósticos energéticos iniciados" },
    { key: "ref_1_2", label: "Número de diagnósticos energéticos concluídos" },
    // { key: "ref_1_3", label: "Valor agregado do subsídio para auditorias energéticas em EUR"},
    { key: "ref_1_4", label: "Número de projetos de EE submetidos ao GCI" },
    { key: "ref_1_5", label: "Número de projetos de EE com suporte à implementação" },
    // { key: ref_1_6", label: "Montante de subsídio agregado para apoio à implementação em EUR"},
    { key: "ref_1_7", label: "Número de PMEs com suporte à implementação concluído" },
    // { key: ref_1_8", label: "Custo agregado de auditoria energética incorrido por PMEs em reais"},
    // { key: ref_1_9", label: "Custo agregado de suporte de implementação incorrido por PMEs em reais"},
    // { key: ref_1_10", label: "Custo médio das auditorias energéticas em reais"},
    // { key: ref_1_11", label: "Custo médio de suporte de implementação em BRL"},
    { key: "ref_1_12", label: "Tamanho médio do tícket de projetos de EE em EUR" },
    { key: "ref_1_13", label: "Custos de investimento agregados esperados em EUR" },
    { key: "ref_1_14", label: "Valor esperado de redução de emissões de gases de efeito estufa (tCO2 cumulativo-vida útil)" },
    { key: "ref_1_15", label: "Valor esperado de economia com EE (KWh cumulativo-vida útil)" },
    { key: "ref_1_16", label: "Número agregado de funcionários do sexo feminino beneficiadas por diagnósticos energéticos" },
    { key: "ref_1_17", label: "Número agregado de funcionários do sexo masculino beneficiados por diagnósticos energéticos" },
    { key: "ref_1_18", label: "Número agregado de funcionários do sexo feminino beneficiadas pelo suporte à implementação" },
    { key: "ref_1_19", label: "Número agregado de funcionários do sexo masculino beneficiados pelo suporte à implementação" },

    { key: "ref_2_1", label: "Descrição do projeto de EE" },
    { key: "ref_2_2", label: "Diagnóstico energético iniciado" },
    // { key: ref_2_3", label: "Montante de subsídio para auditorias energéticas"},
    // { key: ref_2_4", label: "Custo da auditoria energética para PME em reais"},
    { key: "ref_2_5", label: "Diagnóstico energético concluído" },
    { key: "ref_2_6", label: "Projeto encaminhado ao GCI" },
    { key: "ref_2_7", label: "Projeto de EE com suporte de implementação" },
    // { key: ref_2_8", label: "Montante de subsídio para apoio à implementação"},
    // { key: ref_2_9", label: "Custos de suporte de implementação para SME em BRL"},
    { key: "ref_2_10", label: "Suporte de implementação concluído" },
    { key: "ref_2_11", label: "Categorias tecnológicas utilizadas" },
    { key: "ref_2_12", label: "É necessária uma avaliação de impacto ambiental?" },
    { key: "ref_2_13", label: "Custo total de investimento em EE esperado em EUR" },
    // { key: ref_2_14", label: "Taxa de câmbio relevante"},
    { key: "ref_2_15", label: "Localização geográfica" },
    { key: "ref_2_16", label: "Período de implementação planejado" },
    { key: "ref_2_17", label: "Estimativa de data de conclusão" },
    { key: "ref_2_18", label: "Empregos criados no período de implementação do projeto de EE" },
    { key: "ref_2_19", label: "Valor de investimento de fontes privadas" },

    { key: "ref_3_1", label: "Investimento Elegível para EE de acordo com o programa PotencializEE?" },
    { key: "ref_3_2", label: "Valor esperado de economia com EE (KWh cumulativo-vida útil)" },
    { key: "ref_3_3", label: "Porcentagem esperada de economia com EE (%)" },
    { key: "ref_3_4", label: "Valor esperado de redução de emissões de gases de efeito estufa (t de CO2 cumulativo-vida útil)" },
    { key: "ref_3_5", label: "Porcentagem esperada de redução de emissões de gases de efeito estufa (%)" },
    { key: "ref_3_6", label: "VPL da economia esperada de EE em relação à vida útil do investimento em EE (R$)" },
    { key: "ref_3_7", label: "50% do custo de investimento em EE coberto pelo VPL das economias de EE" },
    { key: "ref_3_8", label: "Investimento em EE concluído" },
    { key: "ref_3_9", label: "Custo total do investimento em EE (R$)" },
    { key: "ref_3_10", label: "Valor verificado de economia com EE (KWh cumulativo-vida útil)" },
    { key: "ref_3_11", label: "Porcentagem de economia com EE  verificado (%)" },
    { key: "ref_3_12", label: "Valor de redução de emissões de gases de efeituo estufa verificado (tde CO2 cumulativo-vida útil)" },
    { key: "ref_3_13", label: "Porcentagem de redução de emissões de gases de efeito estufa verificado (%)" },

    { key: "ref_4_1", label: "Nome civil do Beneficiário Final" },
    { key: "ref_4_2", label: "CNPJ do Beneficiário Final" },
    { key: "ref_4_3", label: "Localização geográfica" },
    { key: "ref_4_4", label: "Categoria do Beneficiário Final" },
    { key: "ref_4_5", label: "Cidade" },
    { key: "ref_4_6", label: "Código postal" },
    { key: "ref_4_7", label: "Endereço" },
    { key: "ref_4_9", label: "Número de funcionários total da PME" },
    { key: "ref_4_10", label: "Segmento industrial" },
    { key: "ref_4_11", label: "Número de funcionárias beneficiadas por diagnósticos energéticos" },
    { key: "ref_4_12", label: "Número de funcionários do sexo masculino beneficiados de diagnósticos energéticos" },
    { key: "ref_4_13", label: "Número agregado de funcionários do sexo feminino beneficiadas pelo suporte à implementação" },
    { key: "ref_4_14", label: "Número agregado de funcionários do sexo masculino beneficiadas pelo suporte à implementação" },
  ],

  subHeaders: {
    titulo: "Referência",
    ref_1_1: "1.1",
    ref_1_2: "1.2",
    // ref_1_3: "1.3",
    ref_1_4: "1.4",
    ref_1_5: "1.5",
    // ref_1_6: "1.6",
    ref_1_7: "1.7",
    // ref_1_8: "1.8",
    // ref_1_9: "1.9",
    // ref_1_10: "1.10",
    // ref_1_11: "1.11",
    ref_1_12: "1.12",
    ref_1_13: "1.13",
    ref_1_14: "1.14",
    ref_1_15: "1.15",
    ref_1_16: "1.16",
    ref_1_17: "1.17",
    ref_1_18: "1.18",
    ref_1_19: "1.19",

    ref_2_1: "2.1",
    ref_2_2: "2.2",
    // ref_2_3: "2.3",
    // ref_2_4: "2.4",
    ref_2_5: "2.5",
    ref_2_6: "2.6",
    ref_2_7: "2.7",
    // ref_2_8: "2.8",
    // ref_2_9: "2.9",
    ref_2_10: "2.10",
    ref_2_11: "2.11",
    ref_2_12: "2.12",
    ref_2_13: "2.13",
    // ref_2_14: "2.14",
    ref_2_15: "2.15",
    ref_2_16: "2.16",
    ref_2_17: "2.17",
    ref_2_18: "2.18",
    ref_2_19: "2.19",

    ref_3_1: "3.1",
    ref_3_2: "3.2",
    ref_3_3: "3.3",
    ref_3_4: "3.4",
    ref_3_5: "3.5",
    ref_3_6: "3.6",
    ref_3_7: "3.7",
    ref_3_8: "3.8",
    ref_3_9: "3.9",
    ref_3_10: "3.10",
    ref_3_11: "3.11",
    ref_3_12: "3.12",
    ref_3_13: "3.13",

    ref_4_1: "4.1",
    ref_4_2: "4.2",
    ref_4_3: "4.3",
    ref_4_4: "4.4",
    ref_4_5: "4.5",
    ref_4_6: "4.6",
    ref_4_7: "4.7",
    ref_4_8: "4.8",
    ref_4_9: "4.9",
    ref_4_10: "4.10",
    ref_4_11: "4.11",
    ref_4_12: "4.12",
    ref_4_13: "4.13",
    ref_4_14: "4.14"
  }
};

export const dialogoTomadaPreco = [
  {
    id: 1,
    name: "iluminacao",
    descricao: "ILUMINAÇÃO",
    infodefault: [
      { ordem: 1, detalhe: "Fator de potência igual ou superior a 0,92" },
      { ordem: 2, detalhe: "Alta eficiência luminosa, com eficiência mínima de 80 lm/W" },
      { ordem: 3, detalhe: "Temperatura de cor, preferencialmente, de 4000K" },
      { ordem: 4, detalhe: "Garantia de, no mínimo, 3 anos" },
      { ordem: 5, detalhe: "De preferência, equipamentos que apresentem selo Procel ou certificado do INMETRO nível A" },
    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },
    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },
    ]
  },
  {
    id: 2,
    name: "ar-condicionado",
    descricao: "AR-CONDICIONADO",
    infodefault: [
      { ordem: 1, detalhe: "Fluido Refrigerante R-32 ou fluido menos poluente" },
      { ordem: 2, detalhe: "Tecnologia Inverter" },
      { ordem: 3, detalhe: "Selo Procel Ouro" },
      { ordem: 4, detalhe: "Classificação INMETRO A" },
      { ordem: 5, detalhe: "Compressor com Motor Neodymium" },
      { ordem: 6, detalhe: "IDRS >= 8,3" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 3,
    name: "motores-eletricos",
    descricao: "MOTORES ELÉTRICOS",
    infodefault: [
      { ordem: 1, detalhe: "Garantia de, no mínimo, 2 anos" },
      { ordem: 2, detalhe: "De preferência, equipamentos que apresentem índice de rendimento mínimo Premium (IR3) ou selo Procel" },
    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },

    ]
  },
  {
    id: 4,
    name: "bombas",
    descricao: "BOMBAS",
    infodefault: [
      { ordem: 1, detalhe: "Vazão no ponto de maior eficiência entre 2 e 1000 m³/h" },
      { ordem: 2, detalhe: "Rotação específica entre 6 e 80 min^-1" },
      { ordem: 3, detalhe: "Garantia de, no mínimo, 2 anos" },
      { ordem: 4, detalhe: "De preferência, equipamentos que apresentem selo Procel ou certificado do INMETRO nível A" },
      { ordem: 5, detalhe: "Caso adquira a bomba com o motor, considerar os critérios e ficha de eficiência do motor" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 5,
    name: "compressores",
    descricao: "COMPRESSORES",
    infodefault: [
      { ordem: 1, detalhe: "Baixo nível de ruído em condições normais de operação" },
      { ordem: 2, detalhe: "Limiar do COP igual ou superior a 4.0 (para temperaturas acima de 0°C)" },
      { ordem: 3, detalhe: "Capacidade de manutenção de pressão estável, com variação mínima de 5% da pressão nominal" },
      { ordem: 4, detalhe: "Vida útil estimada de, no mínimo, 10 anos" },
      { ordem: 5, detalhe: "Garantia de, no mínimo, 3 anos" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 6,
    name: "ventiladores",
    descricao: "VENTILADORES",
    infodefault: [
      { ordem: 1, detalhe: "Selo Procel" },
      { ordem: 2, detalhe: "Classificação Inmetro A" },
      { ordem: 3, detalhe: "Acoplamento Reforçado" },
      { ordem: 4, detalhe: "Acompanha Chave de Controle de Velocidade ou Inversor de Frequência" },
      { ordem: 5, detalhe: "Oscilação Horizontal" },
      { ordem: 6, detalhe: "Caso adquira o ventilador com o motor, considerar os critérios e ficha de eficiência do motor" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 7,
    name: "inversores",
    descricao: "INVERSORES DE FREQUÊNCIA",
    infodefault: [
      { ordem: 1, detalhe: "Proteção de subtensão" },
      { ordem: 2, detalhe: "Proteção de sobretensão" },
      { ordem: 3, detalhe: "Proteção de sobrecarga e aquecimento" },
      { ordem: 4, detalhe: "Proteção de sobrecorrente" },
      { ordem: 5, detalhe: "Função religamento automático" },
    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 8,
    name: "refrigeracao",
    descricao: "REFRIGERAÇÃO",
    infodefault: [
      { ordem: 1, detalhe: "Motores do ventilador do chiller ou torre de resfriamento com alto índice de eficiência" },
      { ordem: 2, detalhe: "Fluidos refrigerantes com o menor GWP possível" },
      { ordem: 3, detalhe: "Garantia de, no mínimo, 3 anos" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 9,
    name: "sge",
    descricao: "SGE",
    infodefault: [
      { ordem: 1, detalhe: "Relatórios diário/semanal/mensal/anual" },
      { ordem: 2, detalhe: "Gráficos e relatórios do consumo unitário, em kWh" },
      { ordem: 3, detalhe: "Acompanhamento de meta de consumo" },
      { ordem: 4, detalhe: "Simulações de perfis energéticos (inclusão ou retitada de cargas indutiva/capacitiva)" },
      { ordem: 5, detalhe: "Rateio inteligente entre centro de custos" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 10,
    name: "cogeracao",
    descricao: "COGERAÇÃO",
    infodefault: [

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 11,
    name: "isolamento-termico",
    descricao: "ISOLAMENTO TÉRMICO",
    infodefault: [
      { ordem: 1, detalhe: "Densidade do isolamento" },
      { ordem: 2, detalhe: "Curva característica do material" },
      { ordem: 3, detalhe: "Temperatura máxima de operação" },
      { ordem: 4, detalhe: "Composição química" },
      { ordem: 5, detalhe: "Preferência por materiais sustentáveis e com menor impacto ambiental" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 12,
    name: "caldeiras queimador",
    descricao: "CALDEIRAS QUEIMADOR",
    infodefault: [
      { ordem: 1, detalhe: "Possui economizador ou pré-aquecedor de ar?" },
      { ordem: 2, detalhe: "Indicar vida útil (ano)" },
      { ordem: 3, detalhe: "Nível de emissões (kg CO2/kWh; kg CO2/kcal)" },
      { ordem: 4, detalhe: "Normas nacionais" },
      { ordem: 5, detalhe: "Normas internacionais" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },
    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },
    ]
  },
  {
    id: 13,
    name: "queimadores",
    descricao: "QUEIMADORES",
    infodefault: [
      { ordem: 1, detalhe: "Possui oxi-combustão?" },
      { ordem: 2, detalhe: "Indicar vida útil (ano)" },
      { ordem: 3, detalhe: "Emissões de Nox (mg/Nm3)" },
      { ordem: 4, detalhe: "Possui pré-aquecedor?" },
      { ordem: 5, detalhe: "Possui recirculação de gases?" },
      { ordem: 6, detalhe: "Normas nacionais" },
      { ordem: 7, detalhe: "Normas internacionais" },
      { ordem: 8, detalhe: "Eficiência energética (%)" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },

    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },

    ]
  },
  {
    id: 14,
    name: "forno",
    descricao: "FORNOS",
    infodefault: [
      { ordem: 1, detalhe: "Indicar vida útil (ano)" },
      { ordem: 2, detalhe: "Capacidade de produção com 50% da carga (kg; t; kg/h; t/h)" },
      { ordem: 3, detalhe: "Normas nacionais" },
      { ordem: 4, detalhe: "Normas internacionais" },
      { ordem: 5, detalhe: "Temperatura de trabalho de máxima eficiência" },
      { ordem: 6, detalhe: "Carga de trabalho de máxima eficiência" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },
    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },
    ]
  },
  {
    id: 15,
    name: "aquecedor",
    descricao: "AQUECEDORES",
    infodefault: [
      { ordem: 1, detalhe: "Indicar vida útil (ano)" },
      { ordem: 2, detalhe: "Normas nacionais" },
      { ordem: 3, detalhe: "Normas internacionais" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },
    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },
    ]
  },
  {
    id: 16,
    name: "coletor-solar",
    descricao: "COLETORES SOLARES",
    infodefault: [
      { ordem: 1, detalhe: "Normas nacionais" },
      { ordem: 2, detalhe: "Normas internacionais" },
      { ordem: 3, detalhe: "Vida útil" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },
    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },
    ]
  },
  {
    id: 17,
    name: "reservatorio-termico",
    descricao: "RESERVATÓRIOS TERMICOS",
    infodefault: [
      { ordem: 1, detalhe: "Normas nacionais" },
      { ordem: 2, detalhe: "Normas internacionais" },
      { ordem: 3, detalhe: "Vida útil" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },
    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },
    ]
  },
  {
    id: 18,
    name: "trocador-calor",
    descricao: "TROCADORES DE CALOR",
    infodefault: [
      { ordem: 1, detalhe: "Normas nacionais" },
      { ordem: 2, detalhe: "Normas internacionais" },
      { ordem: 3, detalhe: "Vida útil" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },
    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },
    ]
  },
  {
    id: 19,
    name: "bombas-calor",
    descricao: "BOMBAS DE CALOR",
    infodefault: [
      { ordem: 1, detalhe: "Fluido Refrigerante com menor GWP" },
      { ordem: 2, detalhe: "Tecnologia com variação de capacidade" },
      { ordem: 3, detalhe: "Vazão do fluido lado quente" },
      { ordem: 4, detalhe: "Vazão de fluido lado frio" },
      { ordem: 5, detalhe: "COP > 3" },

    ],
    infotomada: [
      { ordem: 1, detalhe: "Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada" },
      { ordem: 2, detalhe: "Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente" },
      { ordem: 3, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via link do sistema encaminhado por e-mail" },
      { ordem: 4, detalhe: "ATENÇÃO: O prazo para envio do equipamento será mantido na proposta de orçamento da 2ª rodada, caso o seu orçamento seja selecionado para esta etapa. Esse critério poderá ser utilizado para a seleção do fornecedor e não poderá ser alterado." },
    ],
    infoproposta: [
      { ordem: 1, detalhe: "Indicar vida útil de cada equipamento" },
      { ordem: 2, detalhe: "Indicar a validade da proposta de orçamento (por exemplo, 30 dias, 60 dias, etc) - será puxada automaticamente do orçamento da 1ª rodada" },
      { ordem: 3, detalhe: "Informar o prazo para envio dos equipamentos " },
      { ordem: 4, detalhe: "Indicar o valor unitário de cada equipamento e o valor total de cada equipamento" },
      { ordem: 5, detalhe: "A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto" },
      { ordem: 6, detalhe: "Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor." },
      { ordem: 7, detalhe: "A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)" },
      { ordem: 8, detalhe: "Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)" },
      { ordem: 9, detalhe: "No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)" },
    ]
  },
];

export const tituloTabelaAddTomadaPorCarga = {
  iluminacao: {
    coluna1: "Tipo de lâmpada",
    coluna2: "Formato da lâmpada",
    coluna3: "Potência (w)",
    coluna4: "Luminosidade (lumens)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  condicionadores_de_ar: {
    coluna1: "Tipo de ar condicionado",
    coluna2: "Capacidade de Refrigeração",
    coluna3: "Corrente (A)",
    coluna4: "Tensão de alimentação (v)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  motores_eletricos: {
    coluna1: "Potência Nominal (kW)",
    coluna2: "Número de Pólos",
    coluna3: "Velocidade Síncrona (rpm)",
    coluna4: "Rendimento Nominal (%)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  bombas: {
    coluna1: "Tipo de bomba",
    coluna2: "Potência (HP)",
    coluna3: "Vazão desejada (m³h)",
    coluna4: "Rotação por pressão (psi)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  compressores: {
    coluna1: "Tipo de compressor",
    coluna2: "Potência (HP)",
    coluna3: "Pressão máxima (PSI)",
    coluna4: "Vazão fornecida(l/min)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  ventiladores: {
    coluna1: "Tipo de Ventilador",
    coluna2: "Potência do motor (W)",
    coluna3: "Vazão média de ar em alta velocidade(m³/s)",
    coluna4: "Tensão de alimentação(V)",
    coluna5: "Número de Pás",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  inversores_de_frequencia: {
    coluna1: "Tipo de inversor de frequência",
    coluna2: "Potência do motor que o inversor será instalado (kW)",
    coluna3: "Corrente do motor que o inversor será instalado (A)",
    coluna4: "Tensão de alimentação (V)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  refrigeracao: {
    coluna1: "Equipamento de refrigeração",
    coluna2: "Capacidade de refrigeração (TR)",
    coluna3: "COP/EER mínimo",
    coluna4: "GWP máximo do Fluido refrigerante",
    coluna5: "Vazão de água (m³/h)",
    coluna6: "Temperatura da água resfriada (°C)",
    coluna7: "Possui inversor de frequência [S/N]",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  sistema_de_gerenciamento_de_energia: {
    coluna1: "Tipos de monitoramento e controle",
    coluna2: "Tipo de Aplicação",
    coluna3: "",
    coluna4: "",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  cogeracao: {
    coluna1: "Planta de Cogeração",
    coluna2: "Combustíveis",
    coluna3: "Sistema de Armazenamento de Combústiveis",
    coluna4: "Sistema de Desmin. Água",
    coluna5: "Sistema de Transporte",
    coluna6: "Sistema de Automação",
    coluna7: "Sistema de Instrumentação",
    coluna8: "Sistema de Monitoramento de Co²",
    coluna9: "Sistema de Monitoramento CFTV",
    coluna10: "Sistema de Intercomunicação",
    coluna11: "Componentes Mecânicos",
    coluna12: "Componentes Elétricos",
    coluna13: "Sistema de Distribuição de Vapor do Processo",
    coluna14: "Quantidade",

  },
  isolamento_termico: {
    coluna1: "Material do isolamento térmico",
    coluna2: "Temperatura da superfície a ser isolada (°C)",
    coluna3: "Condutividade térmica do isolamento (W/m.K)",
    coluna4: "Espessura do isolamento térmico (mm)",
    coluna5: "Área a ser isolada (m²)",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",
  },
  caldeiras: {
    coluna1: "Tecnologia (flamotubular; aquatubular; mista)",
    coluna2: "Eficiência energética (%)",
    coluna3: "Capacidade de aquecimento (kW; kcal)",
    coluna4: "Capacidade de geração de vapor (t/h)",
    coluna5: "Pressão máxima e de operação (kgf/cm2; bar; psi; Pa)",
    coluna6: "Temperatura máxima de vapor gerado (ºC)",
    coluna7: "Tipo de energético (biomassa, eletricidade, GLP, GN, óleo)",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  queimadores: {
    coluna1: "Capacidade de aquecimento (kW; kcal)",
    coluna2: "Tipo de combustível",
    coluna3: "Excesso de ar (%)",
    coluna4: "Tipo de modulação",
    coluna5: "Taxa de turndown",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  fornos: {
    coluna1: "Tipo de aplicação industrial",
    coluna2: "Tipo de aquecimento (direto; indireto)",
    coluna3: "Capacidade de aquecimento (kW; kcal)",
    coluna4: "Capacidade de produção a plena carga (kg; t; kg/h; t/h)",
    coluna5: "Máxima temperatura interna (ºC)",
    coluna6: "Temperatura de saída dos gases (ºC) (exceto para equipamentos elétricos)",
    coluna7: "Eficiência energética (%)",
    coluna8: "Tipo de energético (biomassa, eletricidade, GLP, GN, óleo)",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",

  },
  aquecedores: {
    coluna1: "Tipo de aplicação industrial",
    coluna2: "Tipo de aquecimento (direto; indireto)",
    coluna3: "Tipo de tecnologia",
    coluna4: "Capacidade de aquecimento (kW; kcal)",
    coluna5: "Pressão máxima de trabalho do fluido (kgf/cm2; psi; bar; Pa)",
    coluna6: "Máxima temperatura de saída do fluido térmico (ºC)",
    coluna7: "Eficiência energética para a máxima capacidade (%)",
    coluna8: "Tipo de energético (biomassa, eletricidade, GLP, GN, óleo)",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade",
  },
  coletores_solares: {
    coluna1: "Tecnologia (com vidro, não isolado, a vácuo)",
    coluna2: "Temperatura de operação (ºC)",
    coluna3: "Temperatura de alimentação (ºC)",
    coluna4: "Vazão diária de água",
    coluna5: "Pressão do sistema (mca ou bar - indicar a unidade)",
    coluna6: "Número de horas de operação por dia",
    coluna7: "Latitude da instalação",
    coluna8: "Longitude da Instalação",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade de coletores",

  },
  reservatorios_termicos: {
    coluna1: "Volume do reservatório",
    coluna2: "Temperatura de operação (ºC)",
    coluna3: "Fluido armazenado",
    coluna4: "Material do corpo interno",
    coluna5: "Tipo de apoio",
    coluna6: "Potência mínima da resistência de apoio (quando usada)",
    coluna7: "Pressão do vapor para a serpentina de apoio (quando usada)",
    coluna8: "Pressão de operação (mca ou bar - indicar a unidade)",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade de reservatórios",

  },
  trocadores_de_calor: {
    coluna1: "Tipo de trocador",
    coluna2: "Capacidade (kW)",
    coluna3: "Função",
    coluna4: "Descrição do fluido 1  - de alimentação (de onde será extraído o calor ou frio)",
    coluna5: "Descrião do fluido 2 - de recuperação (para onde vai o calor ou o frio recuperados)",
    coluna6: "Temperatura de entrada - fluido 1 (de alimentação) ºC",
    coluna7: "Temperatura de entrada - fluido 2 (de recuperação) ºC",
    coluna8: "Temperatura de saída desejada - fluido 2 (de recuperação) ºC",
    coluna9: "Vazão do fluido 1 (de alimentação) kg/h",
    coluna10: "Vazão do fluido 2 (de recuperação) kg/h",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade de trocadores",

  },
  bombas_de_calor: {
    coluna1: "Capacidade de aquecimento necessária (kW)",
    coluna2: "Temperatura de entrada da água",
    coluna3: "CONDENSADOR (LADO QUENTE) Temperatura de saída da água",
    coluna4: "CONDENSADOR (LADO QUENTE) Temperatura de entrada da água",
    coluna5: "EVAPORADOR (LADO FRIO) Temperatura de saída da água",
    coluna6: "EVAPORADOR (LADO FRIO) Tensão de alimentação (V)",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    coluna14: "Quantidade de bombas de calor",

  }
};

export const opcoesTabelaAddTomadaPorCarga = {
  iluminacao: {
    coluna1: [
      { id: "Fluorescente", label: "Fluorescente" },
      { id: "Incandescente", label: "Incandescente" },
      { id: "Vapor Metálico", label: "Vapor Metálico" },
      { id: "Halógena", label: "Halógena" },
      { id: "LED", label: "LED" },
    ],
  },
  condicionadores_de_ar: {
    coluna1: [
      { id: "Split", label: "Split" },
      { id: "Janela", label: "Janela" },
      { id: "Splitão", label: "Splitão" },
      { id: "Self-contained", label: "Self-contained" },
      { id: "Fancoil", label: "Fancoil" },
      { id: "Fancolete", label: "Fancolete" },
    ]
  },
  motores_eletricos: {
    coluna1: [
      { id: "0,12", label: "0,12" },
      { id: "0,18", label: "0,18" },
      { id: "0,25", label: "0,25" },
      { id: "0,37", label: "0,37" },
    ]
  },
  bombas: {
    coluna1: [
      { id: "Centrífuga", label: "Centrífuga" },
      { id: "Deslocamento Positivo", label: "Deslocamento Positivo" },
      { id: "Diafragma", label: "Diafragma" },
      { id: "Engrenagem", label: "Engrenagem" },
      { id: "Parafuso", label: "Parafuso" },
      { id: "Pistão", label: "Pistão" },
    ]
  },
  compressores: {
    coluna1: [
      { id: "Alternativo semi-hermético", label: "Alternativo semi-hermético" },
      { id: "Alternativo hermético", label: "Alternativo hermético" },
      { id: "Alternativo aberto", label: "Alternativo aberto" },
      { id: "Parafuso aberto", label: "Parafuso aberto" },
      { id: "Scroll", label: "Scroll" },
      { id: "Centrífugo", label: "Centrífugo" },
      { id: "Rotativo", label: "Rotativo" },
      { id: "Alternativo de duplo estágio de compressão", label: "Alternativo de duplo estágio de compressão" },
    ]
  },
  ventiladores: {
    coluna1: [
      { id: "Axial de parede", label: "Axial de parede" },
      { id: "Axial de telhado", label: "Axial de telhado" },
      { id: "Industrial (vento)", label: "Industrial (vento)" },
      { id: "Centrífugo", label: "Centrífugo" },
    ]
  },
  inversores_de_frequencia: {
    coluna1: [
      { id: "Escalar", label: "Escalar" },
      { id: "Vetorial", label: "Vetorial" },
    ]
  },
  refrigeracao: {
    coluna1: [
      { id: "Chiller", label: "Chiller" },
      { id: "Compressores", label: "Compressores" },
      { id: "Bombas", label: "Bombas" },
      { id: "Torres de resfriamento", label: "Torres de resfriamento" },
      { id: "Evaporadoras", label: "Evaporadoras" },
    ],
    coluna7: [
      { id: "Sim", label: "Sim" },
      { id: "Não", label: "Não" },
    ]
  },
  sistema_de_gerenciamento_de_energia: {
    coluna1: [
      { id: "Energia elétrica", label: "Energia elétrica" },
      { id: "Água", label: "Água" },
      { id: "Gás", label: "Gás" },
      { id: "Temperatura", label: "Temperatura" },
      { id: "Nível", label: "Nível" },
      { id: "Outro", label: "Outro" },
    ],
    coluna2: [
      { id: "Fixo", label: "Fixo" },
      { id: "Em tempo real", label: "Em tempo real" },
      { id: "WEB", label: "WEB" },
      { id: "Smartphone", label: "Smartphone" },
      { id: "Outro", label: "Outro" },
    ]
  },
  cogeracao: {
    coluna1: [
      { id: "Produção de Vapor", label: "Produção de Vapor" },
      { id: "Turbo Máquina", label: "Turbo Máquina" },
      { id: "Gerador Elétrico", label: "Gerador Elétrico" },
    ],
    coluna2: [
      { id: "Eletriciade", label: "Eletriciade" },
      { id: "Combustíveis", label: "Combustíveis" },
      { id: "Água", label: "Água" },
      { id: "Nitrogênio", label: "Nitrogênio" },
    ],
    coluna5: [
      { id: "Água Desmineralizada", label: "Água Desmineralizada" },
      { id: "Combustíveis", label: "Combustíveis" },
      { id: "Outro", label: "Outro" },
    ],
    coluna10: [
      { id: "Sala de Controle", label: "Sala de Controle" },
      { id: "Operação Local", label: "Operação Local" },
      { id: "Outro", label: "Outro" },
    ],
    coluna11: [
      { id: "Válvulas", label: "Válvulas" },
      { id: "Bombas", label: "Bombas" },
      { id: "Sistema de Vedação", label: "Sistema de Vedação" },
      { id: "Sistema de Exaustão Forçada", label: "Sistema de Exaustão Forçada" },
    ],
    coluna12: [
      { id: "Inversores de Frequência", label: "Inversores de Frequência" },
      { id: "Centro de Comando de Motores", label: "Centro de Comando de Motores" },
      { id: "Sistema de Monitoramento de Vibração de Motores e Bombas", label: "Sistema de Monitoramento de Vibração de Motores e Bombas" },
      { id: "Painéis Elétricos", label: "Painéis Elétricos" },
      { id: "Iluminação de Emergência", label: "Iluminação de Emergência" },
      { id: "Conjunto de Tomadas", label: "Conjunto de Tomadas" },
      { id: "SPDA", label: "SPDA" },
      { id: "Sistema de Distribuição de Energia Gerada pelo Sistema de Cogeração", label: "Sistema de Distribuição de Energia Gerada pelo Sistema de Cogeração" },
    ]
  },
  isolamento_termico: {
    coluna1: [
      { id: "Lã de Rocha", label: "Lã de Rocha" },
      { id: "Fibra de Vidro", label: "Fibra de Vidro" },
      { id: "Fibra Cerâmica", label: "Fibra Cerâmica" },
      { id: "Poliuretano Expandido", label: "Poliuretano Expandido" },
      { id: "Poliestireno Extrudado", label: "Poliestireno Extrudado" },
      { id: "Outro", label: "Outro" },
    ]
  },
  caldeiras: {
    coluna1: [
      { id: "Flamotubular", label: "Flamotubular" },
      { id: "Aquatubular", label: "Aquatubular" },
      { id: "Mista", label: "Mista" },
    ]
  },
  queimadores: {
    coluna1: [
      { id: "Queimadores a gás", label: "Queimadores a gás" },
      { id: "Queimadores a óleo", label: "Queimadores a óleo" },
      { id: "Queimadores a biomassa", label: "Queimadores a biomassa" },
      { id: "Queimadores a carvão", label: "Queimadores a carvão" },
    ],
    coluna2: [
      { id: "Direto", label: "Direto" },
      { id: "Indireto", label: "Indireto" },
    ]
  },
  fornos: {
    coluna1: [
      { id: "Arco elétrico a arco/arco voltaico (FEA)", label: "Arco elétrico a arco/arco voltaico (FEA)" },
      { id: "Calcinação", label: "Calcinação" },
      { id: "Cocção de alimentos", label: "Cocção de alimentos" },
      { id: "Indução", label: "Indução" },
      { id: "Pirólise lenta/craqueamento", label: "Pirólise lenta/craqueamento" },
      { id: "Recozimento", label: "Recozimento" },
      { id: "Secagem", label: "Secagem" },
      { id: "Têmpera", label: "Têmpera" },
      { id: "Têmpera a laser", label: "Têmpera a laser" },
      { id: "Têmpera e revenimento", label: "Têmpera e revenimento" },
      { id: "Têmpera por indução", label: "Têmpera por indução" },
      { id: "Tratamento térmico cerâmica", label: "Tratamento térmico cerâmica" },
      { id: "Tratamento térmico polímero", label: "Tratamento térmico polímero" },
    ],
    coluna2: [
      { id: "Direto", label: "Direto" },
      { id: "Indireto", label: "Indireto" },
    ]
  },
  aquecedores: {
    coluna1: [
      { id: "Arco elétrico a arco/arco voltaico (FEA)", label: "Arco elétrico a arco/arco voltaico (FEA)" },
      { id: "Calcinação", label: "Calcinação" },
      { id: "Cocção de alimentos", label: "Cocção de alimentos" },
      { id: "Indução", label: "Indução" },
      { id: "Pirólise lenta/craqueamento", label: "Pirólise lenta/craqueamento" },
      { id: "Recozimento", label: "Recozimento" },
      { id: "Secagem", label: "Secagem" },
      { id: "Têmpera", label: "Têmpera" },
      { id: "Têmpera a laser", label: "Têmpera a laser" },
      { id: "Têmpera e revenimento", label: "Têmpera e revenimento" },
      { id: "Têmpera por indução", label: "Têmpera por indução" },
      { id: "Tratamento térmico cerâmica", label: "Tratamento térmico cerâmica" },
      { id: "Tratamento térmico polímero", label: "Tratamento térmico polímero" },
    ],
    coluna2: [
      { id: "Direto", label: "Direto" },
      { id: "Indireto", label: "Indireto" },
    ]
  },
  coletores_solares: {
    coluna1: [
      { id: "Com vidro", label: "Com vidro" },
      { id: "Não isolado", label: "Não isolado" },
      { id: "A vácuo", label: "A vácuo" },
    ]
  },
  reservatorios_termicos: {
    coluna1: [
      { id: "Sistema de Resfriamento", label: "Sistema de Resfriamento" },
      { id: "Proteção Anticorrosiva e Desincrustante", label: "Proteção Anticorrosiva e Desincrustante" },
      { id: "Controle microbiológico", label: "Controle microbiológico" },
      { id: "Controle de Drenagens", label: "Controle de Drenagens" },
    ],
    coluna5: [
      { id: "Elético", label: "Elético" },
      { id: "Vapor", label: "Vapor" },
      { id: "Sem apoio", label: "Sem apoio" },
    ]
  },
  trocadores_de_calor: {
    coluna1: [
      { id: "Casco-tubo", label: "Casco-tubo" },
      { id: "Placas", label: "Placas" },
      { id: "Serpentina", label: "Serpentina" },
      { id: "Tubo encamisado", label: "Tubo encamisado" },
      { id: "Tubo aletado", label: "Tubo aletado" },
      { id: "Heat-pipe", label: "Heat-pipe" },
    ],
    coluna3: [
      { id: "Aquecimento", label: "Aquecimento" },
      { id: "Resfriamento", label: "Resfriamento" },
      { id: "Evaporador", label: "Evaporador" },
      { id: "Condensador", label: "Condensador" },
    ]
  },
  bombas_de_calor: {
    coluna1: [
      { id: "1000", label: "1000" },
      { id: "500", label: "500" },
    ]
  },
}

export const listaDocumentosFornecedor = [
  {
    titulo: "1. ACT",
    id: "actId",
    aprovado: "actAprovado",
    justificativa: "actJustificativa",
  },
  {
    titulo: "2. CAT",
    id: "catId",
    aprovado: "catAprovado",
    justificativa: "catJustificativa",
  },
  {
    titulo: "3. Declaração do fabricante",
    id: "declaracaoFabricanteId",
    aprovado: "declaracaoFabricanteAprovado",
    justificativa: "declaracaoFabricanteJustificativa",
  },
  {
    titulo: "4. Certidão de Registro - CREA Pessoa Juridica",
    id: "certidaoCreaId",
    aprovado: "certidaoCreaAprovado",
    justificativa: "certidaoCreaJustificativa",
  },
  {
    titulo: "5. Pedido Público (Anexo I)",
    id: "pedidoPublicoAnexo1Id",
    aprovado: "pedidoPublicoAnexo1Aprovado",
    justificativa: "pedidoPublicoAnexo1Justificativa",
  },
  {
    titulo: "6. Declaração de outras informações (Anexo IX)",
    id: "outrasInformacoesAnexo9Id",
    aprovado: "outrasInformacoesAnexo9Aprovado",
    justificativa: "outrasInformacoesAnexo9Justificativa",
  },
  {
    titulo: "7. Documentos - MEI",
    id: "documentosMeiId",
    aprovado: "documentosMeiAprovado",
    justificativa: "documentosMeiJustificativa",
  },
  {
    titulo: "7. Ato Constitutivo - Sociedades Simples",
    id: "atoSociedadeSimplesId",
    aprovado: "atoSociedadeSimplesAprovado",
    justificativa: "atoSociedadeSimplesJustificativa",
  },
  {
    titulo: "7. Ato Constitutivo - Sociedades por Ações",
    id: "atoSociedadeAcoesId",
    aprovado: "atoSociedadeAcoesAprovado",
    justificativa: "atoSociedadeAcoesJustificativa",
  },
  {
    titulo: "8. Certidão Negativa Falência ou Certidão Judicial - não superior a 90 dias",
    id: "negativaFalenciaId",
    aprovado: "negativaFalenciaAprovado",
    justificativa: "negativaFalenciaJustificativa",
  },
  {
    titulo: "9. Certidão vigente emitida pela instância Judicial",
    id: "certidaoVirgenteId",
    aprovado: "certidaoVirgenteAprovado",
    justificativa: "certidaoVirgenteJustificativa",
  },
  {
    titulo: "10. Balanço Patrimonial e Demonst. contábeis",
    id: "balancoPatrimonialId",
    aprovado: "balancoPatrimonialAprovado",
    justificativa: "balancoPatrimonialJustificativa",
  },
  {
    titulo: "11. Prova de Inscrição - CNPJ",
    id: "provaInscCnpjId",
    aprovado: "provaInscCnpjAprovado",
    justificativa: "provaInscCnpjJustificativa",
  },
  {
    titulo: "12. Cadastro de Contribuinte Municipal",
    id: "cadastroContribuinteMunicipalId",
    aprovado: "cadastroContribuinteMunicipalAprovado",
    justificativa: "cadastroContribuinteMunicipalJustificativa",
  },
  {
    titulo: "13. Cadastro de Contribuinte Estadual",
    id: "cadastroContribuinteEstadualId",
    aprovado: "cadastroContribuinteEstadualAprovado",
    justificativa: "cadastroContribuinteEstadualJustificativa",
  },
  {
    titulo: "14. Prova de Regularidade - Fazenda Nacional",
    id: "regularidadeFazendaNacionalId",
    aprovado: "regularidadeFazendaNacionalAprovado",
    justificativa: "regularidadeFazendaNacionalJustificativa",
  },
  {
    titulo: "15. Prova de Regularidade - Fazenda Estadual",
    id: "regulariadeFazendaEstadualId",
    aprovado: "regulariadeFazendaEstadualAprovado",
    justificativa: "regulariadeFazendaEstadualJustificativa",
  },
  {
    titulo: "16. Prova de Regularidade - Fazenda Municipal",
    id: "regularidadeFazendaMunicipalId",
    aprovado: "regularidadeFazendaMunicipalAprovado",
    justificativa: "regularidadeFazendaMunicipalJustificativa",
  },
  {
    titulo: "17. Prova de Regularidade - FGTS",
    id: "regularidadeFgtsId",
    aprovado: "regularidadeFgtsAprovado",
    justificativa: "regularidadeFgtsJustificativa",
  },
  {
    titulo: "18. Prova de Regularidade – Justiça do Trabalho",
    id: "regularidadeJusticaTrabalhoId",
    aprovado: "regularidadeJusticaTrabalhoAprovado",
    justificativa: "regularidadeJusticaTrabalhoJustificativa",
  }
];

export const tituloTabelaAddOrcamentoPorCarga = {
  iluminacao: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipo de lâmpada",
    coluna2: "Formato da lâmpada",
    coluna3: "Potência (w)",
    coluna4: "Luminosidade (lumens)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Fator de Potência",
    colunaB2: "Eficiência luminosa (lm/W)",
    colunaB3: "Temperatura de cor (K)",
    colunaB4: "Tempo de garantia (anos)",
    colunaB5: "Possui selo Procel?",
    colunaB6: "Classificação INMETRO",
    colunaB7: "Vida útil (horas)",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  condicionadores_de_ar: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipo de ar condicionado",
    coluna2: "Capacidade de Refrigeração",
    coluna3: "Corrente (A)",
    coluna4: "Tesnsão de alimentação (v)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "IDRS",
    colunaB2: "Tipo de compressor",
    colunaB3: "Fluido refrigerante",
    colunaB4: "Possui tecnologia inverter?",
    colunaB5: "Possui selo Procel?",
    colunaB6: "Classificação INMETRO",
    colunaB7: "Vida útil (anos)",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  motores_eletricos: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Potência Nominal (kW)",
    coluna2: "Número de Pólos",
    coluna3: "Velocidade Síncrona (rpm)",
    coluna4: "Redimento Nominal (%)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Garantia (anos)",
    colunaB2: "Possui selo Procel?",
    colunaB3: "Classificação eficiência",
    colunaB4: "Possui inversor de frequência (VFD)?",
    colunaB5: "Vida útil (anos)",
    colunaB6: "",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  bombas: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipo de bomba",
    coluna2: "Potência (HP)",
    coluna3: "Vazão desejada (m³h)",
    coluna4: "Rotação por pressão (psi)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Garantia (anos)",
    colunaB2: "Possui selo Procel?",
    colunaB3: "Classificação INMETRO",
    colunaB4: "Possui inversor de frequência (VFD)?",
    colunaB5: "Vida útil (anos)",
    colunaB6: "",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  compressores: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipo de compressor",
    coluna2: "Potência (HP)",
    coluna3: "Pressão máxiam (PSI)",
    coluna4: "Vazão fornecidad(l/min)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Garantia (anos)",
    colunaB2: "COP - aplicável para chillers",
    colunaB3: "Nível de ruído",
    colunaB4: "Possui inversor de frequência (VFD)?",
    colunaB5: "Vida útil (anos)",
    colunaB6: "",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  ventiladores: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipo de Ventilador",
    coluna2: "Potência do motor (W)",
    coluna3: "Vazão média de ar em alta velocidade(m³/s)",
    coluna4: "Tensão de alimentação(V)",
    coluna5: "Número de Pás",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Tipo de oscilação",
    colunaB2: "Possui acoplamento reforçado?",
    colunaB3: "Possui selo Procel?",
    colunaB4: "Classificação INMETRO",
    colunaB5: "Possui inversor de frequência (VFD)?",
    colunaB6: "Vida útil (anos)",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  inversores_de_frequencia: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipo de inversor de frequência",
    coluna2: "Potência do motor que o inversor será instalado (kW)",
    coluna3: "Corrente do motor que o inversor será instalado (A)",
    coluna4: "Tensão de alimentação (V)",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Possui proteção de subtensão?",
    colunaB2: "Possui proteção de sobretensão?",
    colunaB3: "Possui proteção de sobrecarga e aquecimento?",
    colunaB4: "Possui proteção de sobrecorrente?",
    colunaB5: "Possui função de religamento automático?",
    colunaB6: "Vida útil (anos)",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  refrigeracao: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Equipamento de refrigeração",
    coluna2: "Capacidade de refrigeração (TR)",
    coluna3: "COP/EER mínimo",
    coluna4: "GWP máximo do Fluido refrigerante",
    coluna5: "Vazão de água (m³/h)",
    coluna6: "Temperatura da água resfriada (°C)",
    coluna7: "Possui inversor de frequência [S/N]",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Tempo de garantia (anos)",
    colunaB2: "Classificação INMETRO",
    colunaB3: "Vida útil (anos)",
    colunaB4: "",
    colunaB5: "",
    colunaB6: "",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  sistema_de_gerenciamento_de_energia: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipos de monitoramento e controle",
    coluna2: "Tipo de Aplicação",
    coluna3: "",
    coluna4: "",
    coluna5: "",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Entrega relatórios de medição periodicamente?",
    colunaB2: "Entrega gráficos e relatórios do consumo unitário, em kWh?",
    colunaB3: "Entrega acompanhamento de meta de consumo?",
    colunaB4: "Realiza simulações de perfis energéticos (inclusão ou retitada de cargas indutiva/capacitiva)?",
    colunaB5: "Realiza rateio inteligente entre centro de custos?",
    colunaB6: "Tempo de garantia (anos)",
    colunaB7: "Vida útil (anos)",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  cogeracao: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Planta de Cogeração",
    coluna2: "Combustíveis",
    coluna3: "Sistema de Armazenamento de Combústiveis",
    coluna4: "Sistema de Desmin. Água",
    coluna5: "Sistema de Transporte",
    coluna6: "Sistema de Automação",
    coluna7: "Sistema de Instrumentação",
    coluna8: "Sistema de Monitoramento de Co²",
    coluna9: "Sistema de Monitoramento CFTV",
    coluna10: "Sistema de Intercomunicação",
    coluna11: "Componentes Mecânicos",
    coluna12: "Componentes Elétricos",
    coluna13: "Sistema de Distribuição de Vapor do Processo",
    colunaB1: "Tempo de garantia (anos)",
    colunaB2: "Vida útil (anos)",
    colunaB3: "",
    colunaB4: "",
    colunaB5: "",
    colunaB6: "",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  isolamento_termico: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Material do isolamento térmico",
    coluna2: "Temperatura da superfície a ser isolada (°C)",
    coluna3: "Condutividade térmica do isolamento (W/m.K)",
    coluna4: "Espessura do isolamento térmico (mm)",
    coluna5: "Área a ser isolada (m²)",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Composição química",
    colunaB2: "Temperatura máxima de operação (°C)",
    colunaB3: "Densidade (kg/m³)",
    colunaB4: "Vida útil (anos)",
    colunaB5: "",
    colunaB6: "",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",
  },
  caldeiras: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tecnologia (flamotubular; aquatubular; mista)",
    coluna2: "Eficiência energética (%)",
    coluna3: "Capacidade de aquecimento (kW; kcal)",
    coluna4: "Capacidade de geração de vapor (t/h)",
    coluna5: "Pressão máxima e de operação (kgf/cm2; bar; psi; Pa)",
    coluna6: "Temperatura máxima de vapor gerado (ºC)",
    coluna7: "Tipo de energético (biomassa, eletricidade, GLP, GN, óleo)",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Tempo de garantia (anos)",
    colunaB2: "Possui economizador ou pré-aquecedor de ar?",
    colunaB3: "Nível de emissões (kg CO2/kWh; kg CO2/kcal)",
    colunaB4: "Vida útil (anos)",
    colunaB5: "Possui normas nacionais?",
    colunaB6: "Possui normas internacionais?",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  queimadores: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Capacidade de aquecimento (kW; kcal)",
    coluna2: "Tipo de combustível",
    coluna3: "Excesso de ar (%)",
    coluna4: "Tipo de modulação",
    coluna5: "Taxa de turndown",
    coluna6: "",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Possui oxi-combustão?",
    colunaB2: "Eficiência energética (%)",
    colunaB3: "Tempo de garantia (anos)",
    colunaB4: "Possui pré-aquecedor?",
    colunaB5: "Possui recirculação de gases?",
    colunaB6: "Emissões de Nox (mg/Nm3)",
    colunaB7: "Vida útil (anos)",
    colunaB8: "Possui normas nacionais?",
    colunaB9: "Possui normas internacionais?",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  fornos: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipo de aplicação industrial",
    coluna2: "Tipo de aquecimento (direto; indireto)",
    coluna3: "Capacidade de aquecimento (kW; kcal)",
    coluna4: "Capacidade de produção a plena carga (kg; t; kg/h; t/h)",
    coluna5: "Máxima temperatura interna (ºC)",
    coluna6: "Temperatura de saída dos gases (ºC) (exceto para equipamentos elétricos)",
    coluna7: "Eficiência energética (%)",
    coluna8: "Tipo de energético (biomassa, eletricidade, GLP, GN, óleo)",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Capacidade de produção com 50% da carga (kg; t; kg/h; t/h)",
    colunaB2: "Temperatura de trabalho de máxima eficiência",
    colunaB3: "Carga de trabalho de máxima eficiência",
    colunaB4: "Vida útil (anos)",
    colunaB5: "Possui normas nacionais?",
    colunaB6: "Possui normas internacionais?",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  aquecedores: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipo de aplicação industrial",
    coluna2: "Tipo de aquecimento (direto; indireto)",
    coluna3: "Tipo de tecnologia",
    coluna4: "Capacidade de aquecimento (kW; kcal)",
    coluna5: "Pressão máxima de trabalho do fluido (kgf/cm2; psi; bar; Pa)",
    coluna6: "Máxima temperatura de saída do fluido térmico (ºC)",
    coluna7: "Eficiência energética para a máxima capacidade (%)",
    coluna8: "Tipo de energético (biomassa, eletricidade, GLP, GN, óleo)",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Vida útil (anos)",
    colunaB2: "Possui normas nacionais?",
    colunaB3: "Possui normas internacionais?",
    colunaB4: "",
    colunaB5: "",
    colunaB6: "",
    colunaB7: "",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",
  },
  coletores_solares: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tecnologia (com vidro, não isolado, a vácuo)",
    coluna2: "Temperatura de operação (ºC)",
    coluna3: "Temperatura de alimentação (ºC)",
    coluna4: "Vazão diária de água",
    coluna5: "Pressão do sistema (mca ou bar - indicar a unidade)",
    coluna6: "Número de horas de operação por dia",
    coluna7: "Latitude da instalação",
    coluna8: "Longitude da Instalação",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Eficiência energética (%)",
    colunaB2: "Alta pressão/baixa pressão",
    colunaB3: "Área do Coletor (m²)",
    colunaB4: "Emissividade ou absorbância",
    colunaB5: "Pressão máxima de funcionamento",
    colunaB6: "Produção mensal média de energia (kWh/mês)",
    colunaB7: "Tempo de garantia (anos)",
    colunaB8: "Vida útil (anos)",
    colunaB9: "Possui normas nacionais?",
    colunaB10: "Possui normas internacionais?",
    colunaB11: "Outras informações relevantes",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  reservatorios_termicos: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Volume do reservatório",
    coluna2: "Temperatura de operação (ºC)",
    coluna3: "Fluido armazenado",
    coluna4: "Material do corpo interno",
    coluna5: "Tipo de apoio",
    coluna6: "Potência mínima da resistência de apoio (quando usada)",
    coluna7: "Pressão do vapor para a serpentina de apoio (quando usada)",
    coluna8: "Pressão de operação (mca ou bar - indicar a unidade)",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Material do isolamento",
    colunaB2: "Perda específica mensal (kWh/l.mês)",
    colunaB3: "Vazão de vapor para a serpentina de apoio (quando usada) em kg/h",
    colunaB4: "Pressão máxima de funcionamento (mca)",
    colunaB5: "Possui normas nacionais?",
    colunaB6: "Possui normas internacionais?",
    colunaB7: "Outras informações relevantes",
    colunaB8: "",
    colunaB9: "",
    colunaB10: "",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  trocadores_de_calor: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Tipo de trocador",
    coluna2: "Capacidade (kW)",
    coluna3: "Função",
    coluna4: "Descrição do fluido 1  - de alimentação (de onde será extraído o calor ou frio)",
    coluna5: "Descrião do f]luido 2 - de recuperação (para onde vai o calor ou o frio recuperados)",
    coluna6: "Temperatura de entrada - fluido 1 (de alimentação) ºC",
    coluna7: "Temperatura de entrada - fluido 2 (de recuperação) ºC",
    coluna8: "Temperatura de saída desejada - fluido 2 (de recuperação) ºC",
    coluna9: "Vazão do fluido 1 (de alimentação) kg/h",
    coluna10: "Vazão do fluido 2 (de recuperação) kg/h",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Material principal",
    colunaB2: "Tipo de fluido 1 (de onde será recuperado o calor ou o frio)",
    colunaB3: "Tipo de fluido 2 (onde será utilizado o calor ou o frio recuperado)",
    colunaB4: "Pressão máxima (bar)",
    colunaB5: "Vazão alta (kg/h)",
    colunaB6: "Vazão baixa (kg/h)",
    colunaB7: "Tempo de garantia (anos)",
    colunaB8: "Temperatura de aproximação (ºC)",
    colunaB9: "Vida útil (anos)",
    colunaB10: "Possui normas nacionais?",
    colunaB11: "Possui normas internacionais?",
    colunaB12: "Outras informações relevantes",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",

  },
  bombas_de_calor: {
    colunaA1: "DISCRIMINAÇÃO / FABRICANTE / MODELO",
    colunaA2: "TIPO - Se houver código FINAME, indique na coluna ao lado",
    colunaA3: "Código FINAME",
    colunaA4: "CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado",
    colunaA5: "Código POTENCIALIZEE",
    colunaA6: "",
    coluna1: "Capacidade de aquecimento necessária (kW)",
    coluna2: "Temperatura de entrada da água",
    coluna3: "CONDENSADOR (LADO QUENTE) Temperatura de saída da água",
    coluna4: "CONDENSADOR (LADO QUENTE) Temperatura de entrada da água",
    coluna5: "EVAPORADOR (LADO FRIO) Temperatura de saída da água",
    coluna6: "EVAPORADOR (LADO FRIO) Tensão de alimentação (V)",
    coluna7: "",
    coluna8: "",
    coluna9: "",
    coluna10: "",
    coluna11: "",
    coluna12: "",
    coluna13: "",
    colunaB1: "Capacidade de aquecimento unitária (kW)",
    colunaB2: "COP aquecimento",
    colunaB3: "Capacidade de refrigeração (kW)",
    colunaB4: "COP refrigeração",
    colunaB5: "Potência elétrica nominal (kW)",
    colunaB6: "Possui tecnologia inverter?",
    colunaB7: "Possui selo Procel?",
    colunaB8: "Classificação INMETRO",
    colunaB9: "Vida útil (anos)",
    colunaB10: "Valor estimado do Drycooler",
    colunaB11: "",
    colunaB12: "",
    coluna14: "Quantidade",
    coluna15: "Valor unitário R$",
    coluna16: "Valor total R$",
    colunaB13: "Valor previsto para instalação (R$)",
    colunaB14: "Valor previsto para descarte (R$) - se aplicável",
    colunaB15: "valor total (R$)",
  }
};

export const MetasSugestoesEEPorTecnologia = [
  { id: 'Retrofit do projeto luminotécnico', label: 'Retrofit do projeto luminotécnico' }
  , { id: 'Alterações de envoltória para melhor aproveitamento da iluminação natural ou limpar janelas e telhas translúcidas', label: 'Alterações de envoltória para melhor aproveitamento da iluminação natural ou limpar janelas e telhas translúcidas' }
  , { id: 'Instalação de sensores e fotocélulas', label: 'Instalação de sensores e fotocélulas' }
  , { id: 'Estabelecer rotina regular de limpeza das lâmpadas e luminárias', label: 'Estabelecer rotina regular de limpeza das lâmpadas e luminárias' }
  , { id: 'Proceder limpeza de superfícies e procurar alterar cores para melhorar refletência', label: 'Proceder limpeza de superfícies e procurar alterar cores para melhorar refletência' }
  , { id: 'Reconfigurar ou instalar mais interruptores para melhor controle da iluminação nas áreas de trabalho', label: 'Reconfigurar ou instalar mais interruptores para melhor controle da iluminação nas áreas de trabalho' }
  , { id: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)', label: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)' }
  , { id: 'Realização de política de compras eficiente', label: 'Realização de política de compras eficiente' }
  , { id: 'Retrofit por sistemas eficientes com inverter', label: 'Retrofit por sistemas eficientes com inverter' }
  , { id: 'Sugerir que a PME verifique a implementação de PMOC', label: 'Sugerir que a PME verifique a implementação de PMOC' }
  , { id: 'Instalação de variadores de frequência (VFD)', label: 'Instalação de variadores de frequência (VFD)' }
  , { id: 'Implementação de controlador IMS', label: 'Implementação de controlador IMS' }
  , { id: 'Adequações hidráulicas nos dutos de distribuição e retorno', label: 'Adequações hidráulicas nos dutos de distribuição e retorno' }
  , { id: 'Estabelecer manutenção regular de limpeza dos filtros', label: 'Estabelecer manutenção regular de limpeza dos filtros' }
  , { id: 'Reparar ou refazer isolamento', label: 'Reparar ou refazer isolamento' }
  , { id: 'Limpar entradas e saídas, remover obstruções', label: 'Limpar entradas e saídas, remover obstruções' }
  , { id: 'Ajustar termostato para 24°C a 26°C no inverno e 21°C a 23°C no verão', label: 'Ajustar termostato para 24°C a 26°C no inverno e 21°C a 23°C no verão' }
  , { id: 'Programar aferição de temperaturas retirando termostatos de áreas com muitas variações', label: 'Programar aferição de temperaturas retirando termostatos de áreas com muitas variações' }
  , { id: 'Instalar sistema para fechamento automático de portas', label: 'Instalar sistema para fechamento automático de portas' }
  , { id: 'Pintura da envoltória para cores claras para diminuição da absorção solar', label: 'Pintura da envoltória para cores claras para diminuição da absorção solar' }
  , { id: 'Instalação de elementos de envoltória para redução da carga térmica das áreas internas', label: 'Instalação de elementos de envoltória para redução da carga térmica das áreas internas' }
  , { id: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)', label: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)' }
  , { id: 'Realização de política de compras eficiente', label: 'Realização de política de compras eficiente' }
  , { id: 'Correções e ajustes no projeto mecânico', label: 'Correções e ajustes no projeto mecânico' }
  , { id: 'Troca de motor DC para AC com VFD', label: 'Troca de motor DC para AC com VFD' }
  , { id: 'Troca de motores por novos corretamente dimensionados', label: 'Troca de motores por novos corretamente dimensionados' }
  , { id: 'Instalação de variadores de frequência (VFD)', label: 'Instalação de variadores de frequência (VFD)' }
  , { id: 'Utilização de motores novos e de alta eficiência', label: 'Utilização de motores novos e de alta eficiência' }
  , { id: 'Implementação de sistema de monitoramento', label: 'Implementação de sistema de monitoramento' }
  , { id: 'Adequações topológicas da rede de distribuição', label: 'Adequações topológicas da rede de distribuição' }
  , { id: 'Desenvolvimento de um sistema automático de controle ', label: 'Desenvolvimento de um sistema automático de controle ' }
  , { id: 'Adequações topológicas da rede de distribuição', label: 'Adequações topológicas da rede de distribuição' }
  , { id: 'Instalação de reservatórios e/ou etapas booster', label: 'Instalação de reservatórios e/ou etapas booster' }
  , { id: 'Desenvolvimento de um sistema automático de controle ', label: 'Desenvolvimento de um sistema automático de controle ' }
  , { id: 'Troca de motores por novos corretamente dimensionados', label: 'Troca de motores por novos corretamente dimensionados' }
  , { id: 'Instalação de variadores de frequência (VFD)', label: 'Instalação de variadores de frequência (VFD)' }
  , { id: 'Troca de motores antigos por novos de maior eficiência', label: 'Troca de motores antigos por novos de maior eficiência' }
  , { id: 'Implementação de sistema de monitoramento', label: 'Implementação de sistema de monitoramento' }
  , { id: 'Melhorar a especificação do acoplamento ou trocar por acoplamento direto', label: 'Melhorar a especificação do acoplamento ou trocar por acoplamento direto' }
  , { id: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)', label: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)' }
  , { id: 'Aquisição de medidores de vazão para os sistemas de bombeamento', label: 'Aquisição de medidores de vazão para os sistemas de bombeamento' }
  , { id: 'Realização de política de compras eficiente', label: 'Realização de política de compras eficiente' }
  , { id: 'Divisão da rede por nível de pressão (alta e baixa pressão)', label: 'Divisão da rede por nível de pressão (alta e baixa pressão)' }
  , { id: 'Instalação de secadores de alta eficiência com controle de pressão', label: 'Instalação de secadores de alta eficiência com controle de pressão' }
  , { id: 'Redução de vazamentos', label: 'Redução de vazamentos' }
  , { id: 'Divisão da rede por nível de pressão (alta e baixa pressão)', label: 'Divisão da rede por nível de pressão (alta e baixa pressão)' }
  , { id: 'Instalação de secadores de alta eficiência com controle de pressão', label: 'Instalação de secadores de alta eficiência com controle de pressão' }
  , { id: 'Instalação de equipamentos acessórios mais eficientes (p.ex., bicos aspersores, ferramentas pneumáticas)', label: 'Instalação de equipamentos acessórios mais eficientes (p.ex., bicos aspersores, ferramentas pneumáticas)' }
  , { id: 'Instalar sistemas de limpeza sem ar comprimido (blowers)', label: 'Instalar sistemas de limpeza sem ar comprimido (blowers)' }
  , { id: 'Redução da pressão de trabalho', label: 'Redução da pressão de trabalho' }
  , { id: 'Melhoria do projeto hidráulico: diâmetros, curvas, acessórios, pulmões', label: 'Melhoria do projeto hidráulico: diâmetros, curvas, acessórios, pulmões' }
  , { id: 'Ajuste dos parâmetros de operação dos compressores e entre compressores (p.ex., tempo de alívio, tempo de desligamento, número de acionamentos por período etc.)', label: 'Ajuste dos parâmetros de operação dos compressores e entre compressores (p.ex., tempo de alívio, tempo de desligamento, número de acionamentos por período etc.)' }
  , { id: 'Instalação de compressores mais eficientes', label: 'Instalação de compressores mais eficientes' }
  , { id: 'Instalação de pelo menos um compressor com controle VFD', label: 'Instalação de pelo menos um compressor com controle VFD' }
  , { id: 'Redução de vazamentos', label: 'Redução de vazamentos' }
  , { id: 'Aquisição de medidores e gabinetes de reparo de vazamentos', label: 'Aquisição de medidores e gabinetes de reparo de vazamentos' }
  , { id: 'Aquisição de manômetros para a linha de ar comprimido', label: 'Aquisição de manômetros para a linha de ar comprimido' }
  , { id: 'Melhorar a especificação do acoplamento ou trocar por acoplamento direto', label: 'Melhorar a especificação do acoplamento ou trocar por acoplamento direto' }
  , { id: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)', label: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)' }
  , { id: 'Desobstrução das saídas de ar do compressor para melhoria de performance', label: 'Desobstrução das saídas de ar do compressor para melhoria de performance' }
  , { id: 'Desobstrução das saídas de ar do compressor para melhoria de performance', label: 'Desobstrução das saídas de ar do compressor para melhoria de performance' }
  , { id: 'Realizar estudo se o ar comprimido pode ser substituído por outra fonte mais eficiente para algum consumidor da fábrica', label: 'Realizar estudo se o ar comprimido pode ser substituído por outra fonte mais eficiente para algum consumidor da fábrica' }
  , { id: 'Realização de política de compras eficiente', label: 'Realização de política de compras eficiente' }
  , { id: 'Retrofit por sistemas eficientes e com o dimensionamento adequado', label: 'Retrofit por sistemas eficientes e com o dimensionamento adequado' }
  , { id: 'Implementar controle de vazão nos exaustores utilizando, por exemplo, VFD', label: 'Implementar controle de vazão nos exaustores utilizando, por exemplo, VFD' }
  , { id: 'Retrofit por sistemas eficientes e com o dimensionamento adequado', label: 'Retrofit por sistemas eficientes e com o dimensionamento adequado' }
  , { id: 'Sugerir que a PME verifique a implementação de PMOC', label: 'Sugerir que a PME verifique a implementação de PMOC' }
  , { id: 'Alterações de envoltória para melhor aproveitamento da ventilação natural', label: 'Alterações de envoltória para melhor aproveitamento da ventilação natural' }
  , { id: 'Troca de motor DC para AC com VFD', label: 'Troca de motor DC para AC com VFD' }
  , { id: 'Instalação de variadores de frequência (VFD)', label: 'Instalação de variadores de frequência (VFD)' }
  , { id: 'Troca de motores antigos por novos de maior eficiência', label: 'Troca de motores antigos por novos de maior eficiência' }
  , { id: 'Implementação de controlador IMS', label: 'Implementação de controlador IMS' }
  , { id: 'Implementar controle de vazão nos exaustores utilizando, por exemplo, VFD', label: 'Implementar controle de vazão nos exaustores utilizando, por exemplo, VFD' }
  , { id: 'Melhorar a especificação do acoplamento ou trocar por acoplamento direto', label: 'Melhorar a especificação do acoplamento ou trocar por acoplamento direto' }
  , { id: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)', label: 'Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)' }
  , { id: 'Realização de política de compras eficiente', label: 'Realização de política de compras eficiente' }
  , { id: 'Instalação de reservatório térmico de acumulação', label: 'Instalação de reservatório térmico de acumulação' }
  , { id: 'Possibilidade de substituição do(s) chiller(s)', label: 'Possibilidade de substituição do(s) chiller(s)' }
  , { id: 'Avaliação de bombas de calor para geração de frio', label: 'Avaliação de bombas de calor para geração de frio' }
  , { id: 'Verificar possibilidade de operar com capacidade máxima', label: 'Verificar possibilidade de operar com capacidade máxima' }
  , { id: 'Instalação de isolamento térmico', label: 'Instalação de isolamento térmico' }
  , { id: 'Redimensionamento do isolamento térmico para atendimento à especificação em função da aplicação', label: 'Redimensionamento do isolamento térmico para atendimento à especificação em função da aplicação' }
  , { id: 'Manutenção do isolamento térmico', label: 'Manutenção do isolamento térmico' }
  , { id: 'Redução e correção de vazamentos (perdas) nos processos ', label: 'Redução e correção de vazamentos (perdas) nos processos ' }
  , { id: 'Avaliar as possibilidades para o pré-aquecimento da carga em função de reaproveitamento de calor de processo', label: 'Avaliar as possibilidades para o pré-aquecimento da carga em função de reaproveitamento de calor de processo' }
  , { id: 'Avaliar a viabilidade em função dos queimadores presentes e tipo de controle existente nestes', label: 'Avaliar a viabilidade em função dos queimadores presentes e tipo de controle existente nestes' }
  , { id: 'Avaliar a distribuição da carga em função do posicionamento dos queimadores/resistores para que a elevação de temperatura seja a mais uniforme possível', label: 'Avaliar a distribuição da carga em função do posicionamento dos queimadores/resistores para que a elevação de temperatura seja a mais uniforme possível' }
  , { id: 'Avaliação de coletores solares', label: 'Avaliação de coletores solares' }
  , { id: 'Avaliação de instalação de bombas de calor', label: 'Avaliação de instalação de bombas de calor' }
  , { id: 'Implementação de sistema de pré-aquecimento solar de água', label: 'Implementação de sistema de pré-aquecimento solar de água' }
  , { id: 'Instalação de sistemas de caldeiras a vapor mais eficientes', label: 'Instalação de sistemas de caldeiras a vapor mais eficientes' }
  , { id: 'Instalar VFD e sensor de O2 (oxigênio) em ventiladores de combustão e bombas de água de alimentação', label: 'Instalar VFD e sensor de O2 (oxigênio) em ventiladores de combustão e bombas de água de alimentação' }
  , { id: 'Implementação de recuperador de calor', label: 'Implementação de recuperador de calor' }
  , { id: 'Projetar e instalar economizador de calor', label: 'Projetar e instalar economizador de calor' }
  , { id: 'Instalação de economizador', label: 'Instalação de economizador' }
  , { id: 'Instalar isolação térmica adequada em trechos expostos', label: 'Instalar isolação térmica adequada em trechos expostos' }
  , { id: 'Redução e correção de vazamentos (perdas) nos processos de vapor', label: 'Redução e correção de vazamentos (perdas) nos processos de vapor' }
  , { id: 'Retirar caldeira de menor eficiência e priorizar máquinas mais eficientes', label: 'Retirar caldeira de menor eficiência e priorizar máquinas mais eficientes' }
  , { id: 'Implementar lógica de controle para controle de carga', label: 'Implementar lógica de controle para controle de carga' }
  , { id: 'Instalar válvulas de controle proporcional', label: 'Instalar válvulas de controle proporcional' }
  , { id: 'Projetar e instalar sistema para recuperação do condensado que permita o retorno à alimentação da caldeira', label: 'Projetar e instalar sistema para recuperação do condensado que permita o retorno à alimentação da caldeira' }
  , { id: 'Projetar sistema de recuperação de calor para geração de energia (cogeração tipo bottoming)', label: 'Projetar sistema de recuperação de calor para geração de energia (cogeração tipo bottoming)' }
  , { id: 'Projetar sistema de recuperação de calor para geração de energia (cogeração tipo topping)', label: 'Projetar sistema de recuperação de calor para geração de energia (cogeração tipo topping)' }
  , { id: 'Avaliar sistema de cogeração com chiller de absorção', label: 'Avaliar sistema de cogeração com chiller de absorção' }
  , { id: 'Possibilidade de retrofit do sistema de bombeamento', label: 'Possibilidade de retrofit do sistema de bombeamento' }
  , { id: 'Avaliar sistema de recuperação de calor para geração de energia (cogeração tipo topping)', label: 'Avaliar sistema de recuperação de calor para geração de energia (cogeração tipo topping)' }
  , { id: 'Possibilidade de retrofit da(s) torre(s) de resfriamento', label: 'Possibilidade de retrofit da(s) torre(s) de resfriamento' }
  , { id: 'Possibilidade de retrofit de fancoils ou AHUs', label: 'Possibilidade de retrofit de fancoils ou AHUs' }
  , { id: 'Reavaliar o projeto do sistema industrial e adquirir as componentes ou equipamentos que estejam faltando', label: 'Reavaliar o projeto do sistema industrial e adquirir as componentes ou equipamentos que estejam faltando' }
  , { id: 'Instalação de subcooler para aumento do resfriamento', label: 'Instalação de subcooler para aumento do resfriamento' }
  , { id: 'Instalação de desuperheater para melhorar o grau de saturação do fluido refrigerante', label: 'Instalação de desuperheater para melhorar o grau de saturação do fluido refrigerante' }
  , { id: 'Avaliação de trocador de calor', label: 'Avaliação de trocador de calor' }
  , { id: 'Mudanças dos parâmetros de refrigeração e Otimização da distribuição do frio', label: 'Mudanças dos parâmetros de refrigeração e Otimização da distribuição do frio' }
  , { id: 'Ajustar controles para operação otimizada dos compressores', label: 'Ajustar controles para operação otimizada dos compressores' }
  , { id: 'Implementação de PMOC', label: 'Implementação de PMOC' }
  , { id: 'Instalação de variadores de frequência (VFD)', label: 'Instalação de variadores de frequência (VFD)' }
  , { id: 'Estabelecer manutenção regular de limpeza dos filtros', label: 'Estabelecer manutenção regular de limpeza dos filtros' }
  , { id: 'Possibilidade de retrofit de fancoils ou AHUs', label: 'Possibilidade de retrofit de fancoils ou AHUs' }
  , { id: 'Reavaliar o projeto do sistema industrial e adquirir as componentes ou equipamentos que estejam faltando', label: 'Reavaliar o projeto do sistema industrial e adquirir as componentes ou equipamentos que estejam faltando' }
  , { id: 'Instalação de subcooler para aumento do resfriamento', label: 'Instalação de subcooler para aumento do resfriamento' }
  , { id: 'Instalação de desuperheater para melhorar o grau de saturação do fluido refrigerante', label: 'Instalação de desuperheater para melhorar o grau de saturação do fluido refrigerante' }
  , { id: 'Reavaliar o projeto do sistema industrial e adquirir as componentes ou equipamentos que estejam faltando', label: 'Reavaliar o projeto do sistema industrial e adquirir as componentes ou equipamentos que estejam faltando' }
  , { id: 'Instalação de subcooler para aumento do resfriamento', label: 'Instalação de subcooler para aumento do resfriamento' }
  , { id: 'Instalação de desuperheater para melhorar o grau de saturação do fluido refrigerante', label: 'Instalação de desuperheater para melhorar o grau de saturação do fluido refrigerante' }
  , { id: 'Avaliação de trocador de calor', label: 'Avaliação de trocador de calor' }
  , { id: 'Mudanças dos parâmetros de refrigeração e Otimização da distribuição do frio', label: 'Mudanças dos parâmetros de refrigeração e Otimização da distribuição do frio' }
  , { id: 'Ajustar controles para operação otimizada dos compressores', label: 'Ajustar controles para operação otimizada dos compressores' }
  , { id: 'Implementação de PMOC', label: 'Implementação de PMOC' }
  , { id: 'Instalação de variadores de frequência (VFD)', label: 'Instalação de variadores de frequência (VFD)' }
  , { id: 'Implementação de controlador IMS', label: 'Implementação de controlador IMS' }
  , { id: 'Instalação de sistemas para controle automático do processo de blow down (drenagem)', label: 'Instalação de sistemas para controle automático do processo de blow down (drenagem)' }
  , { id: 'Ajustes na distribuição de água das torres de resfriamento', label: 'Ajustes na distribuição de água das torres de resfriamento' }
];

export const estagiosFornecedor = [
  { cod: "inativo", descricao: 'INATIVO' },
  { cod: "analise", descricao: 'ANÁLISE' },
  { cod: "pendencia", descricao: 'PENDÊNCIA' },
  { cod: "ativo", descricao: 'ATIVO' }
];

export const MetasPorTecnologia = {
  Iluminacao: [
    { "opc": "Retrofit para LED com projeto luminotécnico" },
    { "opc": "Retrofit de lâmpadas LED por LED de maior eficiência e durabilidade" },
    { "opc": "Retrofit do projeto luminotécnico" },
    { "opc": "Alterações de envoltória para melhor aproveitamento da iluminação natural ou limpar janelas e telhas translúcidas" },
    { "opc": "Instalação de sensores e fotocélulas" },
    { "opc": "Estabelecer rotina regular de limpeza das lâmpadas e luminárias" },
    { "opc": "Proceder limpeza de superfícies e procurar alterar cores para melhorar refletência" },
    { "opc": "Reconfigurar ou instalar mais interruptores para melhor controle da iluminação nas áreas de trabalho" },
    { "opc": "Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)" },
    { "opc": "Realização de política de compras eficiente" },
  ],
  HVAC: [
    { "opc": "Retrofit por sistemas eficientes com inverter" },
    { "opc": "Sugerir que a PME verifique a implementação de PMOC" },
    { "opc": "Instalação de variadores de frequência (VFD)" },
    { "opc": "Implementação de controlador IMS" },
    { "opc": "Adequações hidráulicas nos dutos de distribuição e retorno" },
    { "opc": "Estabelecer manutenção regular de limpeza dos filtros" },
    { "opc": "Reparar ou refazer isolamento" },
    { "opc": "Limpar entradas e saídas, remover obstruções" },
    { "opc": "Ajustar termostato para 24°C a 26°C no inverno e 21°C a 23°C no verão" },
    { "opc": "Programar aferição de temperaturas retirando termostatos de áreas com muitas variações" },
    { "opc": "Instalar sistema para fechamento automático de portas" },
    { "opc": "Pintura da envoltória para cores claras para diminuição da absorção solar" },
    { "opc": "Instalação de elementos de envoltória para redução da carga térmica das áreas internas" },
    { "opc": "Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)" },
    { "opc": "Realização de política de compras eficiente" },
  ],
  Motores_Acionamentos: [
    { "opc": "Correções e ajustes no projeto mecânico" },
    { "opc": "Troca de motor DC para AC com VFD" },
    { "opc": "Troca de motores por novos corretamente dimensionados" },
    { "opc": "Instalação de variadores de frequência (VFD)" },
    { "opc": "Utilização de motores novos e de alta eficiência" },
    { "opc": "Implementação de sistema de monitoramento" },
    { "opc": "Adequações topológicas da rede de distribuição" },
    { "opc": "Desenvolvimento de um sistema automático de controle" },
    { "opc": "Melhorar a especificação do acoplamento ou trocar por acoplamento direto" },
    { "opc": "Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)" },
    { "opc": "Realização de política de compras eficiente" },
  ],
  Bombeamento: [
    { "opc": "Adequações topológicas da rede de distribuição" },
    { "opc": "Instalação de reservatórios e/ou etapas booster" },
    { "opc": "Desenvolvimento de um sistema automático de controle" },
    { "opc": "Troca de motores por novos corretamente dimensionados" },
    { "opc": "Instalação de variadores de frequência (VFD)" },
    { "opc": "Troca de motores antigos por novos de maior eficiência" },
    { "opc": "Implementação de sistema de monitoramento" },
    { "opc": "Melhorar a especificação do acoplamento ou trocar por acoplamento direto" },
    { "opc": "Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)" },
    { "opc": "Aquisição de medidores de vazão para os sistemas de bombeamento" },
    { "opc": "Realização de política de compras eficiente" },
  ],
  Ar_Comprimido: [
    { "opc": "Divisão da rede por nível de pressão (alta e baixa pressão)" },
    { "opc": "Instalação de secadores de alta eficiência com controle de pressão" },
    { "opc": "Instalação de equipamentos acessórios mais eficientes (p.ex., bicos aspersores, ferramentas pneumáticas)" },
    { "opc": "Instalar sistemas de limpeza sem ar comprimido (blowers)" },
    { "opc": "Redução da pressão de trabalho" },
    { "opc": "Melhoria do projeto hidráulico: diâmetros, curvas, acessórios, pulmões" },
    { "opc": "Ajuste dos parâmetros de operação dos compressores e entre compressores (p.ex., tempo de alívio, tempo de desligamento, número de acionamentos por período etc.)" },
    { "opc": "Instalação de compressores mais eficientes" },
    { "opc": "Instalação de pelo menos um compressor com controle VFD" },
    { "opc": "Implementação de controlador sequencial" },
    { "opc": "Implementação de recuperador de calor" },
    { "opc": "Caça de vazamentos" },
    { "opc": "Redução de vazamentos" },
    { "opc": "Aquisição de medidores e gabinetes de reparo de vazamentos" },
    { "opc": "Aquisição de manômetros para a linha de ar comprimido" },
    { "opc": "Melhorar a especificação do acoplamento ou trocar por acoplamento direto" },
    { "opc": "Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)" },
    { "opc": "Desobstrução das saídas de ar do compressor para melhoria de performance" },
    { "opc": "Trocar mangueiras que estejam danificadas ou desgastadas" },
    { "opc": "Otimização da rede: Separar esse uso final da linha principal de ar comprimido, adquirindo compressor próprio para atendê-lo" },
    { "opc": "Realizar estudo se o ar comprimido pode ser substituído por outra fonte mais eficiente para algum consumidor da fábrica" },
    { "opc": "Realização de política de compras eficiente" },
  ],
  Ventilacao_Exaustao: [
    { "opc": "Retrofit por sistemas eficientes e com o dimensionamento adequado" },
    { "opc": "Sugerir que a PME verifique a implementação de PMOC" },
    { "opc": "Alterações de envoltória para melhor aproveitamento da ventilação natural" },
    { "opc": "Troca de motor DC para AC com VFD" },
    { "opc": "Instalação de variadores de frequência (VFD)" },
    { "opc": "Troca de motores antigos por novos de maior eficiência" },
    { "opc": "Implementação de controlador IMS" },
    { "opc": "Implementar controle de vazão nos exaustores utilizando, por exemplo, VFD" },
    { "opc": "Melhorar a especificação do acoplamento ou trocar por acoplamento direto" },
    { "opc": "Instalar automação para realizar o autodesligamento do sistema (on/off, timer, switch)" },
    { "opc": "Realização de política de compras eficiente" },
  ],
  Refrigeracao: [
    { "opc": "Instalação de reservatório térmico de acumulação" },
    { "opc": "Possibilidade de substituição do(s) chiller(s)" },
    { "opc": "Avaliação de bombas de calor para geração de frio" },
    { "opc": "Avaliação da troca dos sistemas ou do fluido refrigerante dos sistemas atuais para fluidos com menores emissões GHG como fluidos naturais" },
    { "opc": "Avaliar sistema de cogeração com chiller de absorção" },
    { "opc": "Possibilidade de retrofit do sistema de bombeamento" },
    { "opc": "Possibilidade de retrofit da(s) torre(s) de resfriamento" },
    { "opc": "Possibilidade de retrofit de fancoils ou AHUs" },
    { "opc": "Reavaliar o projeto do sistema industrial e adquirir as componentes ou equipamentos que estejam faltando" },
    { "opc": "Instalação de subcooler para aumento do resfriamento" },
    { "opc": "Instalação de desuperheater para melhorar o grau de saturação do fluido refrigerante" },
    { "opc": "Mudanças dos parâmetros de refrigeração e Otimização da distribuição do frio" },
    { "opc": "Ajustar controles para operação otimizada dos compressores" },
    { "opc": "Implementação de PMOC" },
    { "opc": "Instalação de variadores de frequência (VFD)" },
    { "opc": "Implementação de controlador IMS" },
    { "opc": "Instalação de sistemas para controle automático do processo de blow down (drenagem)" },
    { "opc": "Ajustes na distribuição de água das torres de resfriamento" },
    { "opc": "Adequações hidráulicas nos dutos de distribuição e retorno" },
    { "opc": "Estabelecer manutenção regular de limpeza dos filtros" },
    { "opc": "Reparar ou refazer isolamento térmico" },
    { "opc": "Limpar entradas e saídas, remover obstruções e reduzir infiltrações" },
    { "opc": "Ajustar termostato para 24°C a 26°C no inverno e 21°C a 23°C no verão" },
    { "opc": "Programar aferição de temperaturas retirando termostatos de áreas com muitas variações" },
    { "opc": "Instalar sistema para fechamento automático de portas" },
    { "opc": "Pintura da envoltória para cores claras para diminuição da absorção solar" },
    { "opc": "Instalação ou melhoria de elementos de envoltória para redução da carga térmica das áreas internas e/ou de produção" },
    { "opc": "Realização de política de compras eficiente" },
  ],
  Fornos_Aquecedores: [
    { "opc": "Verificar possibilidade de operar com capacidade máxima" },
    { "opc": "Instalação de isolamento térmico" },
    { "opc": "Redimensionamento do isolamento térmico para atendimento à especificação em função da aplicação" },
    { "opc": "Manutenção do isolamento térmico" },
    { "opc": "Redução e correção de vazamentos (perdas) nos processos " },
    { "opc": "Avaliar as possibilidades de diminuição da temperatura dos gases de exaustão" },
    { "opc": "Avaliar as possibilidades para o pré-aquecimento da carga em função de reaproveitamento de calor de processo" },
    { "opc": "Avaliar a viabilidade em função dos queimadores presentes e tipo de controle existente nestes" },
    { "opc": "Avaliar a distribuição da carga em função do posicionamento dos queimadores/resistores para que a elevação de temperatura seja a mais uniforme possível" },
    { "opc": "Avaliação de coletores solares" },
    { "opc": "Implementação de recuperador de calor" },
    { "opc": "Instalação de economizador" },
    { "opc": "Redimensionamento ou troca do equipamento para atender à demanda de calor requerida na atualidade" },
    { "opc": "Redimensionamento ou troca do equipamento para novo energético" },
    { "opc": "Projetar sistema de recuperação de calor para geração de energia (cogeração tipo bottoming)" },
    { "opc": "Projetar sistema de recuperação de calor para geração de energia (cogeração tipo topping)" },
    { "opc": "Avaliar retrofit ou troca dos queimadores por outros de maior eficiência" },
    { "opc": "Avaliação de reservatórios térmicos" },
    { "opc": "Avaliação de trocador de calor" },
  ],
  Oportunidade_de_substituição_da_caldeira_por_outros_sistemas: [
    { "opc": "Avaliação de instalação de bombas de calor" },
    { "opc": "Implementação de sistema de pré-aquecimento solar de água" },
    { "opc": "Projetar sistema de recuperação de calor para geração de energia (cogeração tipo bottoming)" },
    { "opc": "Avaliar sistema de recuperação de calor para geração de energia (cogeração tipo topping)" },
  ],
  Exclusivamente_Caldeiras: [
    { "opc": "Instalação de sistemas de caldeiras a vapor mais eficientes" },
    { "opc": "Instalar VFD e sensor de O2 (oxigênio) em ventiladores de combustão e bombas de água de alimentação" },
    { "opc": "Projetar e instalar economizador de calor" },
    { "opc": "Instalação de isolamento térmico nas caldeiras" },
    { "opc": "Redimensionamento do isolamento térmico para atendimento à especificação em função da aplicação" },
    { "opc": "Manutenção do isolamento das caldeiras" },
    { "opc": "Projetar e instalar pré-aquecedor de ar" },
    { "opc": "Troca das caldeiras a combustivel de baixa emissão ou eletrificação" },
  ],
  Distribuição_de_vapor: [
    { "opc": "Redimensionamento da rede de vapor para atender à demanda em pressão, temperatura e vapor requerida" },
    { "opc": "Reduzir setpoint de pressão de saida da caldeira OU Instalar caldeira de pequeno porte para atender cargas menores de alta pressão" },
    { "opc": "Projetar e instalar sistema para recuperação do condensado que permita o retorno à alimentação da caldeira" },
    { "opc": "Verificar a possibilidade de eliminar vazamentos ou a substituição dos equipamentos de bombeamentos por sistemas novos e com capacidade de controle de vazão e pressão em cargas parciais" },
  ],
  Comuns_a_Caldeira_uso_e_distribuição_de_vapor: [
    { "opc": "Implementar lógica de controle para controle de carga" },
    { "opc": "Instalar válvulas de controle proporcional" },
  ],
  Uso_de_vapor: [
    { "opc": "Retirar caldeira de menor eficiência e priorizar máquinas mais eficientes" },
    { "opc": "Redução da carga térmica do processo (p.ex., ajuste dos tempos de ciclo do equipamento e redução da temperatura da água ou vapor de processo)" },
    { "opc": "Instalar isolação térmica adequada em trechos expostos" },
    { "opc": "Redução e correção de vazamentos (perdas) nos processos de vapor" },
  ]
};
