import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  CircularProgress,
  Paper,
  Grid,
  Checkbox as MUICheckbox,
  Typography,
  Divider
} from '@mui/material';
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  TextField,
  Select,
  Checkbox,
  CNPJField,
  Autocomplete,
  TelField,
  CEPField,
  SelectUF,
  SelectCidade,
  DialogoVisualizador,
  DialogoConfirmacao,
  Radio,
} from '../../components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { constituicaoEmpresaFornecedor, opcoesBooleanas, estagiosFornecedor } from '../../static';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogoFornecedor = ({
  open, onClose, fornecedor
}) => {
  let user = useStoreState((state) => state.auth.user);
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [tecnologias, setTecnologias] = React.useState([]);
  const [regioes, setRegioes] = React.useState([]);
  const [estados, setEstados] = React.useState([]);
  const [categorias, setCategorias] = React.useState([]);
  const [fileVisualizador, setFileVisualizador] = React.useState();
  const [openDlgVis, setOpenDlgVis] = React.useState(false);
  const [openDlgJustificativa, setOpenDlgJustificativa] = React.useState(false);

  const [dlgJustTitulo, setDlgJustTitulo] = React.useState("");
  const [dlgJustNomeCampoAprovado, setDlgJustNomeCampoAprovado] = React.useState("");  //(nomeCampoAprovado);
  const [dlgJustNomeCampoJustificativa, setDlgJustNomeCampoJustificativa] = React.useState("");  //(nomeCampoJustificativa);
  const [dlgJustFornecedorId, setDlgJustFornecedorId] = React.useState("");  //(fornecedorId);
  const [statusBotao, setStatusBotao] = React.useState('');
  const [botaoAprovar, setBotaoAprovar] = React.useState(false);
  const [botaoReprovar, setBotaoReprovar] = React.useState(false);
  const [analisandoDocumentos, setAnalisandoDocumentos] = React.useState(false);

  const defaultValues = {
    nome: '',
    responsavel: '',
    email: '',
    cnpj: '',
    habilitado: true,
    regioes: [],
    tecnologias: []
  };
  const schema = yup.object({
    nome: yup.string().label('Nome da empresa'),
    responsavel: yup.string().label('Nome do responsável'),
    email: yup.string().email().label('Email do responsável'),
    cnpj: yup.string().cnpj().label('CNPJ'),
    tecnologias: yup.array(yup.number().positive().integer())
      .min(1).nullable().label('Tecnologia'),
    regioes: yup.array(yup.number().positive().integer())
      .min(1).nullable().label('Região'),
    atendeEstados: yup.array(yup.number().positive().integer())
      .min(1).nullable().label('Em quais estados a empresa deseja atuar?'),
    emailAux: yup.string().email().label("E-mail secundário"),
    telefone: yup.string().label("Telefone"),
    cep: yup.string().nullable().label("CEP"),
    rua: yup.string().nullable().label("Rua"),
    numero: yup.string().nullable().label("Número"),
    bairro: yup.string().nullable().label("Bairro"),
    cidade: yup.string().nullable().label("Cidade"),
    uf: yup.string().nullable().label("Estado"),

    atuaEsco: yup.boolean().nullable().label('A empresa atua como uma ESCO?'),
    atuaBot: yup.boolean().nullable().label('A empresa atua na modalidade de BOT?'),
    constituicaoEmpresa: yup.number().nullable().label('Qual o porte da empresa?'),
    idDocumento: yup.number().positive().nullable().label('Selecionar').optional(),

    observacoes: yup.string().nullable().label("Observações"),

    actAprovado: yup.number().nullable().label("Documento aprovado"),
    actJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    catAprovado: yup.number().nullable().label("Documento aprovado"),
    catJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    declaracaoFabricanteAprovado: yup.number().nullable().label("Documento aprovado"),
    declaracaoFabricanteJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    certidaoCreaAprovado: yup.number().nullable().label("Documento aprovado"),
    certidaoCreaJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    pedidoPublicoAnexo1Aprovado: yup.number().nullable().label("Documento aprovado"),
    pedidoPublicoAnexo1Justificativa: yup.string().nullable().label('Justificativa Reprovação'),

    outrasInformacoesAnexo9Aprovado: yup.number().nullable().label("Documento aprovado"),
    outrasInformacoesAnexo9Justificativa: yup.string().nullable().label('Justificativa Reprovação'),

    documentosMeiAprovado: yup.number().nullable().label("Documento aprovado"),
    documentosMeiJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    atoSociedadeSimplesAprovado: yup.number().nullable().label("Documento aprovado"),
    atoSociedadeSimplesJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    atoSociedadeAcoesAprovado: yup.number().nullable().label("Documento aprovado"),
    atoSociedadeAcoesJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    negativaFalenciaAprovado: yup.number().nullable().label("Documento aprovado"),
    negativaFalenciaJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    certidaoVirgenteAprovado: yup.number().nullable().label("Documento aprovado"),
    certidaoVirgenteJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    balancoPatrimonialAprovado: yup.number().nullable().label("Documento aprovado"),
    balancoPatrimonialJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    provaInscCnpjAprovado: yup.number().nullable().label("Documento aprovado"),
    provaInscCnpjJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    cadastroContribuinteMunicipalAprovado: yup.number().nullable().label("Documento aprovado"),
    cadastroContribuinteMunicipalJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    cadastroContribuinteEstadualAprovado: yup.number().nullable().label("Documento aprovado"),
    cadastroContribuinteEstadualJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeFazendaNacionalAprovado: yup.number().nullable().label("Documento aprovado"),
    regularidadeFazendaNacionalJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeFazendaEstadualAprovado: yup.number().nullable().label("Documento aprovado"),
    regularidadeFazendaEstadualJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeFazendaMunicipalAprovado: yup.number().nullable().label("Documento aprovado"),
    regularidadeFazendaMunicipalJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeFgtsAprovado: yup.number().nullable().label("Documento aprovado"),
    regularidadeFgtsJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeJusticaTrabalhoAprovado: yup.number().nullable().label("Documento aprovado"),
    regularidadeJusticaTrabalhoJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    habilitado: yup.boolean().label('Habilitado')
  }).required();

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { watch } = useFormRef;

  const watchActAprovado = watch("actAprovado");


  // load categorias, tecnologia e regiões
  React.useEffect(async () => {
    let mount = {
      isMounted: true
    };
    loadCategorias(mount);
    loadTecnologias(mount);
    loadRegioes(mount);
    loadEstados(mount);
    setStatusBotao(fornecedor?.estagioFornecedor)
    return () => { mount.isMounted = false; };
  }, []);

  const loadEstados = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/estados')).data;
      if (mount.isMounted) {
        setEstados(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({ message: error.toString(), severity: 'error' });
    }
  };

  const loadCategorias = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/categorias')).data;
      if (mount.isMounted) {
        setCategorias(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  // load tecnologias
  const loadTecnologias = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/tecnologias')).data;
      if (mount.isMounted) {
        setTecnologias(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  // load regiões
  const loadRegioes = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/regioes')).data;
      if (mount.isMounted) {
        setRegioes(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  // const loadEstados = async (mount) => {
  //   startLoading();
  //   try {
  //     let data = (await api.http.get('/estados')).data;
  //     console.log("data: ", data);
  //     if (mount.isMounted) {
  //       const sortedStates = data.sort((a, b) => {
  //         return a.nome.localeCompare(b.nome); // Comparando os nomes dos estados
  //       });

  //       setStates(sortedStates);
  //     }
  //     stopLoading();
  //   } catch (error) {
  //     stopLoading({ message: error.toString(), severity: 'error' });
  //   }
  // };

  const { handleSubmit, reset, formState: { isDirty } } = useFormRef;

  const onSave = async (data) => {
    startLoading();
    try {
      if (data.id) {
        await api.http.put('/fornecedores/documentos/' + data.id, data);
      } else {
        //await api.http.post('/fornecedores', data);

      }
      stopLoading({ severity: 'success' });
      // const resultado = exibeBotao(data);
      // setStatusBotao(resultado);
      // if (resultado === 2) {
      close(true);
      // }
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const disabled = (() => {
    if (fornecedor.estagioFornecedor === "analise" && fornecedor.analisadorId)
      return true
    else return false;
  });


  const close = (reload = false) => {
    reset(defaultValues);
    onClose(reload);
  };

  React.useEffect(() => {
    if (fornecedor) {
      reset({
        ...fornecedor,
        regioes: fornecedor.regiao.map(regiao => regiao.id),
        tecnologias: fornecedor.tecnologia.map(tecnologia => tecnologia.id),
        atendeEstados: fornecedor.estado.map(estado => estado.id)
      });

      let todosrespondidos = false;
      let algumpendente = false;

      if (
        fornecedor.actAprovado &&
        fornecedor.catAprovado &&
        fornecedor.declaracaoFabricanteAprovado &&
        fornecedor.certidaoCreaAprovado &&
        fornecedor.pedidoPublicoAnexo1Aprovado &&
        fornecedor.outrasInformacoesAnexo9Aprovado &&
        (fornecedor.documentosMeiAprovado ||
          fornecedor.atoSociedadeSimplesAprovado ||
          fornecedor.atoSociedadeAcoesAprovado) &&
        fornecedor.negativaFalenciaAprovado &&
        fornecedor.certidaoVirgenteAprovado &&
        fornecedor.balancoPatrimonialAprovado &&
        fornecedor.provaInscCnpjAprovado &&
        fornecedor.cadastroContribuinteMunicipalAprovado &&
        fornecedor.cadastroContribuinteEstadualAprovado &&
        fornecedor.regularidadeFazendaNacionalAprovado &&
        fornecedor.regularidadeFazendaEstadualAprovado &&
        fornecedor.regularidadeFazendaMunicipalAprovado &&
        fornecedor.regularidadeFgtsAprovado &&
        fornecedor.regularidadeJusticaTrabalhoAprovado
      ) { todosrespondidos = true; }

      if (
        fornecedor.actAprovado === 2 ||
        fornecedor.catAprovado === 2 ||
        fornecedor.declaracaoFabricanteAprovado === 2 ||
        fornecedor.certidaoCreaAprovado === 2 ||
        fornecedor.pedidoPublicoAnexo1Aprovado === 2 ||
        fornecedor.outrasInformacoesAnexo9Aprovado === 2 ||
        (fornecedor.documentosMeiAprovado === 2 ||
          fornecedor.atoSociedadeSimplesAprovado === 2 ||
          fornecedor.atoSociedadeAcoesAprovado === 2) ||
        fornecedor.negativaFalenciaAprovado === 2 ||
        fornecedor.certidaoVirgenteAprovado === 2 ||
        fornecedor.balancoPatrimonialAprovado === 2 ||
        fornecedor.provaInscCnpjAprovado === 2 ||
        fornecedor.cadastroContribuinteMunicipalAprovado === 2 ||
        fornecedor.cadastroContribuinteEstadualAprovado === 2 ||
        fornecedor.regularidadeFazendaNacionalAprovado === 2 ||
        fornecedor.regularidadeFazendaEstadualAprovado === 2 ||
        fornecedor.regularidadeFazendaMunicipalAprovado === 2 ||
        fornecedor.regularidadeFgtsAprovado === 2 ||
        fornecedor.regularidadeJusticaTrabalhoAprovado === 2
      ) { algumpendente = true; }

      console.log("todos respondidos: ", todosrespondidos);
      console.log("algum pendente: ", algumpendente);

      setBotaoAprovar(todosrespondidos && !algumpendente);
      setBotaoReprovar(todosrespondidos && algumpendente);

    }
  }, [fornecedor]);

  React.useEffect(() => {
    if (
      (fornecedor?.estagioFornecedor === 'analise' && fornecedor?.analisadorId)
      || fornecedor?.estagioFornecedor === 'pendente'
      || fornecedor?.estagioFornecedor === 'ativo'
    ) { setAnalisandoDocumentos(true); }
  }, [fornecedor])

  const onConfirmaDlgJustificativa = async (data) => {
    //confirmar a justificativa
    // console.log("confirmar justificativa")

    startLoading();
    try {

      // console.log("data: ", data);

      let datar = (await api.http.put('/fornecedores/document/reprove/' + dlgJustFornecedorId, {

        id: dlgJustFornecedorId,
        nomeCampo: dlgJustNomeCampoAprovado,
        nomeCampoJustificativa: dlgJustNomeCampoJustificativa,
        justificativa: data.observacoes,
      })).data;

      // setFornecedor(data[0]);

      stopLoading({ severity: 'success' });
      // setOpenConfirmaExcluir(false);

    } catch (error) {
      stopLoading({ message: error.toString(), severity: 'error' });
      // setOpenConfirmaExcluir(false);
      throw error;
    }

    setOpenDlgJustificativa(false);
  };

  const onNaoConfirmaDlgJustificativa = () => {
    setOpenDlgJustificativa(false);
  };

  const onOpenDlgVis = (param1, param2, param3) => {
    setFileVisualizador(param1);
    setOpenDlgVis(true);
  };

  const onCloseDlgVis = () => {
    setOpenDlgVis(false);
  };

  const onAprova = async () => {
    console.log("aprovar");
    startLoading();
    try {

      let data = (await api.http.put('/fornecedores/orcamentos/alterarestagio/' + fornecedor.id, {
        estagioFornecedor: "ativo"
      })).data;

      stopLoading({ severity: 'success' });
      close(true);

    } catch (error) {
      stopLoading({ message: error.toString(), severity: 'error' });
      throw error;
    }
  }

  const onPendencia = async () => {
    console.log("pendencia");
    startLoading();
    try {

      let data = (await api.http.put('/fornecedores/orcamentos/alterarestagio/' + fornecedor.id, {
        estagioFornecedor: "pendencia"
      })).data;

      stopLoading({ severity: 'success' });
      close(true);
    } catch (error) {
      stopLoading({ message: error.toString(), severity: 'error' });
      throw error;
    }
  }

  const onReprove = async () => {
    console.log("reprovado");
    startLoading();
    try {

      let data = (await api.http.put('/fornecedores/orcamentos/alterarestagio/' + fornecedor.id, {
        estagioFornecedor: "inativo"
      })).data;

      stopLoading({ severity: 'success' });
      close(true);
    } catch (error) {
      stopLoading({ message: error.toString(), severity: 'error' });
      throw error;
    }
  }

  const handleIniciarAnalise = async () => {
    startLoading();
    try {

      let data = (await api.http.put(`/fornecedores/orcamentos/usuario/iniciar/analise/${fornecedor?.id}/${user.id}`, {
        usuarioId: user.id
      })).data;

      stopLoading({ severity: 'success' });
      close(true);
    } catch (error) {
      stopLoading({ message: error.toString(), severity: 'error' });
      throw error;
    }
  };

  // Função para renderizar o botão de iniciar análise
  const renderIniciarAnaliseButton = () => {
    if (fornecedor?.estagioFornecedor === "analise" && !fornecedor?.analisadorId) {
      return (
        <Button color="success" onClick={handleIniciarAnalise}>
          INICIAR ANÁLISE
        </Button>
      );
    }
    return null;
  };

  // Função para renderizar o botão de salvar
  const renderSalvarButton = () => {
    if (fornecedor?.estagioFornecedor === "analise" && fornecedor?.analisadorId === user.id) {
      return (
        <Button
          color="primary"
          onClick={handleSubmit(onSave)}
          disabled={!isDirty}
        >
          SALVAR
        </Button>
      );
    }
    return null;
  };

  // Função para renderizar o botão de aprovar
  const renderAprovarButton = () => {
    if (botaoAprovar && fornecedor?.analisado) {
      return (
        <Button
          color="success"
          variant="outlined"
          onClick={handleSubmit(onAprova)}
        >
          APROVAR
        </Button>
      );
    }
    return null;
  };

  // Função para renderizar os botões de pendência e reprovar
  const renderReprovarButtons = () => {
    if (botaoReprovar && fornecedor?.analisado) {
      return (
        <>
          <Button
            color="warning"
            variant="outlined"
            onClick={handleSubmit(onPendencia)}
          >
            PENDÊNCIA
          </Button>
          <Button
            color="error"
            variant="outlined"
            onClick={handleSubmit(onReprove)}
          >
            REPROVAR
          </Button>
        </>
      );
    }
    return null;
  };

  const getDescricaoEstagio = (cod) => {
    const estagio = estagiosFornecedor.find((e) => e.cod === cod);
    return estagio ? estagio.descricao : "Desconhecido";
  };

  //console.log("fornecedor: ", fornecedor);

  // console.log("watch('actAprovado'): ", watch('actAprovado'));
  // console.log("watch('certidaoCreaAprovado'): ", watch('certidaoCreaAprovado'));

  console.log("botao aprovar : ", botaoAprovar);
  console.log("botao reporvar: ", botaoReprovar);

  return (
    <>
      <DialogoVisualizador file={fileVisualizador}
        open={openDlgVis}
        onClose={() => onCloseDlgVis()} />

      <DialogoConfirmacao
        title="Atenção"
        open={openDlgJustificativa}
        onSim={onConfirmaDlgJustificativa}
        onNao={onNaoConfirmaDlgJustificativa}
      >
        <h5>{dlgJustTitulo}</h5>
        Descreva aqui a justificativa para a não aprovar o documento.
      </DialogoConfirmacao>

      <Dialog open={open} onClose={() => close()} fullWidth
        maxWidth="lg" sx={{ '& .MuiDialog-paper': { width: '80%' } }}>
        <DialogTitle>
          <Typography variant="body1"><b>Dados do fornecedor : </b>
            {fornecedor?.nome} - <i>{getDescricaoEstagio(fornecedor?.estagioFornecedor)}</i>
          </Typography>
        </DialogTitle >
        <DialogContent dividers>
          <FormControl sx={{ my: 2 }} fullWidth>
            <CNPJField
              margin="dense"
              fullWidth
              field="cnpj"
              useFormRef={useFormRef}
              schema={schema}
              disabled={true}
              InputLabelProps={{ shrink: true }} />
          </FormControl>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              field="nome"
              disabled={true}
              useFormRef={useFormRef} schema={schema} />
          </FormControl>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              disabled={true}
              field="responsavel"
              useFormRef={useFormRef} schema={schema} />
          </FormControl>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              field="email"
              type="email"
              disabled={true}
              useFormRef={useFormRef} schema={schema} />
          </FormControl>
          <FormControl sx={{ my: 1 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              field="emailAux"
              type="email"
              useFormRef={useFormRef}
              disabled={true}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 1 }} fullWidth>
            <TelField
              field="telefone"
              disabled={true}
              useFormRef={useFormRef}
              schema={schema}
              InputLabelProps={{ shrink: true }} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <CEPField
              field="cep"
              disabled={true}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>


          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="rua"
              disabled={true}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="numero"
              disabled={true}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="bairro"
              disabled={true}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="uf"
              disabled={true}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>


          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="cidade"
              disabled={true}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <Autocomplete
              multiple
              disableCloseOnSelect
              field="tecnologias"
              useFormRef={useFormRef}
              schema={schema}
              options={tecnologias}
              disabled={true}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <MUICheckbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected} />
                  {props.key}
                </li>
              )}
              groupBy={(option) => 'Categoria ' +
                option.categoria
                + ': ' +
                categorias.filter((categoria) => categoria.id == option.categoria)
                  .map((label) => label.nome)} />
          </FormControl>
          <FormControl sx={{ my: 2 }} fullWidth>
            <Autocomplete
              multiple
              disableCloseOnSelect
              field="regioes"
              useFormRef={useFormRef}
              schema={schema}
              options={regioes}
              disabled={true}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <MUICheckbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected} />
                  {props.key}
                </li>
              )} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <Autocomplete
              multiple
              disableCloseOnSelect
              field="atendeEstados"
              useFormRef={useFormRef}
              schema={schema}
              disabled={true}
              options={estados}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <MUICheckbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {props.key}
                </li>
              )}
            />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <Checkbox field="atuaEsco" disabled={true}
              useFormRef={useFormRef} schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <Checkbox field="atuaBot" disabled={true}
              useFormRef={useFormRef} schema={schema} />
          </FormControl>

          <FormControl x={{ mb: 2 }} fullWidth>
            <Select
              field="constituicaoEmpresa" disabled={true}
              useFormRef={useFormRef} schema={schema}
              options={constituicaoEmpresaFornecedor} />
          </FormControl>
          {analisandoDocumentos && (
            <>
              <div className={`conteudo ${analisandoDocumentos ? 'visivel' : 'oculto'}`}>
                <Divider>
                  <h3>Documentação Técnica</h3>
                  <h3>Qualificação Técnica</h3>
                </Divider>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">1. ACT</Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>
                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          startIcon={<ArticleOutlinedIcon />}
                          onClick={() => onOpenDlgVis(fornecedor?.arquivoAct)}
                        >
                          Visualizar
                        </Button>
                      )}
                    </Grid>
                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>
                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>
                            <Select
                              field="actAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={7}>
                          <FormControl sx={{ my: 2 }} fullWidth>
                            {watch('actAprovado') === 2 && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="actJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>






                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box  
                }}>
                  <Typography variant="body1">2. CAT</Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivoCat)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>

                          <FormControl sx={{ my: 2 }} fullWidth>
                            <Select
                              field="catAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />
                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('catAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="catJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}
                          </FormControl>
                        </Grid>
                      </>
                    )}

                  </Grid>

                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    3. Declaração do fabricante
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivodeclaracaoFabricanteId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>

                          <FormControl sx={{ my: 2 }} fullWidth>
                            <Select
                              field="declaracaoFabricanteAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />
                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>
                            {(watch('declaracaoFabricanteAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="declaracaoFabricanteJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}
                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    4. Certidão de Registro - CREA Pessoa Juridica
                  </Typography>

                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivocertidaoCreaId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>
                            <Select
                              field="certidaoCreaAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />
                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>
                            {(watch('certidaoCreaAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="certidaoCreaJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}
                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Divider>
                  <h3>Documentação habilitação</h3>
                  <h3>Qualificação Técnica</h3>
                </Divider>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box  
                }}>
                  <Typography variant="body1">
                    5. Pedido Público (Anexo I)
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivopedidoPublicoAnexo1Id)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>
                            <Select
                              field="pedidoPublicoAnexo1Aprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />
                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>
                            {(watch('pedidoPublicoAnexo1Aprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="pedidoPublicoAnexo1Justificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}
                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    6. Declaração de outras informações (Anexo IX)
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivooutrasInformacoesAnexo9)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>
                            <Select
                              field="outrasInformacoesAnexo9Aprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />
                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>
                            {(watch('outrasInformacoesAnexo9Aprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="outrasInformacoesAnexo9Justificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}
                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                {fornecedor?.constituicaoEmpresa === 1 && (
                  <Box sx={{
                    backgroundColor: 'white',
                    borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                    marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                    marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                    padding: 2 // Opcional: adiciona padding dentro do Box  
                  }}>
                    <Typography variant="body1">
                      7. Documentos - MEI
                    </Typography>
                    <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                      <Grid item xs={2}>

                        {fornecedor?.estagioFornecedor !== "inativo" && (
                          <Button variant="outlined"
                            color="secondary"
                            onClick={() => onOpenDlgVis(fornecedor.arquivodocumentosMeiId)}
                            startIcon={<ArticleOutlinedIcon />}>
                            Visualizar
                          </Button>)}

                      </Grid>

                      {fornecedor?.estagioFornecedor === "analise" && (
                        <>

                          <Grid item xs={3}>
                            <FormControl sx={{ my: 2 }} fullWidth>

                              <Select
                                field="documentosMeiAprovado"
                                useFormRef={useFormRef}
                                schema={schema}
                                options={opcoesBooleanas}
                              />

                            </FormControl>

                          </Grid>

                          <Grid item xs={7}>

                            <FormControl sx={{ my: 2 }} fullWidth>
                              {(watch('documentosMeiAprovado') === 2) && (
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  field="documentosMeiJustificativa"
                                  useFormRef={useFormRef} schema={schema} />
                              )}
                            </FormControl>

                          </Grid>
                        </>
                      )}

                    </Grid>
                  </Box>
                )}

                {fornecedor?.constituicaoEmpresa === 2 && (
                  <Box sx={{
                    backgroundColor: 'white',
                    borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                    marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                    marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                    padding: 2 // Opcional: adiciona padding dentro do Box 
                  }}>
                    <Typography variant="body1">
                      7. Ato Constitutivo - Sociedades Simples
                    </Typography>
                    <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                      <Grid item xs={2}>

                        {fornecedor?.estagioFornecedor !== "inativo" && (
                          <Button variant="outlined"
                            color="secondary"
                            onClick={() => onOpenDlgVis(fornecedor.arquivoatoSociedadeSimplesId)}
                            startIcon={<ArticleOutlinedIcon />}>
                            Visualizar
                          </Button>)}

                      </Grid>

                      {fornecedor?.estagioFornecedor === "analise" && (
                        <>

                          <Grid item xs={3}>
                            <FormControl sx={{ my: 2 }} fullWidth>

                              <Select
                                field="atoSociedadeSimplesAprovado"
                                useFormRef={useFormRef}
                                schema={schema}
                                options={opcoesBooleanas}
                              />

                            </FormControl>

                          </Grid>

                          <Grid item xs={7}>

                            <FormControl sx={{ my: 2 }} fullWidth>
                              {(watch('atoSociedadeSimplesAprovado') === 2) && (
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  field="atoSociedadeSimplesJustificativa"
                                  useFormRef={useFormRef} schema={schema} />
                              )}
                            </FormControl>

                          </Grid>
                        </>
                      )}

                    </Grid>
                  </Box>
                )}

                {fornecedor?.constituicaoEmpresa === 3 && (
                  <Box sx={{
                    backgroundColor: 'white',
                    borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                    marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                    marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                    padding: 2 // Opcional: adiciona padding dentro do Box 
                  }}>
                    <Typography variant="body1">
                      7. Ato Constitutivo - Sociedades por Ações
                    </Typography>
                    <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                      <Grid item xs={2}>

                        {fornecedor?.estagioFornecedor !== "inativo" && (
                          <Button variant="outlined"
                            color="secondary"
                            onClick={() => onOpenDlgVis(fornecedor.arquivoatoSociedadeAcoesId)}
                            startIcon={<ArticleOutlinedIcon />}>
                            Visualizar
                          </Button>)}

                      </Grid>

                      {fornecedor?.estagioFornecedor === "analise" && (
                        <>

                          <Grid item xs={3}>
                            <FormControl sx={{ my: 2 }} fullWidth>

                              <Select
                                field="atoSociedadeAcoesAprovado"
                                useFormRef={useFormRef}
                                schema={schema}
                                options={opcoesBooleanas}
                              />

                            </FormControl>

                          </Grid>

                          <Grid item xs={7}>

                            <FormControl sx={{ my: 2 }} fullWidth>
                              {(watch('atoSociedadeAcoesAprovado') === 2) && (
                                <TextField
                                  margin="dense"
                                  fullWidth
                                  field="atoSociedadeAcoesJustificativa"
                                  useFormRef={useFormRef} schema={schema} />
                              )}
                            </FormControl>

                          </Grid>
                        </>
                      )}

                    </Grid>
                  </Box>
                )}

                <Divider>
                  <h3>Qualificação Econômico-Financeira</h3>
                </Divider>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    8. Certidão Negativa Falência ou Certidão Judicial - não superior a 90 dias
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivonegativaFalenciaId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="negativaFalenciaAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>
                            {(watch('negativaFalenciaAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="negativaFalenciaJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}
                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    9. Certidão vigente emitida pela instância Judicial
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivocertidaoVirgenteId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="certidaoVirgenteAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('certidaoVirgenteAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="certidaoVirgenteJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    10. Balanço Patrimonial e Demonst. contábeis
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivobalancoPatrimonialId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="balancoPatrimonialAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('balancoPatrimonialAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="balancoPatrimonialJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Divider>
                  <h3>Regularidade Fiscal</h3>
                </Divider>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    11. Prova de Inscrição - CNPJ
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivoprovaInscCnpjId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="provaInscCnpjAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('provaInscCnpjAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="provaInscCnpjJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box  
                }}>
                  <Typography variant="body1">
                    12. Cadastro de Contribuinte Municipal
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivocadastroContribuinteMunicipalId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="cadastroContribuinteMunicipalAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('cadastroContribuinteMunicipalAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="cadastroContribuinteMunicipalJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box  
                }}>
                  <Typography variant="body1">
                    13. Cadastro de Contribuinte Estadual
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivocadastroContribuinteEstadualId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="cadastroContribuinteEstadualAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('cadastroContribuinteEstadualAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="cadastroContribuinteEstadualJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box  
                }}>
                  <Typography variant="body1">
                    14. Prova de Regularidade - Fazenda Nacional
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined"
                          color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFazendaNacionalId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="regularidadeFazendaNacionalAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('regularidadeFazendaNacionalAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="regularidadeFazendaNacionalJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box  
                }}>
                  <Typography variant="body1">
                    15. Prova de Regularidade - Fazenda Estadual
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined" color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFazendaEstadualId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="regularidadeFazendaEstadualAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('regularidadeFazendaEstadualAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="regularidadeFazendaEstadualJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    16. Prova de Regularidade - Fazenda Municipal
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined" color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFazendaMunicipalId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="regularidadeFazendaMunicipalAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('regularidadeFazendaMunicipalAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="regularidadeFazendaMunicipalJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    17. Prova de Regularidade - FGTS
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined" color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFgtsId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="regularidadeFgtsAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('regularidadeFgtsAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="regularidadeFgtsJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>

                <Box sx={{
                  backgroundColor: 'white',
                  borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
                  marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
                  marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
                  padding: 2 // Opcional: adiciona padding dentro do Box 
                }}>
                  <Typography variant="body1">
                    18. Prova de Regularidade – Justiça do Trabalho
                  </Typography>
                  <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                    <Grid item xs={2}>

                      {fornecedor?.estagioFornecedor !== "inativo" && (
                        <Button variant="outlined" color="secondary"
                          onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeJusticaTrabalhoId)}
                          startIcon={<ArticleOutlinedIcon />}>
                          Visualizar
                        </Button>)}

                    </Grid>

                    {fornecedor?.estagioFornecedor === "analise" && (
                      <>

                        <Grid item xs={3}>
                          <FormControl sx={{ my: 2 }} fullWidth>

                            <Select
                              field="regularidadeJusticaTrabalhoAprovado"
                              useFormRef={useFormRef}
                              schema={schema}
                              options={opcoesBooleanas}
                            />

                          </FormControl>

                        </Grid>

                        <Grid item xs={7}>

                          <FormControl sx={{ my: 2 }} fullWidth>

                            {(watch('regularidadeJusticaTrabalhoAprovado') === 2) && (
                              <TextField
                                margin="dense"
                                fullWidth
                                field="regularidadeJusticaTrabalhoJustificativa"
                                useFormRef={useFormRef} schema={schema} />
                            )}

                          </FormControl>

                        </Grid>
                      </>
                    )}

                  </Grid>
                </Box>
              </div>
            </>
          )}
          {/* <FormControl sx={{ my: 2 }} fullWidth>
            <Checkbox field="habilitado"
              useFormRef={useFormRef} schema={schema} />
          </FormControl> */}
        </DialogContent >
        <DialogActions>
          {loading > 0 && <CircularProgress
            size={30}
            sx={{
              'position': 'absolute',
              'left': 25,
              'bottom': 12
            }} />}
          <Button color="inherit" onClick={() => close()}>
            CANCELAR
          </Button>

          {renderIniciarAnaliseButton()}
          {renderSalvarButton()}
          {renderAprovarButton()}
          {renderReprovarButtons()}

          {/* {
            (fornecedor?.estagioFornecedor === "analise"
              && !fornecedor?.analisadorId) && (
              <Button color='success' onClick={() => handleIniciarAnalise()}>
                INICIAR ANÁLISE
              </Button>
            )}


          {(fornecedor?.estagioFornecedor === "analise"
            && fornecedor?.analisadorId === user.id
          ) && (
              <Button color="primary"
                onClick={handleSubmit(onSave)} disabled={!isDirty}>
                SALVAR
              </Button>
            )}

          {botaoAprovar && fornecedor?.analisado
            && (
              <Button color="success"
                variant="outlined"
                onClick={handleSubmit(onAprova)} >
                APROVAR
              </Button>
            )}
          {botaoReprovar && fornecedor?.analisado && (
            <>
              <Button color="warning"
                variant="outlined"
                onClick={handleSubmit(onPendencia)} >
                PENDÊNCIA
              </Button>
              <Button color="error"
                variant="outlined"
                onClick={handleSubmit(onReprove)} >
                REPROVAR
              </Button>
            </>
          )} */}

        </DialogActions>
      </Dialog ></>

  );
};


export default DialogoFornecedor;
