import { useState } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
  Button, Checkbox as MUICheckbox,
  Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Grid,
} from "@mui/material";
import api from "../../../services/api";
import './addTomada.css';
import { opcoesTabelaAddTomadaPorCarga } from "../../../static";

const AddRefrigeracaoFaseOne = ({ currProcesso, onClose, datamaxima, tipoCarga }) => {

  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [rows, setRows] = useState([
    {
      coluna1: '',
      coluna2: '',
      coluna3: '',
      coluna4: '',
      coluna5: '',
      coluna6: '',
      coluna7: '',
      coluna14: 0,
    }
  ]);

  // Função para adicionar uma nova linha vazia
  const addRow = () => {
    setRows([
      ...rows,
      {
        coluna1: '',
        coluna2: '',
        coluna3: '',
        coluna4: '',
        coluna5: '',
        coluna6: '',
        coluna7: '',
        coluna14: 0,
      }
    ]);
  };

  // Função para atualizar valores dentro das linhas da tabela
  const handleRowChange = (index, field, value) => {
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  };

  const handleSubmit = async () => {
    startLoading();
    if (datamaxima.length === 0) {
      stopLoading({ message: "Forneça uma data de encerramento.", severity: 'error' });
    }

    if (rows.length === 0) {
      stopLoading({ message: "Preencha pelo menos um item para tomada de preço.", severity: 'error' });
    }

    const isValid = rows.every(row => {
      return Object.values(row).slice(0, -1).every(value => value !== '') && row.coluna14 > 0;
    });

    if (!isValid) {
      stopLoading({ message: "Certifique-se de que todas as colunas estão preenchidas e que a quantidade seja maior que zero.", severity: 'error' });
      return;
    }

    // Monta o objeto JSON com os dados
    const payload = {
      processoId: currProcesso.id,          // Inclui o processo_id
      tipoCarga: tipoCarga,                 // Carga selecionada
      finishedAt: datamaxima,                // Data de finalização
      tabela: rows                           // Dados preenchidos na tabela
    };

    console.log("Chamando api: ", payload);

    try {

      let data = (await api.http.post('/fornecedores/orcamentosprocessos', payload)).data;

      stopLoading();
      onClose();
    } catch (error) {
      stopLoading({ message: error.toString(), severity: 'error' });
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">Equipamento de refrigeração</TableCell>
              <TableCell align="center">Capacidade de refrigeração (TR)</TableCell>
              <TableCell align="center">COP/EER mínimo</TableCell>
              <TableCell align="center">GWP máximo do Fluido refrigerante</TableCell>
              <TableCell align="center">Vazão de água (m³/h)</TableCell>
              <TableCell align="center">Temperatura da água resfriada (°C)</TableCell>
              <TableCell align="center">Possui inversor de frequência [S/N]</TableCell>
              <TableCell align="center">Quantidade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <select
                    className="table-select"
                    value={row.coluna1}
                    onChange={(e) => handleRowChange(index, "coluna1", e.target.value)}
                  >
                    <option value="">Selecione</option>
                    {opcoesTabelaAddTomadaPorCarga.refrigeracao.coluna1.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {/* <input
                    type="text" className="table-input"
                    value={row.coluna1}
                    onChange={(e) => handleRowChange(index, "coluna1", e.target.value)}
                  /> */}
                </TableCell>
                <TableCell align="center">
                  <input
                    type="text" className="table-input"
                    value={row.coluna2}
                    onChange={(e) => handleRowChange(index, "coluna2", e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <input
                    type="text" className="table-input"
                    value={row.coluna3}
                    onChange={(e) => handleRowChange(index, "coluna3", e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <input
                    type="text" className="table-input"
                    value={row.coluna4}
                    onChange={(e) => handleRowChange(index, "coluna4", e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <input
                    type="text" className="table-input"
                    value={row.coluna5}
                    onChange={(e) => handleRowChange(index, "coluna5", e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <input
                    type="text" className="table-input"
                    value={row.coluna6}
                    onChange={(e) => handleRowChange(index, "coluna6", e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <select
                    className="table-select"
                    value={row.coluna7}
                    onChange={(e) => handleRowChange(index, "coluna7", e.target.value)}
                  >
                    <option value="">Selecione</option>
                    {opcoesTabelaAddTomadaPorCarga.refrigeracao.coluna7.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {/* <input
                    type="text" className="table-input"
                    value={row.coluna7}
                    onChange={(e) => handleRowChange(index, "coluna7", e.target.value)}
                  /> */}
                </TableCell>
                <TableCell align="center">
                  <input
                    type="number" className="table-input"
                    value={row.coluna14}
                    onChange={(e) => handleRowChange(index, "coluna14", e.target.value)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container spacing={1}>
        <Grid item xs={6} >
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleSubmit}
          //disabled={isDisabled() || !isDirty}
          //disabled={!isDirty}
          >
            Salvar
          </Button>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
            onClick={addRow}
          >
            Adicionar Linha
          </Button>
        </Grid>
      </Grid>
      {/* <Button variant="contained" onClick={addRow} sx={{ mt: 2 }}>
        Adicionar Linha
      </Button> */}
    </>
  );
};

export default AddRefrigeracaoFaseOne;
