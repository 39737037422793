import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  CircularProgress,
  Paper,
  Select,
  Grid
} from '@mui/material';
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  TextField,
  SelectUF,
  Checkbox
} from '../../components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import HelpDialog from './HelpDialog.js';

yup.setLocale(pt);


// const InstFinSelect = ({ useFormRef, schema }) => {
//   const [listaInstFinanceiras, setListaInstFinanceiras] = React.useState([]);
//   let startLoading = useStoreActions((actions) => actions.nav.startLoading);
//   let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
//   const [mounted, setMounted] = React.useState(false);

//   const [states, setStates] = React.useState([]);

//   // React.useEffect(async () => {
//   //   startLoading();
//   //   try {
//   //     setListaInstFinanceiras((await api.http.get('/inst-financeiras')).data);
//   //     stopLoading();
//   //     setMounted(true);
//   //   } catch (error) {
//   //     stopLoading({
//   //       message: error.toString(),
//   //       severity: 'error'
//   //     });
//   //   }
//   // }, []);

//   return (
//     <FormControl sx={{ my: 2 }} fullWidth>
//       {mounted &&
//         <Select
//           required
//           field="instFinanceiraId"
//           useFormRef={useFormRef}
//           schema={schema}
//           options={listaInstFinanceiras}
//           getOptionLabel={option => option.nome}
//         />
//       }
//     </FormControl>
//   );
// };

const DialogoEdital = ({ open, onClose, edital }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [states, setStates] = React.useState([]);

  const defaultValues = {
    titulo: '',
    uf: '',
    linkUrl: '',
    habilitado: true
  };
  const schema = yup.object({
    titulo: yup.string().label('Título').required(),
    linkUrl: yup.string().label('Link do edital').required(),
    uf: yup.string().label('edital para U.F.').required(),
    habilitado: yup.boolean().label('Habilitado').required()
  }).required();
  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { handleSubmit, reset, watch,
    formState: { isDirty, isSubmitting } } = useFormRef;

  const onSave = async (data) => {
    startLoading();
    try {
      if (data.id) {
        await api.http.put('/fornecedores/editais/' + data.id, data);
      } else {
        await api.http.post('/fornecedores/editais', data);
      }
      stopLoading({ severity: 'success' });
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const close = (reload = false) => {
    reset(defaultValues);
    onClose(reload);
  };

  React.useEffect(() => {
    reset(edital);
  }, [edital]);

  React.useEffect(() => {
    ///recuperar dados da api ibge e preencher o dropdow estados
    async function fetchStates() {
      try {
        const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        const data = await response.json();

        const sortedStates = data.sort((a, b) => {
          return a.nome.localeCompare(b.nome); // Comparando os nomes dos estados
        });

        setStates(sortedStates);

      } catch (error) {
        console.error('Error fetching states:', error);
      }
    }

    fetchStates();
  }, []);


  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Informações de acesso ao edital</DialogTitle>
      <DialogContent>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="titulo"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="linkUrl"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <SelectUF
            field="uf"
            useFormRef={useFormRef}
            schema={schema}
            //disabled={disabled}
            options={states}
            getOptionLabel={(option) => option.nome}
          />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Checkbox field="habilitado"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        {loading > 0 && <CircularProgress
          size={30}
          sx={{
            'position': 'absolute',
            'left': 25,
            'bottom': 12
          }} />}
        <Button color="inherit"
          onClick={() => close()}
          disabled={isSubmitting}>
          CANCELAR
        </Button>
        <Button color="primary"
          onClick={handleSubmit(onSave)}
          disabled={!isDirty || isSubmitting}>
          SALVAR
        </Button>
      </DialogActions>
    </Dialog>

  );
};


export default DialogoEdital;
