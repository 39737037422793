import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Checkbox as MUICheckbox,
  Tabs,
  Tab,
  Box, Paper,
  Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Grid
} from "@mui/material";

import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Autocomplete, FileListField, Select, DateField }
  from "../../../components";
import api from "../../../services/api";
import { tiposCargas, tituloTabelaAddTomadaPorCarga, dialogoTomadaPreco } from "../../../static";
import { formatCurrency } from "../../../services/utils";

//import ViewTomadaPrecoFaseOneHeader from "../../components/TomadaPreco/ViewTomadaPrecoFaseOneHeader";
//import ViewTomadaPrecoFaseOneBody from "../../components/TomadaPreco/ViewTomadaPrecoFaseOneBody";

import PropTypes from 'prop-types';
import { ConstructionOutlined } from "@mui/icons-material";

//import { ViewTableBodyIluminacao } from "./BodyFaseOne/ViewTableBodyIluminacao";

import '../../StepPreDiagnosticoRelatorios/ModalRelResumo.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



const AcompanhaCompressoresFaseOne = ({ open, onClose, orcamento, itens }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let currProcesso = useStoreState((state) => state.processo.current);

  const [dados, setDados] = React.useState();
  const [somaTotal, setSomaTotal] = React.useState(0);
  // const [isPastDate, setIsPastDate] = React.useState(null);

  // Obtem os cabeçalhos conforme o tipoCarga
  const selectedHeaders = tituloTabelaAddTomadaPorCarga[orcamento.tipoCarga] || {};

  // Array de colunas com os títulos não vazios
  const columns = Object.values(selectedHeaders).filter(header => header !== "");

  // Adiciona sempre a coluna 15 (Valor unitário R$)
  columns.push("Valor unitário R$");
  columns.push("Valor total R$");

  // Estado para controlar quais colunas estão visíveis
  const [visibleColumns, setVisibleColumns] = React.useState(
    columns.map(() => true)
  );

  const getTipoCarga = (cod) => {
    const nomecarga = tiposCargas.find((e) => e.id === cod);
    return nomecarga ? nomecarga.label : "Desconhecido";
  }

  const formatDateToPtBr = (dateString) => {
    // Verifica se a string contém informações de timezone
    dateString = dateString.substring(0, 10);
    const date = new Date(dateString.includes('T') ? dateString : `${dateString}T00:00:00`);

    // Formata a data para o formato 'dd/mm/yyyy'
    return date.toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo', // Garante o fuso horário correto
    });
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const close = () => {
    // reset(defaultValues);
    onClose(true);
  };

  const aprovar2fase = async () => {
    const enviofase2At = new Date();

    ////  orcamentos/processo/tomada/aprovarpara2fase/

    startLoading();
    try {
      let putOrcamentos = (
        await api.http.put("/orcamentos/processo/tomada/aprovarpara2fase/" + orcamento?.id
          , {
            enviofase2At: enviofase2At.toISOString().split('T')[0] // Envia no formato 'YYYY-MM-DD'
          }
        )).data;
      onClose(true);
      stopLoading();

    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  const preencherInfo = (name, chave) => {
    // Localiza o objeto correto com base no 'name'
    const itemSelecionado = dialogoTomadaPreco.find(item => item.name === name);

    if (!itemSelecionado || !itemSelecionado[chave]) return null; // Verifica se existe a chave

    // Retorna o conteúdo para ser renderizado
    return itemSelecionado[chave].map(info => (
      <Grid container key={info.ordem} spacing={2}>
        <Grid item xs={1} className="item-response-color">
          <Typography variant="subtitle2" gutterBottom className='margemtexto'>
            {info.ordem}
          </Typography>
        </Grid>
        <Grid item xs={11} className="item-response">
          <Typography variant="subtitle2" gutterBottom className='margemtexto'>
            {info.detalhe}
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  // const adicionarValor = (valor) => {
  //   // Verifica se o valor é string, substitui a vírgula por ponto e converte para número
  //   const valorConvertido = typeof valor === 'string'
  //     ? parseFloat(valor.replace(',', '.'))
  //     : Number(valor);

  //   // Atualiza o estado adicionando o valor convertido
  //   if (!isNaN(valorConvertido)) { // Verifica se é um número válido
  //     setSomaTotal((prevSoma) => prevSoma + valorConvertido);
  //   } else {
  //     console.error('O valor fornecido não é um número válido:', valor);
  //   }
  // };

  // const convertStringToNumber = (value1, value2) => {
  //   // Função que garante a conversão de string para número, substituindo vírgula por ponto
  //   const convert = (str) => {
  //     // Substitui vírgula por ponto e converte para número
  //     const formattedStr = str.replace(',', '.');
  //     console.log("Converte: ", formattedStr);
  //     return parseFloat(formattedStr);
  //   };

  //   console.log("Numero 1: ", value1);
  //   console.log("Numero 2: ", value2);
  //   // Converte os dois valores
  //   const number1 = convert(value1);
  //   const number2 = convert(value2);

  //   const multiplica = (number1 * number2)

  //   // Retorna os números convertidos
  //   return multiplica;
  // };

  // React.useEffect(async () => {
  //   startLoading();
  //   try {
  //     let getOrcamentos = await api.http.get("/orcamentos/processo/tomada/itens/" + orcamento.id).data;
  //     setDados(getOrcamentos);
  //     console.log("getorcamentos: ", getOrcamentos);
  //   } catch (error) {
  //     stopLoading({
  //       message: error.toString(),
  //       severity: "error",
  //     });

  //   };
  // }, [orcamento])

  // console.log("orcamento: ", orcamento);
  //console.log("selectedHeaders:", selectedHeaders);
  // console.log("dados:", itens?.data);

  // const isPastDate = (selectedDate) => {
  //   const today = new Date(); // Data atual
  //   //const selectedDate = new Date(inputDate); // Data selecionada

  //   // Remover o tempo da comparação (apenas ano, mês e dia)
  //   today.setHours(0, 0, 0, 0);
  //   selectedDate.setHours(0, 0, 0, 0);

  //   // Verificar se a data selecionada é anterior à data atual
  //   return (selectedDate < today);
  // };

  React.useEffect(() => {
    if (itens?.data) {
      // Calcula a soma das colunas 16
      const total = itens.data.reduce((acc, row) => {
        let value = row.coluna16;
        if (typeof value === 'string') {
          value = value.replace(',', '.');
        }
        const col16 = parseFloat(value) || 0;
        return acc + col16;
      }, 0);

      // Atualiza o estado com a soma total
      setSomaTotal(total);
    }
  }, [itens]);

  console.log("itens : ", itens);

  return (
    <Dialog open={open} onClose={() => onClose()} fullScreen>
      <DialogTitle>Visualização tomada de preços 1ª Fase</DialogTitle>
      <DialogContent dividers>
        <Box className="title">
          <Typography align="center" variant="subtitle1" className="titleFontColor">
            PROGRAMA POTENCIALIZEE - {getTipoCarga(orcamento?.tipoCarga)}
          </Typography>
        </Box>
        <Box className="title">
          <Typography align="left" variant="subtitle1" className="titleFontColor">
            PEDIDO DE ORÇAMENTO: {orcamento?.id}
          </Typography>
        </Box>
        <Box className="title">
          <Typography align="left" variant="subtitle1" className="titleFontColor">
            DADOS DE IDENTIFICAÇÃO DO SOLICITANTE:
          </Typography>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Cidade: </Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{orcamento?.fornecedor?.cidade}</Typography>
          </Grid>
          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">UF: </Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{orcamento?.fornecedor?.uf}</Typography>
          </Grid>
          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Validade da proposta </Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'> {orcamento?.validadeProposta} </Typography>
          </Grid>
        </Grid>

        <Box className='title'>
          <Typography variant="h6" gutterBottom className='titleFontColor'>
            INFORMAÇÕES ADICIONAIS - {getTipoCarga(orcamento.tipoCarga).toUpperCase()} QUE PREFERENCIALMENTE APRESENTEM:
          </Typography>
        </Box>
        <Grid container spacing={0} className="gridTitleContainer">
          {preencherInfo(orcamento?.tipoCarga, "infodefault")}
        </Grid>

        <Box className='title'>
          <Typography variant="h6" gutterBottom className='titleFontColor'>
            INFORMAÇÕES ADICIONAIS QUE DEVEM ESTAR CONTIDAS NA PROPOSTA::
          </Typography>
        </Box>
        <Grid container spacing={0} className="gridTitleContainer">
          {preencherInfo(orcamento?.tipoCarga, "infotomada")}
        </Grid>

        <Box className='title'>
          <Typography variant="h6" gutterBottom className='titleFontColor'>
            INFORMAÇÕES SOBRE O FORNECEDOR:
          </Typography>
        </Box>

        <Grid container spacing={0}>
          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Fornecedor:</Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'> {orcamento?.fornecedor?.nome}</Typography>
          </Grid>

          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Prazo Máximo para retorno:</Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'> {orcamento.finishedAt ? formatDateToPtBr(orcamento.finishedAt) : ""}</Typography>
          </Grid>

          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Data Retorno:</Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'> {orcamento.respostafase1At ? formatDateToPtBr(orcamento.respostafase1At) : ""}</Typography>
          </Grid>
        </Grid>

        <Box className='title'>
          <Typography variant="h6" gutterBottom className='titleFontColor'>
            INFORMAÇÕES SOBRE {getTipoCarga(orcamento.tipoCarga).toUpperCase()} A SEREM ORÇADAS:
          </Typography>
        </Box>

        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  Tipo de compressor
                </TableCell>
                <TableCell align="center">
                  Pressão máxiam (PSI)
                </TableCell>
                <TableCell align="center">
                  Vazão fornecida(l/min)
                </TableCell>
                <TableCell align="center">
                  Luminosidade (lumens)
                </TableCell>
                <TableCell align="center">
                  Quantidade
                </TableCell>
                <TableCell align="center">
                  Valor unitário R$
                </TableCell>
                <TableCell align="center">
                  Valor total R$
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {itens?.data.map((row, rowIndex) => (
                <>
                  <TableRow>
                    <TableCell align="center">
                      {row.coluna1}
                    </TableCell>
                    <TableCell align="center">
                      {row.coluna2}
                    </TableCell>
                    <TableCell align="center">
                      {row.coluna3}
                    </TableCell>
                    <TableCell align="center">
                      {row.coluna4}
                    </TableCell>
                    <TableCell align="center">
                      {row.coluna14}
                    </TableCell>
                    <TableCell align="center">
                      {formatCurrency(row.coluna15)}
                    </TableCell>
                    <TableCell align="center">
                      {formatCurrency(row.coluna16)}
                    </TableCell>
                  </TableRow>
                </>
              ))}

            </TableBody>
          </Table>
        </TableContainer>

        <Grid container spacing={0}>
          <Grid item xs={3} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">PRAZO PARA ENVIO DO EQUIPAMENTO: </Typography>
          </Grid>
          <Grid item xs={6} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{orcamento?.prazoEntrega} DIAS</Typography>
          </Grid>
          <Grid item xs={3} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='gridItemTitleResponseright'>R$ {formatCurrency(somaTotal)}</Typography>
          </Grid>
          <Grid item xs={3} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">CONDIÇÕES DE PAGAMENTO: </Typography>
          </Grid>
          <Grid item xs={9} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{orcamento?.observacoes}</Typography>
          </Grid>
        </Grid>

        <Button variant="outlined"
          sx={{ marginTop: "1rem" }}
          onClick={aprovar2fase}
          disabled={!orcamento.respostafase1At}>Aprovar 2ª fase</Button>

      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button color="inherit" onClick={() => close()}>
          FECHAR
        </Button>

      </DialogActions>
    </Dialog>
  );
};

export default AcompanhaCompressoresFaseOne;
